import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Controller, useFieldArray } from 'react-hook-form';

import Input from 'components/ui/Input';
import Text from 'components/ui/Text';
import Field from 'components/ui/Field';
import Button from 'components/ui/Button';
import MetaTagsSelect from 'components/forms/MetaTagsSelect';
import { CloseIcon, AddIcon } from 'components/ui/Icons';

const TopContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const BottomContainer = styled.div`
  margin-top: 8px;
`;

const RemoveBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AddBtnContainer = styled.div`
`;

const Container = styled.div`
  margin-top: 16px;

  ${TopContainer} {
    ${Field} + ${Field} {
      margin-left: 12px;
    }
  }
`;

const TAG_FIELD = 'property';
const NAME_FIELD = 'name';
const CONTENT_FIELD = 'content';

function MetaTagsForm({ className }) {
  const arrFieldName = 'meta_tags';
  const { fields, append, remove } = useFieldArray({
    name: arrFieldName
  });

  const handleAddClick = (ev) => {
    ev.preventDefault();

    append({});
  };

  const handleRemoveClick = (index) => (ev) => {
    ev.preventDefault();

    remove(index);
  };

  return (
    <>
      {
        fields.map((item, index) => (
          <Container
            key={item.id}
          >
            <TopContainer>
              <Field
                label='Тэг'
                name={`${arrFieldName}.${index}.${TAG_FIELD}`}
              >
                <Controller
                  defaultValue={item[TAG_FIELD] || null}
                  render={({ field }) => {
                    return (
                      <MetaTagsSelect
                        {...field}
                      />
                    );
                  }}
                />
              </Field>

              <Field
                label='Свойство'
                name={`${arrFieldName}.${index}.${NAME_FIELD}`}
              >
                <Controller
                  defaultValue={item[NAME_FIELD] || ''}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        placeholder='Введите свойство'
                      />
                    );
                  }}
                />
              </Field>
            </TopContainer>

            <BottomContainer>
              <Field
                label='Значение'
                name={`${arrFieldName}.${index}.${CONTENT_FIELD}`}
              >
                <Controller
                  defaultValue={item[CONTENT_FIELD] || ''}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        placeholder='Введите значение'
                      />
                    );
                  }}
                />
              </Field>
            </BottomContainer>

            <RemoveBtnContainer>
              <Button
                onClick={handleRemoveClick(index)}
                size='medium'
                variant='secondary'
                compact
              >
                <CloseIcon />
                <Text>Удалить</Text>
              </Button>
            </RemoveBtnContainer>
          </Container>
        ))
      }

      <AddBtnContainer>
        <Button
          onClick={handleAddClick}
          variant='info'
          size='medium'
          compact
        >
          <AddIcon />
          <Text>Добавить пару</Text>
        </Button>
      </AddBtnContainer>
    </>
  );
}

MetaTagsForm.defaultProps = {
  className: ''
};

MetaTagsForm.propTypes = {
  className: PropTypes.string
};

export default MetaTagsForm;
