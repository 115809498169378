import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Modal from 'components/ui/Modal';
import Form from 'components/ui/Form';
import ReviewerForm, { schema } from 'Pages/Reviewers/components/Form';
import Header from 'components/ui/Header';
import useReviewers, { formValues } from 'Pages/Reviewers/hooks/useReviewers';

const HeaderContainer = styled.div`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 560px;
  min-height: 100px;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 4px;
  box-sizing: border-box;
  max-height: 100vh;
  overflow: auto;

  ${HeaderContainer} {
    margin-bottom: 20px;
  }
`;

const FormWrapper = styled(Form)`
  width: 400px;
`;

function EditAdditional({ className }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [value, { fetchOne, update }] = useReviewers();

  useEffect(() => {
    fetchOne(id);
  }, [id]);

  const initialValue = useMemo(() => {
    return formValues(value);
  }, [value]);

  const onSubmit = async (formData) => {
    const { error } = await update(id, formData);

    if (!error) {
      navigate('/reviewers');
    }
  };

  const onReset = () => {
    navigate('/reviewers');
  };

  if (value === null) return null;

  return (
    <Modal>
      <Wrapper className={className}>
        <HeaderContainer>
          <Header
            level={3}
          >
            Редактировать автора
          </Header>
        </HeaderContainer>

        <FormWrapper
          initialValue={formValues(initialValue)}
          onSubmit={onSubmit}
          onReset={onReset}
          schema={schema}
        >
          <ReviewerForm />
        </FormWrapper>
      </Wrapper>
    </Modal>
  );
}

EditAdditional.defaultProps = {
  className: ''
};

EditAdditional.propTypes = {
  className: PropTypes.string
};

export default styled(EditAdditional)``;
