import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/ui/Select';
import symbols from './helpers/currencySymbols';

const CurrencySelect = React.forwardRef(({ className, placeholder, error, simpleValue, ...rest }, ref) => {
  const symbolsArray = Object.entries(symbols).map(([name, value]) => ({ name, value }));

  return (
    <Select
      options={symbolsArray}
      placeholder={placeholder}
      valueField='name'
      labelField='name'
      error={error}
      simpleValue={simpleValue}
      {...rest}
    />
  );
});

CurrencySelect.defaultProps = {
  className: '',
  placeholder: 'Выберите',
  error: null
};

CurrencySelect.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.any,
  simpleValue: PropTypes.string
};

export default CurrencySelect;
