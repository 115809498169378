import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Loader as LoaderMantine } from '@mantine/core';

import useStyles from './Loader.styles';

function Loader({ className }) {
  const { classes, cx } = useStyles({});

  return (
    <Box className={cx(classes.root, className)}>
      <LoaderMantine />
    </Box>
  );
}

Loader.propTypes = {
  className: PropTypes.string
};

export default styled(Loader)``;
