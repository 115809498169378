import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from '../../Card';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -32px;
  margin-top: -32px;

  ${Card} {
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 32px;
    margin-top: 32px;
  }
`;

function BlockCards({ data, className, onEdit, onEditAdditional, onPublish, type, onDelete, onEditForm, sitePath }) {
  return (
    <Wrapper className={className} type={type}>
      {data.map((blog) => (
        <Card
          key={blog.id}
          blog={blog}
          onEditForm={onEditForm}
          onEdit={onEdit(blog)}
          onEditAdditional={onEditAdditional(blog)}
          onPublish={onPublish(blog)}
          onDelete={onDelete}
          type={type}
          sitePath={sitePath}
        />
      ))}
    </Wrapper>
  );
}

BlockCards.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  onEditForm: PropTypes.func,
  onEdit: PropTypes.func,
  type: PropTypes.string,
  onEditAdditional: PropTypes.func,
  onPublish: PropTypes.func,
  sitePath: PropTypes.string,
  onDelete: PropTypes.func
};

export default styled(BlockCards)``;
