import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { TopBlogIcon } from 'components/ui/Icons';
import { useTranslation } from 'react-i18next';

const site = process.env.REACT_APP_SITE;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

const RegionIconContainer = styled.div`
  font-size: 60px;

  svg {
    display: block;
  }
`;

const TextContainer = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #323232;
  margin-left: 15px;
`;

const CommonText = styled.div`
  display: inline;
  font-family: 'GTEesti';
`;

const FocusText = styled.a`
  display: inline;
  font-family: 'GTEesti';
  margin-left: 4px;
  color: #323232;
  cursor: pointer;

  &:hover {
    color: #323232;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background: #FFEED6;
  border-radius: 4px;
  padding: 21px 31px 24px 26px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function Region({ className, data }) {
  const { t } = useTranslation();

  const handleClick = e => {
    e.preventDefault();
    window.open(`${site}/blog/${data.blog.slug}/`);
  };

  return (
    <Wrapper className={className}>
      <LeftContent>
        <RegionIconContainer>
          <TopBlogIcon />
        </RegionIconContainer>

        <TextContainer>
          <CommonText>
            {t('Blogcard.CommonText')}
          </CommonText>

          <FocusText onClick={handleClick} href={`${site}/blog/${data.blog.slug}/`}>
            {data.title}
          </FocusText>
        </TextContainer>
      </LeftContent>
    </Wrapper>
  );
}

Region.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

export default styled(Region)``;
