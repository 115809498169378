import connection from 'connection/instance';
import { DateTime } from 'luxon';

const format = 'yyyy-MM-dd';

const site = process.env.REACT_APP_SITE;

class BlogApi {
  async fetchHotels(q) {
    try {
      const params = {
        q: q.toLowerCase(),
        query_by: 'index_strings',
        sort_by: '_text_match:desc',
        num_typos: 1
      };

      const headers = {
        'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY
      };

      const { data } = await connection.get('/api/ts/collections/hotels/documents/search', { params, headers });

      return { data: data.hits, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async fetchHotel(slug) {
    try {
      const {
        data: { hotel }
      } = await connection.get(`${site}/api/hotels/${slug}`);

      return { data: hotel, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async fetchPrice(hotel) {
    try {
      const check_in = DateTime.now().plus({ days: 7 }).toFormat(format);
      const check_out = DateTime.now().plus({ days: 14 }).toFormat(format);

      const params = {
        check_in,
        check_out,
        travellers: [
          {
            age_group: 'adult',
            age: 0
          }
        ],
        exclude_ids: [hotel.id],
        address: hotel.address
      };

      const {
        data: { meta }
      } = await connection.get(`${site}/api/hotels/${hotel.id}/prices`, { params });

      return { data: meta, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }
}

export default new BlogApi();
