import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Loader from 'components/ui/Loader';

const PriceContainer = styled.div`
  margin-left: 27px;
  border: 1px solid #f1e5dd;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 28px;
  float: right;
  min-width: 288px;
`;

const PriceContent = styled.div`
  display: flex;
  align-items: center;
`;

const Price = styled.div`
  font-family: GTEesti;
  white-space: nowrap;
  line-height: 35px;
  font-size: 30px;
  font-weight: 700;
`;

const Billing = styled.div`
  font-family: GTEesti;
  margin-left: 8px;
  line-height: 21px;
  font-size: 18px;
  white-space: nowrap;
`;

const Button = styled.div`
  font-family: GTEesti;
  text-align: center;
  white-space: nowrap;
  background: #1bc47d;
  border-radius: 4px;
  padding: 13px 40px 15px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: all .2s ease-in-out;
`;

const Description = styled.div`
  display: inline;
  font-size: 18px;
  line-height: 26px;
  color: #323232;
  font-family: GTEesti;

  br{
    content: '';
    margin: 8px 0;
    white-space: pre;
    display: block;
  }
`;

const NotAvailable = styled.div`
  color: #EC4B61;
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 155px;
  display: inline-block;

  ${PriceContent} + ${Button} {
    margin-top: 16px;
  }
`;

const site = process.env.REACT_APP_SITE || 'https://sanatory-testing.ru';

function Bottom({ className, hotel, price, isPending }) {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open(`${site}/hotel/${hotel.slug}#roomTypes`);
  };

  const pretiffyPrice = (price) => {
    const n = Math.round(price).toString();
    const separator = ' ';
    return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + separator);
  };

  const enteredPrice = {
    amount: Number(hotel.min_price_text.match(/\d+/)[0]),
    currency: price?.currency || 'RUB'
  };

  return (
    <Wrapper className={className}>
      <PriceContainer>
        {!isPending && enteredPrice?.amount > 0 && (
          <PriceContent>
            <Price>
              {pretiffyPrice(price?.amount > 0 ? price.amount : enteredPrice.amount)} ₽
            </Price>

            <Billing>
              за сутки
            </Billing>
          </PriceContent>
        )}

        {!isPending && enteredPrice?.amount === 0 && (
          <NotAvailable>{t('HotelCard.Unavailable')}</NotAvailable>
        )}

        {isPending && <Loader />}

        <Button onClick={handleClick}>
          {t('HotelCard.Action.SelectRoom')}
        </Button>
      </PriceContainer>

      <Description dangerouslySetInnerHTML={{ __html: hotel.description }} />
    </Wrapper>
  );
}

Bottom.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object,
  price: PropTypes.object,
  isPending: PropTypes.bool
};

export default styled(Bottom)``;
