import { createAsyncThunk } from '@reduxjs/toolkit';

import { articlesUnpublish } from 'apis/ArticlesApi';
import { IArticleResponse } from 'types';

const ACTION = 'articles/unpublish';

const unpublishArticle = createAsyncThunk(
  ACTION,
  async (data: IArticleResponse): Promise<IArticleResponse> => {
    const article = await articlesUnpublish(data.id);

    return article;
  }
);

export default unpublishArticle;
