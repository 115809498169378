
import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme, _, getRef) => ({
  root: {

  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    color: '#323232',
    marginTop: 18,

    '.green': {
      color: '#3cc47d'
    }
  },
  content: {
    marginTop: 28
  },
  dropdown: {
    ref: getRef('dropdown'),

    [`+.${getRef('dropdown')}`]: {
      marginTop: 20
    }
  }

}));

export default useStyles;
