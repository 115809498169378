
import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme, _, getRef) => ({
  root: {
    marginTop: 16
  },

  distance: {
    ref: getRef('distance'),

    [`+.${getRef('distance')}`]: {
      marginTop: 10
    }
  }
}));

export default useStyles;
