import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Modal from 'components/ui/Modal';
import Header from 'components/ui/Header';
import Form from 'components/ui/Form';
import ReviewerForm, { schema } from 'Pages/Reviewers/components/Form';
import useReviewers, { formValues } from 'Pages/Reviewers/hooks/useReviewers';

const HeaderContainer = styled.div`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 560px;
  min-height: 100px;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 4px;
  box-sizing: border-box;
  max-height: 100vh;
  overflow: auto;

  ${HeaderContainer} {
    margin-bottom: 20px;
  }
`;

const FormWrapper = styled(Form)`
  width: 400px;
`;

function Add({ className }) {
  const navigate = useNavigate();
  const [, { create }] = useReviewers();

  const onSubmit = async (formData) => {
    const { data, error } = await create(formData);

    if (!error) {
      navigate(`/reviewers/${data.id}/edit`);
    }
  };

  const onReset = () => {
    navigate('/reviewers');
  };

  return (
    <Modal>
      <Wrapper className={className}>
        <HeaderContainer>
          <Header
            level={3}
          >
            Добавить автора
          </Header>
        </HeaderContainer>

        <FormWrapper
          initialValue={formValues({})}
          onSubmit={onSubmit}
          onReset={onReset}
          schema={schema}
        >
          <ReviewerForm />
        </FormWrapper>
      </Wrapper>
    </Modal>
  );
}

Add.defaultProps = {
};

Add.propTypes = {
  className: PropTypes.string
};

export default styled(Add)``;
