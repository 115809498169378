import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mantine/core';

import NavLink from './NavLink';
import NavContent from './NavContent';
import { IObjectDataFull } from 'types';

import useStyles from './Content.styles';

import getTranslate from 'utils/getTranslate';

type Props = {
  className?: string,
  hotel: any,
  object: IObjectDataFull,
  currentLanguage: string
}

type Tab = {
  name: string,
  tag: string,
  isActive: boolean
}

function Content({ className, hotel, object, currentLanguage }: Props) {
  const { t } = getTranslate(currentLanguage);
  const { classes, cx } = useStyles();
  const blocks = object.body_translations[currentLanguage]?.blocks;

  const tabs: Tab[] = useMemo(() => {
    return [
      {
        name: t('HotelPage.Content.Descriptions', { language: currentLanguage }),
        tag: 'description',
        isActive: blocks?.find((b) => b.type === 'description')?.data?.about?.length > 0
      },
      {
        name: t('HotelPage.Content.Programms'),
        tag: 'programms',
        isActive: blocks?.find((b) => b.type === 'programs')?.data?.list?.length > 0
      },
      {
        name: t('HotelPage.Content.Specializations'),
        tag: 'specializations',
        isActive: hotel.specializations.length > 0
      },
      {
        name: t('HotelPage.Content.Procedures'),
        tag: 'procedures',
        isActive: blocks?.find((b) => b.type === 'procedures')?.data?.list?.length > 0
      },
      {
        name: t('HotelPage.Content.MealOptions'),
        tag: 'meal',
        isActive: blocks?.find((b) => b.type === 'feeding_programs')?.data?.list?.length > 0
      },
      {
        name: t('HotelPage.Content.Amenities'),
        tag: 'amenities',
        isActive: hotel.amenities.length > 0
      }
    ];
  }, [currentLanguage]);

  const [selected, setSelected] = useState<Tab | undefined>(() => tabs?.find((t) => t.isActive));

  const handleClick = (item: Tab) => () => {
    setSelected(item);
  };

  const linkElements = tabs.map((tab) => (
    <NavLink
      key={tab.tag}
      item={tab}
      active={selected?.tag === tab.tag}
      onClick={handleClick(tab)}
    />
  ));

  return (
    <Box className={cx(className, classes.root)}>
      <Box className={classes.header}>
        {linkElements}
      </Box>

      <Box className={classes.contentContainer}>
        <NavContent
          currentLanguage={currentLanguage}
          page={selected}
          hotel={hotel}
          object={object}
        />
      </Box>
    </Box>
  );
}

Content.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object
};

export default Content;
