
import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {

  },
  text: {
    fontSize: 18,
    lineHeight: '24px',
    color: '#323232',
    whiteSpace: 'break-spaces',

    p: {
      marginTop: 0
    }
  }

}));

export default useStyles;
