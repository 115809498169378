import React from 'react';
import { Text, Box, Select } from '@mantine/core';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import useStyles from './RoomTypes.styles';

import Rate from '../shared/Rate';
import { IPeriod } from 'types/parsing';
import value from 'pages/Regions/containers/Edit/defaultValue';

type Props = React.PropsWithChildren<{
  className?: string;
  roomTypes: any[];
  roomTypesState: string;
  onSelect: (room_type: any) => void;
  roomType: any;
}>;

function RoomTypes({ className, roomTypes, onSelect, roomType }: Props) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const mappedRoomTypes = roomTypes.map((type) => ({
    value: type,
    label: type.name_translations.ru
  }));

  return (
    <Box className={cx(classes.root, className)}>
      <Text className={classes.label} size={16} color='#212529'>
        {t('Tariffs.RoomTypes.Title')}
      </Text>

      <Box className={classes.periods}>
        <Select
          data={mappedRoomTypes}
          onChange={onSelect}
          value={roomType}
          placeholder={t('Tariffs.RoomTypes.Placeholder')}
        />
      </Box>
    </Box>
  );
}

export default RoomTypes;
