import React, { useEffect, useState } from 'react';
import { Button, Modal, Text } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import useStyles from './sanatoryProvider.styles';
import { useAppDispatch } from 'store';
import { toastify } from 'utils/toatify';
import { FormField } from '../../AddObject/FormField';
import { sanatoryProviderSchema, EMAIL } from './form';
import { SANATORY } from '../../AddObject/constants';
import { getProviderTranslate } from '../../List/getProviderTranslate';
import migrateObjectsAction from 'store/objectsSlice/actions/migrateObjectsActions';
import SuccessModal from '../../AddObject/SuccessModal';
import { Row } from 'components/ui/Grid';
import { COMMISSION, CONTRACTS, DATE, NUMBER, SOURCES } from '../../AddObject/SanatoryForm/form';
import Field from 'components/ui/Field/Field';
import DatePicker from 'components/ui/DatePicker/DatePicker';
import useSources from '../../AddObject/SanatoryForm/useSources';

type SanatoryProviderFormProps = {
    setShowModal: (b: boolean)=>void;
    showModal: boolean;
    id: string;
}

function SanatoryProviderModal({ setShowModal, showModal, id }:SanatoryProviderFormProps) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors }, ...methods
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(sanatoryProviderSchema)
  });
  // @ts-ignore
  const [sources, { fetch }] = useSources();

  useEffect(() => {
    fetch();
  }, []);

  const onSubmit = async (data:any) => {
    const formatedData = {
      ...data,
      provider: SANATORY,
      sources: _.map(data.sources, (source, index) => ({
        ...source,
        // @ts-ignore
        insurance_id: sources?.[index].id,
        contracts: _.filter(source?.contracts, (contract) => contract?.commission_in_percent !== undefined).map((contract) => ({
          ...contract

        }))
      })).filter(Boolean)
    };

    const result = await dispatch(migrateObjectsAction({ object: formatedData, id }));

    // @ts-ignore
    if (result?.error) {
      toastify('error', 'Возникла ошибка');
    } else {
      setShowModal(false);
      setOpenSuccessModal(true);
      setModalData(result?.payload?.meta);
    }
  };

  return (
    <>
      <Modal
        withCloseButton={false} centered closeOnClickOutside={false} onClose={() => setShowModal(false)}
        opened={showModal}
      >
        <form name='sanatory_provider_form' onSubmit={handleSubmit(onSubmit)}>

          <Text size={30} color='#000'>{t('Objects.Provider.Ostrovok.Title')}</Text>
          <Text size={30} color='#000'>{getProviderTranslate(SANATORY)}</Text>
          <div className={classes.field}>
            <FormField
              name={EMAIL}
              control={control}
              errors={errors?.[EMAIL]}
              label={t('Objects.New.Form.Email')}
              placeholder={t('Objects.New.Form.Email.Placeholder')}
              required
            />
          </div>
          <div>
            {/* @ts-ignore */}
            {sources?.map(({ name, id }, index) => {
              return (
                <div key={id} className={classes.block}>
                  <Text size={20} color='#000'>{name}</Text>
                  <div>
                    <Row className={classes.row}>
                      <FormField
                        name={`${SOURCES}.${index}.${CONTRACTS}.${index}.${NUMBER}`}
                        control={control}
                        errors={errors?.[SOURCES]?.[index]?.[CONTRACTS]?.[index]?.[NUMBER]}
                        label={t('Objects.New.Form2.Number')}
                        placeholder={t('Objects.New.Form2.Number.Placeholder')}
                        required
                      />

                      <Field
                        label={t('Objects.New.Form2.Date')}
                        name={`${SOURCES}.${index}.${CONTRACTS}.${index}.${DATE}`}
                        error={errors?.[SOURCES]?.[index]?.[CONTRACTS]?.[index]?.[DATE]}
                        required
                      >
                        <Controller
                          name={`${SOURCES}.${index}.${CONTRACTS}.${index}.${DATE}`}
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                                // @ts-ignore
                              className={classes.datepicker}
                              error={errors?.[SOURCES]?.[index]?.[CONTRACTS]?.[index]?.[DATE]}
                              {...field}
                            />
                          )}
                        />
                      </Field>

                      <FormField
                        name={`${SOURCES}.${index}.${CONTRACTS}.${index}.${COMMISSION}`}
                        control={control}
                        errors={errors?.[SOURCES]?.[index]?.[CONTRACTS]?.[index]?.[COMMISSION]}
                        label={t('Objects.New.Form2.Commission')}
                        placeholder={t('Objects.New.Form2.Commission.Placeholder')}
                        required
                        type='number'
                      />
                    </Row>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={classes.buttons_wrapper}>
            <Button type='submit' className={classes.button_green}>
              {t('Action.Save')}
            </Button>
            <Button onClick={() => setShowModal(false)} className={classes.button_gray}>
              {t('Action.Cancel')}
            </Button>
          </div>
        </form>
      </Modal>
      <SuccessModal setOpenModal={setOpenSuccessModal} openModal={openSuccessModal} modalData={modalData} />
    </>
  );
}

export default SanatoryProviderModal;
