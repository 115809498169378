import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';
import LanguageDetector from 'i18next-browser-languagedetector';
import { MantineProvider } from '@mantine/core';
import { Provider } from 'react-redux';
import store from 'store';
import { Settings } from 'luxon';

import theme from './theme';

import reportWebVitals from './reportWebVitals';
import styles from './index.styled';
import i18nConfig from './config/i18n';
import App from 'Pages/App';

import SubdomainLanguageDetect from './SubdomainLanguageDetect';

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${styles}
`;

i18n
  .use(LanguageDetector)
  .use(ICU)
  .use(initReactI18next)
  .init(i18nConfig);

Settings.defaultLocale = 'ru';
window.i18n = i18n;

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <GlobalStyle />

        <SubdomainLanguageDetect />

        <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
          <App />
        </MantineProvider>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
