import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const H1 = styled.h1`
  color: #323232;
  font-weight: 600;
  font-size: 42px;
  font-family: GTEesti;
  line-height: 48px;
`;

const H2 = styled.h2`
  font-family: GTEesti;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
`;

const H3 = styled.h3`
  font-family: GTEesti;
  font-weight: 600;
`;

const H4 = styled.h4`
  font-family: GTEesti;
  font-weight: 600;
`;

const H5 = styled.h5`
  font-family: GTEesti;
  font-weight: 600;
`;

const H6 = styled.h6`
  font-family: GTEesti;
  font-weight: 600;
`;

const Wrapper = styled.div`
  font-size: 20px;
  line-height: 34px;
  font-family: GTEesti;
  margin-top: 28px;
`;

const headers = [
  H1,
  H2,
  H3,
  H4,
  H5,
  H6
];

function Header({ data, ...rest }) {
  return <Wrapper {...rest} dangerouslySetInnerHTML={{ __html: data.text }} as={headers[data.level - 1]} />;
}

Header.propTypes = {
  data: PropTypes.object
};

export default styled(Header)``;
