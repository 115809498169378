import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Modal from 'components/ui/Modal';
import Header from 'components/ui/Header';
import Form from 'components/ui/Form';
import ReviewerForm, { schema } from 'pages/BlogNew/components/Form';
import formValues from './formValues';

import { useAppDispatch } from 'store';
import createArticleAction from 'store/articlesSlice/actions/createArticleAction';

const HeaderContainer = styled.div`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 4px;
  box-sizing: border-box;
  width: 560px;
  max-height: 100vh;
  overflow: auto;

  ${HeaderContainer} {
    margin-bottom: 20px;
  }
`;

const FormWrapper = styled(Form)`
`;

function Add({ className, lang }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onSubmit = async (formData) => {
    const formattedData = {
      ...formData,
      title: {
        ru: formData?.title_ru || null,
        en: formData?.title_en || null
      },
      meta_tags: [
        {
          name: 'Description',
          property: null,
          content: formData?.seo_description_ru || null
        },
        ...formData?.meta_tags
      ],
      seo_description: {
        ru: formData?.seo_description_ru || null,
        en: formData?.seo_description_en || null
      },
      seo_title: {
        ru: formData?.seo_title_ru || null,
        en: formData?.seo_title_en || null
      },
      type: formData?.type,
      sub_title: {
        ru: formData?.title_ru || null,
        en: formData?.title_en || null
      }
    };

    await dispatch(createArticleAction(formattedData));

    navigate('/articles');
  };

  const onReset = () => {
    navigate('/articles');
  };

  return (
    <Modal>
      <Wrapper className={className}>
        <HeaderContainer>
          <Header
            level={3}
          >
            {t('Blog.Title')}
          </Header>
        </HeaderContainer>

        <FormWrapper
          initialValue={formValues({ type: 'blog' })}
          onSubmit={onSubmit}
          onReset={onReset}
          schema={schema}
        >
          <ReviewerForm {...{ lang }} />
        </FormWrapper>
      </Wrapper>
    </Modal>
  );
}

Add.defaultProps = {
};

Add.propTypes = {
  className: PropTypes.string,
  lang: PropTypes.string
};

export default styled(Add)``;
