import { createStyles } from '@mantine/styles';
import { relative } from 'path';

const useStyles = createStyles((theme, _, getRef) => ({
  root: {},
  label: {

  },
  description: {
    marginTop: 4
  },

  periods: {
    marginTop: 4
  }
}));

export default useStyles;
