import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Controller, useFieldArray } from 'react-hook-form';

import Input from 'components/ui/Input';
import Text from 'components/ui/Text';
import Field from 'components/ui/Field';
import Button from 'components/ui/Button';
import MetaTagsSelect from 'components/forms/MetaTagsSelect';
import { CloseIcon, AddIcon, RussiaIcon, UnitedKingdomIcon } from 'components/ui/Icons';
// @ts-ignore
import { getRegionTranslate } from 'Pages/Regions/containers/Add/locale';

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionsWrapper = styled.div`
  display: flex;
  gap: 30px;
`;

const SectionContainer50 = styled.div`
  width: ${(p) => p.lang === 'en' ? '50%' : '100%'};
  margin-top: 8px;
`;

const Divider = styled.div`
  width: 1px;
  background: ${(p) => p.withOutBackround ? 'none' : '#DCD8D2'};
  margin: ${(p) => p.margin};
`;

const RemoveBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AddBtnContainer = styled.div`
`;

const Container = styled.div`
`;

const SelectPlaceholder = styled.div`
  display: flex;
  gap: 10px;
`;

const TAG_FIELD = 'property';
const NAME_FIELD = 'name';
const CONTENT_FIELD = 'content';

function MetaTagsForm({ className, lang }) {
  const arrFieldName = 'meta_tags';
  const { fields, append, remove } = useFieldArray({
    name: arrFieldName
  });

  const handleAddClick = (ev) => {
    ev.preventDefault();

    append({});
  };

  const handleRemoveClick = (index) => (ev) => {
    ev.preventDefault();

    remove(index);
  };

  const selectPlaceholder = (lang) => {
    return (
      <SelectPlaceholder>
        {lang === 'ru' && <RussiaIcon />}
        {lang === 'en' && <UnitedKingdomIcon />}
        <div>{getRegionTranslate('tagPlaceholder', lang)}</div>
      </SelectPlaceholder>

    );
  };

  return (
    <>
      {
          fields.map((item, index) => (
            <Container
              key={item.id}
            >
              <SectionsContainer>
                <SectionsWrapper>
                  <SectionContainer50>
                    <Field
                      label={getRegionTranslate('tag', 'ru')}
                      name={`${arrFieldName}.${index}.${TAG_FIELD}`}
                    >
                      <Controller
                        defaultValue={item[TAG_FIELD] || null}
                        render={({ field }) => {
                          return (
                            <MetaTagsSelect
                              {...field} placeholder={lang === 'en' ? selectPlaceholder('ru') : getRegionTranslate('tagPlaceholder', lang)}
                            />
                          );
                        }}
                      />
                    </Field>
                  </SectionContainer50>
                  {lang === 'en' && <Divider />}
                  {lang === 'en' && (
                    <SectionContainer50>
                      <Field
                        label={getRegionTranslate('tag', 'en')}
                        name={`${arrFieldName}.${index}.${TAG_FIELD}`}
                      >
                        <Controller
                          defaultValue={item[TAG_FIELD] || null}
                          render={({ field }) => {
                            return (
                              <MetaTagsSelect
                                {...field} placeholder={selectPlaceholder('en')}
                              />
                            );
                          }}
                        />
                      </Field>
                    </SectionContainer50>)}
                </SectionsWrapper>
                <SectionsWrapper>
                  <SectionContainer50>
                    <Field
                      label={getRegionTranslate('property', 'ru')}
                      name={`${arrFieldName}.${index}.${NAME_FIELD}`}
                    >
                      <Controller
                        defaultValue={item[NAME_FIELD] || ''}
                        render={({ field }) => {
                          return (
                            <Input
                              {...field}
                              withIcon={lang === 'en' && <RussiaIcon />}
                              placeholder={getRegionTranslate('propertyPlaceholder', 'ru')}
                            />
                          );
                        }}
                      />
                    </Field>
                  </SectionContainer50>
                  {lang === 'en' && <Divider />}
                  {lang === 'en' && (
                    <SectionContainer50>
                      <Field
                        label={getRegionTranslate('property', 'en')}
                        name={`${arrFieldName}.${index}.${NAME_FIELD}`}
                      >
                        <Controller
                          defaultValue={item[NAME_FIELD] || ''}
                          render={({ field }) => {
                            return (
                              <Input
                                {...field}
                                withIcon={<UnitedKingdomIcon />}
                                placeholder={getRegionTranslate('propertyPlaceholder', 'en')}
                              />
                            );
                          }}
                        />
                      </Field>
                    </SectionContainer50>)}
                </SectionsWrapper>
                <SectionsWrapper>
                  <SectionContainer50>
                    <Field
                      label={getRegionTranslate('value', 'ru')}
                      name={`${arrFieldName}.${index}.${CONTENT_FIELD}`}
                    >
                      <Controller
                        defaultValue={item[CONTENT_FIELD] || ''}
                        render={({ field }) => {
                          return (
                            <Input
                              {...field}
                              withIcon={lang === 'en' && <RussiaIcon />}
                              placeholder={getRegionTranslate('valuePlaceholder', 'ru')}
                            />
                          );
                        }}
                      />
                    </Field>
                  </SectionContainer50>
                  {lang === 'en' && <Divider />}
                  {lang === 'en' && (
                    <SectionContainer50>
                      <Field
                        label={getRegionTranslate('value', 'en')}
                        name={`${arrFieldName}.${index}.${CONTENT_FIELD}`}
                      >
                        <Controller
                          defaultValue={item[CONTENT_FIELD] || ''}
                          render={({ field }) => {
                            return (
                              <Input
                                {...field}
                                withIcon={<UnitedKingdomIcon />}
                                placeholder={getRegionTranslate('valuePlaceholder', 'en')}
                              />
                            );
                          }}
                        />
                      </Field>
                    </SectionContainer50>)}
                </SectionsWrapper>
              </SectionsContainer>

              <RemoveBtnContainer>
                <Button
                  onClick={handleRemoveClick(index)}
                  size='medium'
                  variant='secondary'
                  compact
                >
                  <CloseIcon />
                  <Text>{getRegionTranslate('deleteButton', lang)}</Text>
                </Button>
              </RemoveBtnContainer>
            </Container>
          ))
        }

      <AddBtnContainer>
        <Button
          onClick={handleAddClick}
          variant='info'
          size='medium'
          compact
        >
          <AddIcon />
          <Text>{getRegionTranslate('addTagPair', lang)}</Text>
        </Button>
      </AddBtnContainer>
    </>
  );
}

MetaTagsForm.defaultProps = {
  className: ''
};

MetaTagsForm.propTypes = {
  className: PropTypes.string,
  lang: PropTypes.string
};

export default MetaTagsForm;
