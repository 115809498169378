import React from 'react';
import PropTypes from 'prop-types';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import styled from 'styled-components';

function Dropdown({ children, className, isOpen, content, onClickOutside, ...rest }) {
  return (
    <Popover
      {...rest}
      containerStyle={{ top: '-7px' }}
      isOpen={isOpen}
      onClickOutside={onClickOutside}
      positions={['bottom', 'left', 'right', 'top']}
      content={({ position, childRect, popoverRect }) => {
        return (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor='#FFFFFF'
            arrowSize={6}
            arrowStyle={{ top: '-5px' }}
            style={{ padding: 0 }}
          >
            {content}
          </ArrowContainer>
        );
      }}
    >
      {children}
    </Popover>
  );
}

Dropdown.defaultProps = {
  className: ''
};

Dropdown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  content: PropTypes.any,
  isOpen: PropTypes.bool,
  onClickOutside: PropTypes.func
};

export default styled(Dropdown)``;
