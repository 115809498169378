import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Controller, useFieldArray } from 'react-hook-form';

import Input from 'components/ui/Input';
import Field from 'components/ui/Field';
import Button from 'components/ui/Button';
import { CloseIcon, AddIcon } from 'components/ui/Icons';
import Text from 'components/ui/Text';

const AddBtnContainer = styled.div`
  margin-top: 8px;
`;

const ItemContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: flex-end;

  ${Field} {
    margin-right: 8px;
  }
`;

const NAME_FIELD = 'name';

function AwardsForm({ className }) {
  const arrFieldName = 'awards';
  const { fields, append, remove } = useFieldArray({
    name: arrFieldName
  });

  const handleAddClick = (ev) => {
    ev.preventDefault();

    append({});
  };

  const handleRemoveClick = (index) => (ev) => {
    ev.preventDefault();

    remove(index);
  };

  return (
    <>
      {
        fields.map((item, index) => (
          <ItemContainer
            key={item.id}
          >
            <Field
              label='Награда'
              name={`${arrFieldName}.${index}.${NAME_FIELD}`}
            >
              <Controller
                defaultValue={item[NAME_FIELD] || ''}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      placeholder='Введите награду'
                    />
                  );
                }}
              />
            </Field>

            <Button
              onClick={handleRemoveClick(index)}
              size='medium'
              variant='secondary'
              compact
            >
              <CloseIcon />
            </Button>
          </ItemContainer>
        ))
      }

      <AddBtnContainer>
        <Button
          onClick={handleAddClick}
          variant='info'
          size='medium'
          compact
        >
          <AddIcon />
          <Text>Добавить награду</Text>
        </Button>
      </AddBtnContainer>
    </>
  );
}

AwardsForm.defaultProps = {
  className: ''
};

AwardsForm.propTypes = {
  className: PropTypes.string
};

export default AwardsForm;
