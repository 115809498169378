import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {},
  label: {

  },
  description: {
    marginTop: 4
  },

  rates: {
    marginTop: 4
  },

  rate: {
    display: 'flex'
  },

  leftRate: {

  },

  rightRate: {
    marginLeft: 20
  }
}));

export default useStyles;
