import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {},
  tr: {
    background: 'rgb(234,233,228)'
  },
  breadcrumbs: {
    marginBottom: 20
  },
  anchor: {
    lineHeight: '24px'
  },
  tableRow: {
    cursor: 'pointer'
  },
  th: {},
  td: {
    maxWidth: 320
  },
  tdLarge: {
    maxWidth: 520
  },
  status: {
    width: 135,
    height: 34,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 14
  },
  statusCompleted: {
    background: '#D3F4E0',
    color: '#639375'
  },
  statusInProgress: {
    background: '#C7CFD9',
    color: '#FFFFFF'
  },
  loader: {
    height: '100vh'
  },
  actionsTd: {
    display: 'flex',
    height: '100%'
  },
  button: {
    background: '#52AB18',
    height: 32,
    fontSize: 14,
    borderRadius: 4,
    color: '#333333',
    transition: 'all .2s ease-in-out',

    '&:hover': {
      background: '#C4C2BB'
    }
  },
  target: {
    userSelect: 'none',
    cursor: 'pointer',
    marginLeft: 16,
    height: 32,
    padding: '0 12px',
    fontSize: 20,
    borderRadius: 4,
    color: '#333333',
    background: '#D2D1CC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  table: {
    background: '#FFF',
    border: '1px solid #c0c0c0',
    margin: '20px 0'
  },
  sideLeft: {
    flexGrow: 0,
    flexShrink: 0
  },
  main: {
    flexGrow: 1
  },
  topbar: {
    flexGrow: 0,
    flexShrink: 0
  },
  content: {
    flexGrow: 1
  }
}));

export default useStyles;
