import React from 'react';

import { Content } from 'components/ui/Layout';
import useStyles from '../Edit.styles';
import { OSTROVOK } from '../../AddObject/constants';
import OstrovokFormEdit from './OstrovokForm';
import SanatoryFormEdit from './SanatoryForm';

type EditObject = {
    hotel: any
}

function EditObject({ hotel }:EditObject) {
  // @ts-ignore
  const { classes } = useStyles({});
  return (
    <Content className={classes.content}>
      {hotel?.provider === OSTROVOK ? <OstrovokFormEdit hotel={hotel} /> : <SanatoryFormEdit hotel={hotel} />}
    </Content>
  );
}
export default EditObject;
