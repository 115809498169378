import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import image from 'public/images/new_year.jpg';
import { ArrowRight } from 'tabler-icons-react';

const site = process.env.REACT_APP_SITE;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const TextContainer = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 34px;
  left: 34px;
  color: #3582DB;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TextContent = styled.div`
  max-width: 190px;
  font-size: 16px;
  line-height: 19px;
`;

const Wrapper = styled.div`
  width: 401px;
  height: 220px;
  position: relative;
`;

function NewYearBlock({ className, data }) {
  const { t } = useTranslation();

  const handleClick = e => {
    e.preventDefault();
    window.open(`${site}/blog/${data.blog.slug}/`);
  };

  return (
    <Wrapper className={className}>
      <Image src={image} />

      <TextContainer>
        <TextContent>
          Новогодние предложения в этом санатории
        </TextContent>

        <ArrowRight />
      </TextContainer>
    </Wrapper>
  );
}

NewYearBlock.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

export default styled(NewYearBlock)``;
