import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mantine/core';
import useStyles from './TopBar.styles';

type Props = React.PropsWithChildren<{
  className?: string | undefined,
  transparent?: boolean
}>

function TopBar({ children, transparent, className }: Props) {
  const { classes, cx } = useStyles({ transparent });

  return (
    <Box
      className={cx(className, classes.root)}
    >
      {children}
    </Box>
  );
}

TopBar.defaultProps = {
  transparent: false
};

TopBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  transparent: PropTypes.bool
};

export default TopBar;
