import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import Grid, { Row } from 'components/ui/Grid';
import Field from 'components/ui/Field';
import Input from 'components/ui/Input';
import TextArea from 'components/ui/TextArea';
import Button from 'components/ui/Button';
import ButtonGroup from 'components/ui/ButtonGroup';
import Text from 'components/ui/Text';
import MetaTagsForm from './MetaTagsForm';
// @ts-ignore
import { getDiseasesTranslate } from 'Pages/Diseases/containers/Add/locale';
import { RussiaIcon, UnitedKingdomIcon } from 'components/ui/Icons';

const SectionContainer = styled.div`
  width: 100%;
`;

const SectionContainer50 = styled.div`
  width: ${(p) => p.lang === 'en' ? '50%' : '100%'};
`;

const SectionsWrapper = styled.div`
  display: flex;
  gap: 30px;
`;

const Divider = styled.div`
  width: 1px;
  background: #DCD8D2;
  margin: ${(p) => p.margin};
`;

const Wrapper = styled.div`
  ${SectionContainer} {

    > ${Text} {
      margin-bottom: 12px;
    }
  }

  ${SectionContainer50} {
    margin-top: 24px;
  }

  ${ButtonGroup} {
    margin-top: 28px;
  }
`;

const HiddenInput = styled(Input)`
  display: none;
`;

const TITLE_FIELD_RU = 'title_ru';
const TITLE_FIELD_EN = 'title_en';
const SUB_TITLE_FIELD_RU = 'sub_title_ru';
const SUB_TITLE_FIELD_EN = 'sub_title_en';
const SEO_TITLE_FIELD_RU = 'seo_title_ru';
const SEO_TITLE_FIELD_EN = 'seo_title_en';
const SEO_DESCRIPTION_FIELD_RU = 'seo_description_ru';
const SEO_DESCRIPTION_FIELD_EN = 'seo_description_en';
const TYPE = 'type';

export const schema = yup.object().shape({
  [TITLE_FIELD_RU]: yup.string().required(),
  [SUB_TITLE_FIELD_RU]: yup.string().required(),
  [SEO_TITLE_FIELD_RU]: yup.string().required(),
  [SEO_DESCRIPTION_FIELD_RU]: yup.string().required()
});

function BlogForm({ className }) {
  const { t, i18n: { lang } } = useTranslation();
  const { formState: { errors } } = useFormContext();

  return (
    <Wrapper
      className={className}
    >
      <Controller
        name={TYPE}
        render={({ field }) => {
          return (
            <HiddenInput
              {...field}
            />
          );
        }}
      />

      <SectionsWrapper>
        <SectionContainer50>
          <Text>{t('Common.General')}</Text>

          <Grid>
            <Row>
              <Field
                label={t('Disease.Form.Name')}
                error={!!errors[TITLE_FIELD_RU]}
                required
                name={TITLE_FIELD_RU}
              >
                <Controller
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        withIcon={lang === 'en' && <RussiaIcon />}
                        error={!!errors[TITLE_FIELD_RU]}
                        placeholder={getDiseasesTranslate('articlePlaceholder', 'ru')}
                      />
                    );
                  }}
                />
              </Field>
            </Row>
          </Grid>
        </SectionContainer50>
        {lang === 'en' && <Divider margin='24px 0 0' />}
        {lang === 'en' && (
          <SectionContainer50>
            <Text>{t('Common.General')}</Text>

            <Grid>
              <Row>
                <Field
                  label={t('Disease.Form.Name')}
                  error={!!errors[TITLE_FIELD_EN]}
                  required
                  name={TITLE_FIELD_EN}
                >
                  <Controller
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          withIcon={<UnitedKingdomIcon />}
                          error={!!errors[TITLE_FIELD_EN]}
                          placeholder={getDiseasesTranslate('articlePlaceholder', 'en')}
                        />
                      );
                    }}
                  />
                </Field>
              </Row>
            </Grid>
          </SectionContainer50>)}
      </SectionsWrapper>

      <SectionsWrapper>
        <SectionContainer50>
          <Text>{t('Disease.Subtitle')}</Text>

          <Grid>
            <Row>
              <Field
                label={t('Common.Name.Placeholder')}
                error={!!errors[SUB_TITLE_FIELD_RU]}
                required
                name={SUB_TITLE_FIELD_RU}
              >
                <Controller
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        withIcon={lang === 'en' && <RussiaIcon />}
                        error={!!errors[SUB_TITLE_FIELD_RU]}
                        placeholder={t('Common.Name.Placeholder')}
                      />
                    );
                  }}
                />
              </Field>
            </Row>
          </Grid>
        </SectionContainer50>
        {lang === 'en' && <Divider margin='24px 0 0' />}
        {lang === 'en' && (
          <SectionContainer50>
            <Text>{t('Disease.Subtitle')}</Text>

            <Grid>
              <Row>
                <Field
                  label={t('Common.Name.Placeholder')}
                  error={!!errors[SUB_TITLE_FIELD_EN]}
                  required
                  name={SUB_TITLE_FIELD_EN}
                >
                  <Controller
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          withIcon={<UnitedKingdomIcon />}
                          error={!!errors[SUB_TITLE_FIELD_EN]}
                          placeholder={t('Common.Name.Placeholder')}
                        />
                      );
                    }}
                  />
                </Field>
              </Row>
            </Grid>
          </SectionContainer50>)}
      </SectionsWrapper>

      <SectionsWrapper>
        <SectionContainer50>
          <Text>{t('Common.Seo.Title')}</Text>

          <Grid>
            <Row>
              <Field
                label={t('Common.Title')}
                error={!!errors[SEO_TITLE_FIELD_RU]}
                name={SEO_TITLE_FIELD_RU}
                required
              >
                <Controller
                  render={({ field }) => (
                    <Input
                      {...field}
                      withIcon={lang === 'en' && <RussiaIcon />}
                      error={!!errors[SEO_TITLE_FIELD_RU]}
                      placeholder={t('Common.Title.Placeholder')}
                    />
                  )}
                />
              </Field>
            </Row>

            <Row>
              <Field
                label={t('Common.Description')}
                error={!!errors[SEO_DESCRIPTION_FIELD_RU]}
                required
                name={SEO_DESCRIPTION_FIELD_RU}
              >
                <Controller
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      withIcon={lang === 'en' && <RussiaIcon />}
                      error={!!errors[SEO_DESCRIPTION_FIELD_RU]}
                      placeholder={t('Common.Description.Placeholder')}
                    />
                  )}
                />
              </Field>
            </Row>
          </Grid>
        </SectionContainer50>
        {lang === 'en' && <Divider />}
        {lang === 'en' && (
          <SectionContainer50>
            <Text>{t('Common.Seo.Title')}</Text>

            <Grid>
              <Row>
                <Field
                  label={t('Common.Title')}
                  error={!!errors[SEO_TITLE_FIELD_EN]}
                  name={SEO_TITLE_FIELD_EN}
                  required
                >
                  <Controller
                    render={({ field }) => (
                      <Input
                        {...field}
                        withIcon={<UnitedKingdomIcon />}
                        error={!!errors[SEO_TITLE_FIELD_EN]}
                        placeholder={t('Common.Title.Placeholder')}
                      />
                    )}
                  />
                </Field>
              </Row>

              <Row>
                <Field
                  label={t('Common.Description')}
                  error={!!errors[SEO_DESCRIPTION_FIELD_EN]}
                  required
                  name={SEO_DESCRIPTION_FIELD_EN}
                >
                  <Controller
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        withIcon={<UnitedKingdomIcon />}
                        error={!!errors[SEO_DESCRIPTION_FIELD_EN]}
                        placeholder={t('Common.Description.Placeholder')}
                      />
                    )}
                  />
                </Field>
              </Row>
            </Grid>
          </SectionContainer50>)}
      </SectionsWrapper>

      <SectionContainer>
        <MetaTagsForm {...{ lang }} />
      </SectionContainer>

      <ButtonGroup>
        <Button
          type='submit'
          size='medium'
        >
          {t('Action.Save')}
        </Button>

        <Button
          type='reset'
          size='medium'
          variant='secondary'
        >
          {t('Action.Cancel')}
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
}

BlogForm.defaultProps = {
  className: ''
};

BlogForm.propTypes = {
  className: PropTypes.string,
  lang: PropTypes.string
};

export default styled(BlogForm)``;
