import React, { useState } from 'react';
import { Button, Modal, Text } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import useStyles from './ostrovokProvider.styles';
import { useAppDispatch } from 'store';
import { toastify } from 'utils/toatify';
import { FormField } from '../../AddObject/FormField';
import { ostrovokProviderSchema, PROVIDER_ID } from './form';
import { OSTROVOK } from '../../AddObject/constants';
import { getProviderTranslate } from '../../List/getProviderTranslate';
import migrateObjectsAction from 'store/objectsSlice/actions/migrateObjectsActions';
import SuccessModal from '../../AddObject/SuccessModal';

type OstrovokProviderFormProps = {
    setShowModal: (b: boolean)=>void;
    showModal: boolean;
    id: string;
}

function OstrovokProviderModal({ setShowModal, showModal, id }:OstrovokProviderFormProps) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ostrovokProviderSchema)
  });

  const onSubmit = async (data:any) => {
    const formatedData = {
      ...data,
      provider: OSTROVOK
    };

    const result = await dispatch(migrateObjectsAction({ object: formatedData, id }));

    // @ts-ignore
    if (result?.error) {
      toastify('error', 'Возникла ошибка');
    } else {
      setShowModal(false);
      setOpenSuccessModal(true);
      setModalData(result?.payload?.meta);
    }
  };

  return (
    <>
      <Modal
        withCloseButton={false} centered closeOnClickOutside={false} onClose={() => setShowModal(false)}
        opened={showModal}
      >
        <form name='ostrovok_provider_form' onSubmit={handleSubmit(onSubmit)}>

          <Text size={30} color='#000'>{t('Objects.Provider.Ostrovok.Title')}</Text>
          <Text size={30} color='#000'>{getProviderTranslate(OSTROVOK)}</Text>
          <div className={classes.field}>
            <FormField
              name={PROVIDER_ID}
              control={control}
              errors={errors?.[PROVIDER_ID]}
              label={t('Objects.Form.Ostrovok.ProviderId')}
              placeholder={t('Objects.Form.Ostrovok.ProviderId.Placeholder')}
              required
            />
          </div>
          <div className={classes.buttons_wrapper}>
            <Button type='submit' className={classes.button_green}>
              {t('Action.Save')}
            </Button>
            <Button onClick={() => setShowModal(false)} className={classes.button_gray}>
              {t('Action.Cancel')}
            </Button>
          </div>
        </form>
      </Modal>
      <SuccessModal setOpenModal={setOpenSuccessModal} openModal={openSuccessModal} modalData={modalData} />
    </>

  );
}

export default OstrovokProviderModal;
