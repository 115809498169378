import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from './List.theme';

const Wrapper = styled.div`
  ${(props) => theme(props)}
`;

function List({ children, className, ...rest }) {
  return (
    <Wrapper
      {...rest}
      className={className}
    >
      {children}
    </Wrapper>
  );
}

List.defaultProps = {
  className: ''
};

List.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default styled(List)``;
