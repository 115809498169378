import React from 'react';

import useStyles from './Main.styles';

type Props = React.PropsWithChildren<{
  className?: string | undefined;
  onScroll?: (e: any) => void;
}>;

function Main({ children, className, onScroll }: Props) {
  const { classes, cx } = useStyles();

  return (
    <div onScroll={onScroll} className={cx(classes.root, className)}>
      {children}
    </div>
  );
}

export default Main;
