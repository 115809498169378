import { AxiosResponse } from 'axios';

import { IUserReqBody, IUserResponse } from 'types';
import instance from 'connection/instance';
import { verifyJWT } from 'utils/jwt';
import { setDefaulsHeader } from 'utils/setDefaultHeaders';
import { removeFromLocalStorage, writeToLocalStorage } from 'utils/storage';
import { unsetDefaultHeader } from 'utils/unsetDefaultHeader';

export const login = async (data: IUserReqBody): Promise<IUserResponse> => {
  const response: AxiosResponse<IUserResponse> = await instance.post('/api/auth/sign_in', { client: data });
  const jwt = await verifyJWT(response);

  await setDefaulsHeader(jwt);

  const user = { ...response.data, ...jwt };

  writeToLocalStorage('user', user);

  return user;
};

export const logout = async (): Promise<IUserResponse> => {
  removeFromLocalStorage('user');
  unsetDefaultHeader();
  return await instance.delete('/api/auth/sign_out');
};
