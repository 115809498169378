import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Button from 'components/ui/Button';

const buttonAlign = css`
  ${Button} + ${Button} {
    margin-left: 16px;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  ${buttonAlign}
`;

const LeftGroup = styled(Group)`
  justify-content: flex-start;
`;

const RightGroup = styled(Group)`
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${buttonAlign}
`;

function ButtonGroup({ children, className }) {
  return (
    <Wrapper className={className}>
      {children}
    </Wrapper>
  );
}

ButtonGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export { LeftGroup, RightGroup };

export default styled(ButtonGroup)``;
