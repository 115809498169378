import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useNavigate, Outlet, useParams } from 'react-router-dom';
import { Text, Table, Breadcrumbs, Anchor } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import Loader from 'components/ui/Loader';

import { Main, TopBar, Content } from 'components/ui/Layout';

import useStyles from './Tariffs.styles';

import { STATUSES } from 'types';
import { fetchParsingTariffsAction } from 'store/parsingSlice/actions';
import { parsingTariffsAllSelector } from 'store/parsingSlice/selectors/tariffs';

const format = 'dd.MM.yyyy';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

function SearchArea({ className }: Props) {
  const { t } = useTranslation();

  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const [state, data]: [STATUSES, any, STATUSES?] = useAppSelector(parsingTariffsAllSelector);

  useEffect(() => {
    id && dispatch(fetchParsingTariffsAction(id));
  }, [id]);

  if (state !== STATUSES.FULFILLED) {
    return <Loader className={classes.loader} />;
  }

  const handleClick = (tariff: any) => {
    navigate(`/parsing/${id}/tariff/${tariff._id}/`);
  };

  const rows = data.tariffs.map((element: any) => {
    return (
    <tr key={element.uuid} className={classes.tableRow} onClick={() => handleClick(element)}>
      <td className={classes.td}>
        <Text>
        {element.name_translations.ru}
        </Text>

        <Text size={14} color='dimmed'>
          {element.room_type_object.name_translations.ru}
        </Text>
        </td>
      <td className={classes.tdLarge}>
        <Text lineClamp={2}>
          {element.description_translations.ru}
        </Text>
      </td>

      <td className={classes.td}>
        Sanatory.ru
      </td>

      <td className={classes.td}>
        {DateTime.fromFormat(element.available_period.from, 'yyyy-MM-dd').toFormat(format)} - {DateTime.fromFormat(element.available_period.to, 'yyyy-MM-dd').toFormat(format)}
      </td>
    </tr>
  )
    });

  return (
    <Main className={cx(className, classes.root)}>
      <TopBar>
        <Text size={25} color='#000'>
          <Breadcrumbs 
          separator={<Text size={25}>/</Text>}
          >
            <Anchor
              href='/parsing/'
            >
              {t('Parsing.Title')}
            </Anchor>

            <Text>
              {t('Tariffs.Title')}
            </Text>
          </Breadcrumbs>

        </Text>


      </TopBar>

      <Content className={classes.content}>
        <Table verticalSpacing='xl' horizontalSpacing='xl' className={classes.table} fontSize='md'>
          <thead>
            <tr className={classes.tr}>
              <th className={classes.th}>{t('Tariffs.Table.Field1')}</th>
              <th className={classes.th}>{t('Tariffs.Table.Field2')}</th>
              <th className={classes.th}>{t('Tariffs.Table.Field3')}</th>
              <th className={classes.th}>{t('Tariffs.Table.Field4')}</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </Content>

      <Outlet />
    </Main>
  );
}

export default SearchArea;
