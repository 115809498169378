import React, { useState } from 'react';
import { Box, Text } from '@mantine/core';
import { ThumbUp } from 'tabler-icons-react';

import { useTranslation } from 'react-i18next';

import useStyles from './RatingElement.styles';
import { IImage, IObjectDataFull, IObjectReviewCategory } from 'types';

type Props = {
  className?: string,
  element: IObjectReviewCategory
}

function BestReview({ className, element }: Props) {
  const { classes, cx } = useStyles({ ...element });
  const { t } = useTranslation();

  return (
    <Box className={cx(className, classes.root)}>
      <Text className={classes.name} size={16}>
        {element.name}
      </Text>

      <Box className={classes.slider}>
        <Box className={classes.sliderLine} />
      </Box>

      <Text size={16}>
        {element.rating}
      </Text>
    </Box>
  );
}
export default BestReview;
