import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext, Controller } from 'react-hook-form';

import Grid, { Row, Column } from 'components/ui/Grid';
import Field from 'components/ui/Field';
import Input from 'components/ui/Input';
import DatePicker from 'components/ui/DatePicker';
import TextArea from 'components/ui/TextArea';
import SpecializationsSelect from 'components/forms/SpecializationsSelect';
import Button from 'components/ui/Button';
import ButtonGroup from 'components/ui/ButtonGroup';
import Text from 'components/ui/Text';
import FileInput from 'components/ui/FileInput';
import MetaTagsForm from './MetaTagsForm';
import AwardsForm from './AwardsForm';
import * as yup from 'yup';

const SectionContainer = styled.div`
`;

const Wrapper = styled.div`
  ${SectionContainer} {
    &:not(:first-child) {
      margin-top: 24px;
    }

    > ${Text} {
      margin-bottom: 12px;
    }
  }

  ${ButtonGroup} {
    margin-top: 28px;
  }
`;

const FIRST_NAME_FIELD = 'first_name';
const LAST_NAME_FIELD = 'last_name';
const MIDDLE_NAME_FIELD = 'middle_name';
const AVATAR_FIELD = 'avatar';
const START_OF_PRACTICE_FIELD = 'start_of_practice';
const SPECIALIZATIONS_FIELD = 'specializations';
const SEO_TITLE_FIELD = 'seo_title';
const SEO_DESCRIPTION_FIELD = 'seo_description';

export const schema = yup.object().shape({
  [FIRST_NAME_FIELD]: yup.string().required(),
  [LAST_NAME_FIELD]: yup.string().required(),
  [SEO_TITLE_FIELD]: yup.string().required(),
  [SEO_DESCRIPTION_FIELD]: yup.string().required()
});

function ReviewerForm({ className }) {
  const { formState: { errors } } = useFormContext();

  return (
    <Wrapper
      className={className}
    >
      <SectionContainer>
        <Text>Общее</Text>

        <Grid>
          <Row>
            <Column
              xs={6}
            >
              <Field
                label='Имя'
                name={FIRST_NAME_FIELD}
                error={!!errors[FIRST_NAME_FIELD]}
                required
              >
                <Controller
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        error={!!errors[FIRST_NAME_FIELD]}
                        placeholder='Введите имя'
                      />
                    );
                  }}
                />
              </Field>
            </Column>

            <Column
              xs={6}
            >
              <Field
                label='Фамилия'
                name={LAST_NAME_FIELD}
                error={!!errors[LAST_NAME_FIELD]}
                required
              >
                <Controller
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        error={!!errors[LAST_NAME_FIELD]}
                        placeholder='Введите фамилию'
                      />
                    );
                  }}
                />
              </Field>
            </Column>
          </Row>

          <Row>
            <Column
              xs={6}
            >
              <Field
                label='Отчество'
                name={MIDDLE_NAME_FIELD}
              >
                <Controller
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        placeholder='Введите отчество'
                      />
                    );
                  }}
                />
              </Field>
            </Column>

            <Column
              xs={6}
            >
              <Field
                label='Аватар'
                name={AVATAR_FIELD}
              >
                <Controller
                  render={({ field }) => {
                    return (
                      <FileInput
                        {...field}
                      />
                    );
                  }}
                />
              </Field>
            </Column>
          </Row>

          <Row>
            <Column
              xs={6}
            >
              <Field
                label='Дата начала практики'
                name={START_OF_PRACTICE_FIELD}
              >
                <Controller
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                    />
                  )}
                />
              </Field>
            </Column>
          </Row>

          <Row>
            <Field
              label='Специализации'
              name={SPECIALIZATIONS_FIELD}
            >
              <Controller
                render={({ field }) => (
                  <SpecializationsSelect
                    {...field}
                  />
                )}
              />
            </Field>
          </Row>
        </Grid>

        <AwardsForm />
      </SectionContainer>

      <SectionContainer>
        <Text>Данные для SEO</Text>

        <Grid>
          <Row>
            <Field
              label='Заголовок'
              name={SEO_TITLE_FIELD}
              error={!!errors[SEO_TITLE_FIELD]}
              required
            >
              <Controller
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      error={!!errors[SEO_TITLE_FIELD]}
                      placeholder='Введите заголовок'
                    />
                  );
                }}
              />
            </Field>
          </Row>

          <Row>
            <Field
              label='Описание'
              name={SEO_DESCRIPTION_FIELD}
              error={!!errors[SEO_DESCRIPTION_FIELD]}
              required
            >
              <Controller
                render={({ field }) => {
                  return (
                    <TextArea
                      {...field}
                      error={!!errors[SEO_DESCRIPTION_FIELD]}
                      placeholder='Введите описание'
                    />
                  );
                }}
              />
            </Field>
          </Row>
        </Grid>

        <MetaTagsForm />
      </SectionContainer>

      <ButtonGroup>
        <Button
          type='submit'
          size='medium'
        >
          Сохранить
        </Button>

        <Button
          type='reset'
          size='medium'
          variant='secondary'
        >
          Отмена
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
}

ReviewerForm.defaultProps = {
  className: ''
};

ReviewerForm.propTypes = {
  className: PropTypes.string
};

export default styled(ReviewerForm)``;
