import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  font-family: GTEesti;
  line-height: 36px;
`;

const LisItem = styled.div`
  padding: 60px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  min-width: 611px;
  max-width: 611px;
  background: #e5eafc;
  font-size: 20px;
  line-height: 34px;
  color: #323232;

  &:nth-child(odd) {
    background: #eef9e4;
  }
`;

const ListContainer = styled.div`
  margin-top: 48px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative
`;

const Arrow = styled.div`
  position: absolute;
  width: 50px;
  border-radius: 50%;
  height: 50px;
  cursor: pointer;
  background-size: 30%;
  top: 50%;
  transform: translateY(-50%);
  background: hsla(0,0%,100%,.802338);
  box-shadow: 0 2px 5px 2px rgb(143 151 159 / 24%);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjQ4MDQ3IDEyTDYuNTIwNDcgNy4wMzAyMUwxLjQ4MDQ3IDEuOTIiIHN0cm9rZT0iIzRDNEM0QyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
  background-position: 50%;
  background-repeat: no-repeat;
`;

const ArrowLeft = styled(Arrow)`
  left: -25px;
  transform: translateY(-50%) rotate(-180deg);
`;

const ArrowRight = styled(Arrow)`
  right: -25px;
`;

const Wrapper = styled.div`
  margin-top: 68px;
`;

function Facts({ className, data }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const facts = data.list;

  const handleNext = () => {
    setActiveIndex(activeIndex + 1);
  };

  const handlePrev = () => {
    setActiveIndex(activeIndex === 0 ? facts.length - 1 : activeIndex - 1);
  };

  return (
    <Wrapper className={className}>
      <Title dangerouslySetInnerHTML={{ __html: data.title }} />

      <ListContainer>
        <LisItem dangerouslySetInnerHTML={{ __html: facts[activeIndex % facts.length] }} />

        <LisItem dangerouslySetInnerHTML={{ __html: facts[(activeIndex + 1) % facts.length] }} />

        <ArrowLeft onClick={handlePrev} />

        <ArrowRight onClick={handleNext} />
      </ListContainer>
    </Wrapper>
  );
}

Facts.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

export default styled(Facts)``;
