import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Waypoint } from 'react-waypoint';

import { getImage } from 'Utils/getImage';

import RightContainer from './RightContainer';
import useHotelPrice from 'hooks/useHotelPrice';

const Image = styled.img`
  border-radius: 9px;
  width: 300px;
  height: 210px;
`;

const Wrapper = styled.div`
  padding: 24px;
  background: #f5f0ec;
  border-radius: 9px;
  display: flex;
  margin: 10px 0;
  box-sizing: border-box;
  max-width: 788px;
  min-width: 788px;

  ${RightContainer} { 
    margin-left: 20px;
  }
`;

function HotelCardSmall({ className, data: { hotel } }) {
  const [{ price, isPending }, { fetch }] = useHotelPrice(hotel);

  if (!hotel.id) return;

  const handleEnter = () => {
    !isPending && !price && fetch();
  };

  const img = getImage(hotel.image.encoded_url, { width: 600, height: 420 });

  return (
    <Waypoint onEnter={handleEnter}>
      <Wrapper className={className}>
        <Image src={img.defaultSrc} />

        <RightContainer hotel={hotel} price={price} isPending={isPending} />
      </Wrapper>
    </Waypoint>
  );
}

HotelCardSmall.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

export default styled(HotelCardSmall)``;
