import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme, _, getRef) => ({
  root: {
    padding: 20,
    boxSizing: 'border-box'
  },
  regions: {
    marginTop: 20
  },
  badge: {
    ref: getRef('badge'),
    width: 16,
    height: 16,
    pointerEvents: 'none'
  },
  tab: {
    '&[data-active]': {
      backgroundColor: theme.colors.blue[7],
      borderColor: theme.colors.blue[7],
      color: theme.white,

      [`& .${getRef('badge')}`]: {
        background: 'white',
        color: '#000'
      }
    }
  }
}));

export default useStyles;
