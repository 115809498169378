import { css } from 'styled-components';

const transition = (props) => css`
  transition: all .2s ease-in-out;
`;

const disabled = (props) => css`
  pointer-events: none;
  color: #c0c0c0;
`;

const defaults = (props) => css`
  background-color: #FFFFFF;
  padding: 4px 12px;
  cursor: pointer;

  &:first-child {
    padding-top: 8px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    padding-bottom: 8px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    background-color: #EAE9E4;
  }
`;

const theme = (props) => ([
  defaults(props),
  transition(props),
  (props.disabled ? disabled(props) : css``)
]);

export default theme;
