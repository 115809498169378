import React from 'react';
import { MapContainer as Map, TileLayer, Marker, ZoomControl } from 'react-leaflet';

import getMapTilesUrl from 'utils/getMapTilesUrl';

import Distances from './Distances';
import { IObjectDataFull } from 'types';
import useStyles from './Map.styles';
import { Box } from '@mantine/core';

type Props = {
  className?: string,
  object: IObjectDataFull,
  hotel: any,
  currentLanguage: string,
  tileBaseUrl: string
}

function MapPage({ object, className, hotel, tileBaseUrl, currentLanguage }: Props) {
  const { classes, cx } = useStyles();
  const tileUrl = getMapTilesUrl({ tileBaseUrl });

  const coordinates = [...hotel.address.coordinates].reverse();

  const distances = object.body_translations[currentLanguage]?.blocks?.find((b) => b.type === 'distances')?.data?.list;

  if (!distances) {
    return <Box />;
  }

  const distancesElement = distances.length > 0 && (
    <Box>
      <Distances distances={distances} />
    </Box>
  );

  return (
    <Box className={cx(className, classes.root)}>
      <Map
        animate
        minZoom={1}
        useFlyTo
        center={coordinates}
        zoom={7}
        zoomControl={false}
      >
        <TileLayer
          url={tileUrl}
        />

        <ZoomControl position='bottomright' />

        <Marker
          position={coordinates}
        />
      </Map>

      <Box className={classes.mapInfoContainer}>
        {distancesElement}
      </Box>
    </Box>
  );
}

MapPage.defaultProps = {
  tileBaseUrl: 'https://tiles.worldota.net/api/v1/t/ostrovok'
};

export default MapPage;
