import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Item = styled.a`
  font-size: 14px;
  color: #999999;
  text-decoration: none;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  ${Item} + ${Item} {
    &:before {
      content: '/';
      margin: 0 6px;
    }
  }
`;

function Breadcrumbs({ links, className }) {
  return (
    <Wrapper className={className}>
      {links.map(({ name, href, onClick }, idx) => (
        <Item
          key={idx}
          href={href}
          onClick={onClick}
        >
          {name}
        </Item>
      ))}
    </Wrapper>
  );
}

Breadcrumbs.defaultProps = {
  varian: 'primary'
};

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func
    })
  )
};

export default styled(Breadcrumbs)``;
