import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {

  },
  regions: {
    marginTop: 20
  },
  breadcrumbs: {
    marginBottom: 20
  },
  region: {
    marginBottom: 12
  },
  select: {
    marginTop: 4,
    width: '100%'
  }
}));

export default useStyles;
