import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {},
  breadcrumbs: {
    marginBottom: 20
  },
  sideLeft: {
    flexGrow: 0,
    flexShrink: 0
  },
  main: {
    flexGrow: 1
  },
  topbar: {
    flexGrow: 0,
    flexShrink: 0
  },
  content: {
    flexGrow: 1
  },
  table: {
    background: '#FFF',
    border: '1px solid #c0c0c0',
    position: 'relative',
    marginBottom: 20
  },
  box: {
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between'
  },
  boxHeader: {
    padding: 24,
    display: 'flex',
    position: 'sticky',
    justifyContent: 'space-between',
    top: 0,
    background: 'rgb(234,233,228)'
  },
  text: {
    width: 175
  },
  boxText: {
    cursor: 'pointer',
    width: 180,
    '&:nth-of-type(6)': {
      width: 160
    },
    '&:last-of-type': {
      width: 20
    }
  }
}));

export default useStyles;
