import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/ui/Select';
import useOptions from './hooks/useOptions';

const MetaTagsSelect = React.forwardRef(({ className, placeholder, ...rest }, ref) => {
  const options = useOptions();

  return (
    <Select
      options={options}
      placeholder={placeholder}
      valueField='tag'
      labelField='name'
      simpleValue
      {...rest}
    />
  );
});

MetaTagsSelect.defaultProps = {
  className: '',
  placeholder: 'Выберите тэг'
};

MetaTagsSelect.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string
};

export default MetaTagsSelect;
