import { startAppListening } from '../../listenerMiddleware';

import { toastify } from 'utils/toatify';

import {
  fetchOneObjectAction,
  updateObjectAction
} from '../actions';

startAppListening({
  actionCreator: fetchOneObjectAction.rejected,
  effect: async (action, listenerApi) => {
    toastify('error', 'Не удалось получить данные');
  }
});

startAppListening({
  actionCreator: updateObjectAction.rejected,
  effect: async (action, listenerApi) => {
    toastify('error', 'Не удалось обновитть статью');
  }
});

startAppListening({
  actionCreator: updateObjectAction.fulfilled,
  effect: async (action, listenerApi) => {
    toastify('success', 'Статья успешно обновлена');
  }
});
