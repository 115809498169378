import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  font-family: GTEesti;
  line-height: 36px;
`;

const LisItem = styled.li`
  padding-left: 30px;
  position: relative;
  color: #323232;
  font-family: GTEesti;
  font-size: 20px;
  max-width: 90%;
  list-style: none;
  letter-spacing: 0;
  line-height: 28px;
  padding-top: 12px;
  width: 100%;
  padding-bottom: 13px;

  &:before {
    position: absolute;
    content: "";
    box-sizing: border-box;
    background: #1bc47d;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEwIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDQuNzA0MDJMNC40MTg1MiA4TDkgMSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjwvcGF0aD4KPC9zdmc+);
    height: 16px;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 16px;
    left: 0;
    color: #fff;
    top: 18px;
    border-radius: 50%;
  }
`;

const ListContainer = styled.ul`
  padding-left: 0;
  margin: 0;
  column-count: 2;
  width: 1060px;
  display: inline-block;
  margin-top: 32px;
`;

const Description = styled.div`
  margin-top: 32px;
  font-size: 20px;
  line-height: 34px;
  font-family: GTEesti;
`;

const Wrapper = styled.div`
  margin-top: 68px;

  ${Description} + ${ListContainer} {
    margin-top: 16px;
  }
`;

function Diseases({ className, data }) {
  const items = data.list.map((el, index) => <LisItem key={index} dangerouslySetInnerHTML={{ __html: el }} />);
  return (
    <Wrapper className={className}>
      <Title dangerouslySetInnerHTML={{ __html: data.title }} />

      {data.description && (
        <Description dangerouslySetInnerHTML={{ __html: data.description }} />
      )}

      <ListContainer>
        {items}
      </ListContainer>
    </Wrapper>
  );
}

Diseases.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

export default styled(Diseases)``;
