import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { Drawer, useMantineTheme } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';

import useStyles from './Edit.styles';

import Form from './Form';

import { fetchOneSearchAreaAction } from 'store/searchAreaSlice/actions';

import { searchAreaSelector } from 'store/searchAreaSlice/selectors';
import { IRegionDocument, ISearchArea, ISearchAreaBreadcrumbs } from 'types';

import Loader from 'components/ui/Loader';

type Props = React.PropsWithChildren<{
  className?: string
}>

function SearchArea({ className }: Props) {
  const { id } = useParams();
  const [opened, setOpened] = useState<boolean>(false);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const dispatch = useAppDispatch();
  const [breadcrumbs, searchArea]: [ISearchAreaBreadcrumbs[], ISearchArea?] = useAppSelector(searchAreaSelector);
  const [selectedRegions, setSelectedRegions] = useState<IRegionDocument[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const data: any = await dispatch(fetchOneSearchAreaAction(String(id)));
      const areas = data.payload.data.search_additionals;
      const selectedRegionsResult: IRegionDocument[] = areas.map((d: any) => {
        return {
          document: {
            inc_number: d.area_id,
            id: d.area_id,
            label_en: d.name,
            label_ru: d.name,
            scope: 'point',
            slug: 'search/list'
          }
        };
      });
      setSelectedRegions(selectedRegionsResult);

      setOpened(true);
    };

    fetch();
  }, []);

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Drawer
      position='right'
      overlayColor={theme.colors.dark[9]}
      opened={opened}
      onClose={handleClose}
      overlayOpacity={0.55}
      size='xl'
      transition='slide-left'
      transitionDuration={100}
      transitionTimingFunction='ease'
      overlayBlur={3}
    >

      {searchArea && <Form searchArea={searchArea} onClose={handleClose} breadcrumbs={breadcrumbs} selectedRegions={selectedRegions} />}

      {!searchArea && <Loader className={classes.loader} />}
    </Drawer>
  );
}

export default SearchArea;
