import styled, { css } from 'styled-components';

const rcSelect = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0 12px;
  border-radius: 4px;
  background: #FFFFFF;
  color: #333333;
  cursor: pointer;
  border: 1px solid #C8C4BC;

  &.rc-select-open {
    border-radius: 4px 4px 0px 0px;

    .rc-select-arrow {
      margin-top: -4px;
      transform: rotate(-180deg);
    }
  }

  .rc-select-selector + .rc-select-arrow {
    margin-left: 12px;
  }

  ${({ isSearch }) => isSearch && css`
    .rc-select-selection-item {
      display: none;
    }
  `}

  ${({ isSearch }) => !isSearch && css`
    .rc-select-selection-search {
      display: none;
    }
  `}
`;

const rcSelector = css`
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: inherit;

  .rc-select-selection-search {}

  .rc-select-selection-item {}

  .rc-select-selection-placeholder {
    color: #999999;
  }
`;

const rcArrow = css`
  flex-shrink: 0;
  font-size: 12px;
`;

const rcSelectDropdown = css`
  z-index: 1;

  &.rc-select-dropdown-hidden {
    display: none;
  }
`;

const rcVirtualList = css`
  border: 1px solid #C4C2BB;
  border-top: none;
  box-sizing: border-box;
  border-radius: 0px 0px 4px 4px;

  .rc-select-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    padding: 0 12px;
    background: #FFFFFF;
    cursor: pointer;

    &:last-child {
      border-radius: 0px 0px 4px 4px;
    }

    &.rc-select-item-option-active {
      background: #EAE9E4;
    }

    &.rc-select-item-option-selected {
      background: #EAE9E4;
    }

    .rc-select-item-option-state {
      display: none;
    }
  }
`;

const rcSelectItemEmpty = css`
  border: 1px solid #C8C4BC;
  border-top: none;
  padding: 8px 12px;
  background: #FFFFFF;
  cursor: not-allowed;
`;

const rcSelectMultiple = css`
  height: auto;

  .rc-select-selector {
    padding: 8px 0px;
  }
`;

const rcSelectSelectionOverflow = css`
  display: flex;
  flex-wrap: wrap;
  padding-top: 4px;

  .rc-select-selection-overflow-item-suffix {
    display: none;
  }
`;

const rcSelectSelectionOverflowItem = css`
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 2px 6px;
  background-color: #D2D1CC;
  border-radius: 4px;
`;

const Wrapper = styled.div`
  font-family: Helvetica Neue;
  font-size: 14px;

  .rc-select {
    ${rcSelect}

    .rc-select-selector {
      ${rcSelector}
    }

    .rc-select-arrow {
      ${rcArrow}
    }
  }

  .rc-select-multiple {
    ${rcSelectMultiple}

    .rc-select-selection-overflow {
      ${rcSelectSelectionOverflow}
    }

    .rc-select-selection-overflow-item {
      ${rcSelectSelectionOverflowItem}
    }
  }

  .rc-select-dropdown {
    ${rcSelectDropdown}

    .rc-virtual-list {
      ${rcVirtualList}
    }
  }

  .rc-select-item-empty {
    ${rcSelectItemEmpty}
  }

  ${({ error }) => error && css`
    .rc-select {
      border: 1px solid #FF5656;
      color: #FF5656;
    }
  `}
`;

export default Wrapper;
