import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route } from 'react-router-dom';

import { List, Add, Edit, EditForm } from './containers';

function Reviewers({ className }) {
  return (
    <Routes>
      <Route path='/:id/edit' element={<Edit />} />

      <Route path='/' element={<List />}>
        <Route path='/add' element={<Add />} />

        <Route path='/:id/editform' element={<EditForm />} />
      </Route>
    </Routes>
  );
}

Reviewers.propTypes = {
  className: PropTypes.string
};

export default Reviewers;
