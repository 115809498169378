import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Card from '../../Card';

import { SelectArrowIcon } from 'components/ui/Icons';
import applySort from './utils/applySort';

const DropdownIcon = styled(SelectArrowIcon)`
  transform: rotate(180deg);
  font-size: 10px;
  margin-left: 6px;
`;

const Tr = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, 1fr);
  padding: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  background: rgb(234, 233, 228);
`;

const Th = styled.div`
  ${({ active }) => active && css`
    font-weight: 700;

    ${DropdownIcon} {
      transform: rotate(0deg);
    }
  `}

  ${({ clickable }) => clickable && css`
    cursor: pointer;
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  font-size: 14px;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  background: #FFF;
  border: 1px solid #c0c0c0;
`;

function ListCards({ data, className, onEdit, onEditAdditional, onPublish, type, sitePath, onDelete, onEditForm }) {
  const [sort, setSort] = useState();
  const displayData = applySort(data, sort);

  const handleClick = (tag) => () => {
    tag === sort ? setSort(null) : setSort(tag);
  };

  return (
    <Wrapper className={className} type={type}>
      <Tr>
        <Th
          onClick={handleClick('name')}
          active={sort === 'name'}
          clickable
        >
          Название
          <DropdownIcon />
        </Th>

        <Th>
          Время
        </Th>

        <Th
          onClick={handleClick('category')}
          active={sort === 'category'}
          clickable
        >
          Категория

          <DropdownIcon />
        </Th>

        <Th>
          Действия
        </Th>

        <Th
          onClick={handleClick('publish')}
          active={sort === 'publish'}
          clickable
        >
          Опубликовать

          <DropdownIcon />
        </Th>
      </Tr>

      {displayData.map((blog) => (
        <Card
          key={blog.id}
          blog={blog}
          onEditForm={onEditForm}
          onEdit={onEdit(blog)}
          onEditAdditional={onEditAdditional(blog)}
          onPublish={onPublish(blog)}
          type={type}
          sitePath={sitePath}
          onDelete={onDelete}
        />
      ))}
    </Wrapper>
  );
}

ListCards.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  onEdit: PropTypes.func,
  sitePath: PropTypes.string,
  onEditForm: PropTypes.func,
  onDelete: PropTypes.func,
  type: PropTypes.string,
  onEditAdditional: PropTypes.func,
  onPublish: PropTypes.func
};

export default styled(ListCards)``;
