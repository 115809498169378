import { createStyles } from '@mantine/styles';

type Props = {
  hasBottomBar?: boolean
}

const useStyles = createStyles((theme, { hasBottomBar }: Props) => ({
  root: {
    width: 'inherit',
    height: 'inherit',
    padding: '0 30px 30px',
    overflowY: 'visible',
    boxSizing: 'border-box',
    paddingBottom: hasBottomBar ? 90 : 0,
    minWidth: 1000
  }
}));

export default useStyles;
