import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Treatment from './Treatment';

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  font-family: GTEesti;
  line-height: 36px;
`;

const Description = styled.div`
  font-family: GTEesti;
  font-size: 20px;
  line-height: 34px;
  color: #323232;
  max-width: 936px;
  margin-top: 47px;
`;

const ItemsContainer = styled.div`
  margin-top: 32px;
  display: flex;

  ${Treatment} + ${Treatment} {
    margin-left: 27px;
  }
`;

const Wrapper = styled.div`
  margin-top: 68px;
`;

function About({ className, data }) {
  const [opened, setOpened] = useState();

  const handleClick = (treatment) => {
    setOpened(opened?.title === treatment.title ? null : treatment);
  };

  const treatments = data.items.map((treatment, index) => (
    <Treatment
      treatment={treatment}
      key={index}
      opened={treatment.title === opened?.title}
      onClick={handleClick}
    />
  ));

  return (
    <Wrapper className={className}>
      <Title dangerouslySetInnerHTML={{ __html: data.title }} />

      <ItemsContainer>
        {treatments}
      </ItemsContainer>

      <Description dangerouslySetInnerHTML={{ __html: opened?.description }} />
    </Wrapper>
  );
}

About.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

export default styled(About)``;
