import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import _uniqueId from 'lodash/uniqueId';

const Text = styled.div`
  color: #666666;
  white-space: nowrap;
`;

const PrefixText = styled(Text)`
  margin-right: 8px;
`;

const PostfixText = styled(Text)`
  margin-left: 8px;
`;

const InputElement = styled.input`
  flex-grow: 1;
  width: 100%;
  height: inherit;
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  color: inherit;

  &::placeholder {
    color: #999999;
  }

  ${({ withPrefix }) => withPrefix && css`
    text-align: right;
  `}

  ${({ withPostfix }) => withPostfix && css`
    text-align: left;
  `}

  ${({ valueAlign }) => valueAlign === 'right' && css`
    text-align: right;
  `}

  ${({ valueAlign }) => valueAlign === 'left' && css`
    text-align: left;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #DCD8D2;
  background: #FFFFFF;
  font-family: Helvetica Neue;
  color: #333333;
  box-sizing: border-box;
  font-size: 14px;
  padding: 0 12px;
  border-radius: 4px;

  ${({ size }) => size === 'medium' && css`
    height: 32px;
  `}

  ${({ size }) => size === 'large' && css`
    height: 42px;
  `}

  ${({ isFocused }) => isFocused && css`
    border: 1px solid #C8C4BC;
    z-index: 9;
  `}

  ${({ isError }) => isError && css`
    border: 1px solid #FF5656;
    color: #FF5656;
  `}

  ${({ isDisabled }) => isDisabled && css`
    pointer-events: none;
    opacity: 0.7;
  `}
`;

const IconWrapper = styled.div`
  padding: 0 10px 0 0;
`;

const Input = React.forwardRef(({
  id: initialId,
  value: initialValue,
  valueAlign,
  error,
  disabled,
  size,
  prefix,
  postfix,
  className,
  onFocus,
  onBlur,
  onChange,
  selectOnFocus,
  withIcon,
  ...rest
}, ref) => {
  const inputRef = useRef();

  const id = useRef(initialId);

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (value !== initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const [focused, setFocused] = useState(false);

  const withPrefix = !!prefix;
  const withPostfix = !!postfix;

  const handleFocus = (e) => {
    setFocused(true);

    if (selectOnFocus) {
      inputRef.current.select();
    }

    onFocus && onFocus(e);
  };

  const handleBlur = (e) => {
    setFocused(false);
    onBlur && onBlur(e);
  };

  const handleClick = (e) => {
    inputRef.current.focus();
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange && onChange(newValue);
  };

  return (
    <Wrapper
      isError={error}
      isDisabled={disabled}
      isFocused={focused}
      onClick={handleClick}
      size={size}
      className={className}
    >
      {withPrefix && (
        <PrefixText>{prefix}</PrefixText>
      )}
      {withIcon && <IconWrapper>{withIcon}</IconWrapper>}
      <InputElement
        id={id.current}
        ref={inputRef}
        disabled={disabled}
        value={value}
        valueAlign={valueAlign}
        withPrefix={withPrefix}
        withPostfix={withPostfix}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        {...rest}
      />

      {postfix && (
        <PostfixText>
          {postfix}
        </PostfixText>
      )}
    </Wrapper>
  );
});

Input.defaultProps = {
  id: _uniqueId('input-'),
  value: '',
  valueAlign: 'left',
  size: 'medium',
  selectOnFocus: false,
  error: false,
  disabled: false
};

Input.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  valueAlign: PropTypes.oneOf(['left', 'right']),
  size: PropTypes.oneOf(['medium', 'large']),
  prefix: PropTypes.any,
  postfix: PropTypes.any,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  selectOnFocus: PropTypes.bool,
  withIcon: PropTypes.any
};

export default styled(Input)``;
