import connection from 'connection/instance';
import { AxiosResponse } from 'axios';
import { IRequest, IResponseData } from 'types';
import { IAvailabilitiesMeta, IAvailabilitiesRequestData, IAvailabilitiesResponse } from 'types/availabilities';

export const availabilitesFetch = async (requestParams: IAvailabilitiesRequestData): Promise<IResponseData<IAvailabilitiesResponse[], IAvailabilitiesMeta>> => {
  const params: IRequest<IAvailabilitiesRequestData> = {
    data: {
      ...requestParams
    }
  };

  const response: AxiosResponse<IResponseData<IAvailabilitiesResponse[], IAvailabilitiesMeta>> = await connection.get(
    '/api/reports/availabilities',
    { params }
  );

  return response.data;
};
