import React from 'react';
import { Box, Container } from '@mantine/core';

import useStyles from './Content.styles';

type Props = React.PropsWithChildren<{
  hasBottomBar?: boolean,
  className?: string,
  onScroll?: (e: any) => void
}>

function Content({ children, hasBottomBar, className, onScroll }: Props) {
  const { classes, cx } = useStyles({ hasBottomBar });

  return (
    <Container
      className={cx(classes.root, className)}
      fluid
      onScroll={onScroll}
    >
      {children}
    </Container>
  );
}

export default Content;
