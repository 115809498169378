import React, { useState } from 'react';

import useStyles from './ProgrammsContent.styles';

import CommonPart from '../CommonPart';
import Dropdown from './Dropdown';
import { Box } from '@mantine/core';
import getTranslate from 'utils/getTranslate';
import { IObjectDataFull } from 'types';

type Props = {
  className?: string,
  object: IObjectDataFull,
  currentLanguage: string
}

function ProgrammsContent({ className, object, currentLanguage }: Props) {
  const [openedProgramId, setOpenedProgramId] = useState<string | null>(null);
  const { t } = getTranslate(currentLanguage);
  const { classes } = useStyles();

  const handleOpen = (id: string) => () => {
    if (openedProgramId === id) {
      setOpenedProgramId(null);
      return;
    }

    setOpenedProgramId(id);
  };

  const programs = object.body_translations[currentLanguage]?.blocks.find((b) => b.type === 'programs').data.list;

  const treatmentComponents = programs.map((program: any, index: number) => (
    <Dropdown
      className={classes.dropdown}
      program={program}
      key={index}
      onClick={handleOpen(program.title)}
      opened={program.title === openedProgramId}
    />
  ));

  return (
    <Box className={className}>
      <CommonPart>
        <Box className={classes.title} dangerouslySetInnerHTML={{ __html: t('HotelPage.Content.Programms.Description') }} />

        <Box className={classes.content}>
          {treatmentComponents}
        </Box>
      </CommonPart>
    </Box>
  );
}

export default ProgrammsContent;
