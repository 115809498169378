import { Box, Text } from '@mantine/core';
import React from 'react';

import useStyles from './NavLink.styles';

type Props = {
  className?: string,
  item: Tab,
  onClick: () => void,
  active: boolean
}

type Tab = {
  name: string,
  tag: string,
  isActive: boolean
}

function NavLink({ className, onClick, item, active }: Props) {
  const { classes } = useStyles({ active });
  if (!item.isActive) return null;

  return (
    <Box
      className={classes.root}
      onClick={onClick}
    >
      <Text>
        {item.name}
      </Text>
    </Box>
  );
}

export default NavLink;
