
import { createStyles } from '@mantine/styles';

type Props = {
  active: boolean
}

const useStyles = createStyles((theme, { active }: Props) => ({
  root: {
    padding: '8px 15px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    borderRadius: 4,
    fontSize: 16,
    lineHeight: 19,
    userSelect: 'none',
    background: active ? '#F1E5DD' : 'transparent',
    '&:hover': {
      background: '#F1E5DD'
    }
  }
}));

export default useStyles;
