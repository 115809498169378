import connection from 'connection/instance';

class SpecializationsApi {
  async fetchList() {
    try {
      const { data } = await connection.get('/api/specializations');

      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }
}

export default new SpecializationsApi();
