import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Stars from 'components/ui/Stars';

const Name = styled.div`
  font-family: GTEesti;
  font-size: 30px;
  line-height: 32px;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AddressContainer = styled.div`
  margin-top: 12px;
  font-size: 18px;
  font-family: GTEesti;
  line-height: 21px;
`;

const Reviews = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: 19px;
  color: #3582db;
  cursor: pointer;
  text-align: center;
`;

const RatingContainer = styled.div`
  color: #6dd098;
  display: flex;
  align-items: center;
  background: #e4f9ee;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 12px 16px 15px;
  font-size: 20px;
  width: 83px;
  margin-left: auto;
  line-height: 23px;
  font-weight: 700;

  &:before {
    content: '';  
    min-width: 20px;
    margin-right: 8px;
    min-height: 18px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNCAxMyIgZmlsbD0iIzZkZDA5OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0wIDQuNDAyNDdWMTEuNDAyNUMwIDExLjg4NTcgMC4zOTE3MzEgMTIuMjc3NSAwLjg3NQogICAgICAxMi4yNzc1SDMuNVYzLjUyNzQ3SDAuODc1QzAuMzkxNzMxIDMuNTI3NDcgMCAzLjkxOTIgMCA0LjQwMjQ3WiI+PC9wYXRoPgoKICAgIDxwYXRoIGQ9Ik0xMi4zODQyIDQuNDAyMjhIOS43OTkzMkM5LjU3MzMxIDQuNDAyMjggOS40NjAwOSA0LjI1MTQ3CiAgICAgIDkuNDIyMDYgNC4xODY1MkM5LjM4NDA0IDQuMTIyMDEgOS4zMDcxMyAzLjk0OTgzIDkuNDE2NTEKICAgICAgMy43NTI0NEwxMC4zMjc4IDIuMTExMzlDMTAuNTI3OCAxLjc1MjA3IDEwLjU0ODMgMS4zMjk1MwogICAgICAxMC4zODQ2IDAuOTUyMjY2QzEwLjIyMSAwLjU3NDU4MSA5Ljg5ODAxIDAuMzAwNzE2IDkuNDk4NTMKICAgICAgMC4yMDExNjhMOC44NTU5NSAwLjA0MDUyMzRDOC42OTk1OCAwLjAwMTIxNjc3IDguNTMzODEKICAgICAgMC4wNTEyMDQ2IDguNDI0ODYgMC4xNzIxMTZMNC45MzU5NyA0LjA0ODA5QzQuNTc0MSA0LjQ1MDk5CiAgICAgIDQuMzc1IDQuOTcwNTIgNC4zNzUgNS41MTE0MVYxMC4wODk4QzQuMzc1IDExLjI5NTkgNS4zNTYzOAogICAgICAxMi4yNzczIDYuNTYyNSAxMi4yNzczTDEwLjkxNDkgMTIuMjc2OEMxMS44OTc1IDEyLjI3NjgKICAgICAgMTIuNzY1MyAxMS42MTUgMTMuMDI0NiAxMC42Njc4TDEzLjk1ODYgNi4zODE3QzEzLjk4NTkgNi4yNjI1CiAgICAgIDE0IDYuMTQwMzEgMTQgNi4wMTgxMkMxNCA1LjEyNzMxIDEzLjI3NSA0LjQwMjI3IDEyLjM4NDEKICAgICAgNC40MDIyN0wxMi4zODQyIDQuNDAyMjhaIj48L3BhdGg+CiAgPC9zdmc+);
  }
`;

const Amenities = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
`;

const Amenity = styled.div`
  margin-bottom: 8px;
  margin-right: 8px;
  background: #f5f5f7;
  border-radius: 32px;
  font-size: 16px;
  line-height: 19px;
  padding: 4px 12px;
  white-space: nowrap;
  color: #999;
  font-family: GTEesti;
`;

const StarsContainer = styled.div`
  display: flex;
  margin-left: 8px;
`;

const LeftContainer = styled.div`

`;

const RightContainer = styled.div`
  min-width: 96px;
  max-width: 96px;
  margin-left: 10px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const site = process.env.REACT_APP_SITE || 'https://sanatory-testing.ru';

function Top({ className, hotel }) {
  const amenitiesComponent = hotel.amenities.map((am, index) => <Amenity key={index}>{am.text}</Amenity>);

  const handleClick = () => {
    window.open(`${site}/hotel/${hotel.slug}/reviews`);
  };

  return (
    <Wrapper className={className}>
      <LeftContainer>
        <NameContainer>
          <Name dangerouslySetInnerHTML={{ __html: hotel.name }} />
          {hotel.star > 3 && (
            <StarsContainer>
              <Stars
                size='medium'
                stars={hotel.star}
              />
            </StarsContainer>
          )}
        </NameContainer>

        {hotel.address?.location && (
          <AddressContainer>
            {hotel.address.location}
          </AddressContainer>
        )}

        <Amenities>
          {amenitiesComponent}
        </Amenities>
      </LeftContainer>

      <RightContainer>
        {hotel.rating > 0 && (
          <RatingContainer>
            {hotel.rating}
          </RatingContainer>
        )}

        {hotel.review?.total > 0 && (
          <Reviews onClick={handleClick}>
            {hotel.review.total} отзывов
          </Reviews>
        )}
      </RightContainer>
    </Wrapper>
  );
}

Top.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object
};

export default styled(Top)``;
