import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Title = styled.div`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  width: 100%;
  border-radius: 9px;
  padding: 21px 40px 17px 24px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 16px;
  position: relative;
  font-family: GTEesti;

  &:before {
    content: "";
    position: absolute;
    right: 19px;
    top: 0;
    height: 100%;
    width: 20px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNMTAwNy43MDcxMSwzMjQzLjcwNzExIEwxMDEzLjI5Mjg5LDMyNDkuMjkyODkgQzEwMTMuNjgzNDIsMzI0OS42ODM0MiAxMDEzLjY4MzQyLDMyNTAuMzE2NTggMTAxMy4yOTI4OSwzMjUwLjcwNzExIEMxMDEzLjEwNTM2LDMyNTAuODk0NjQgMTAxMi44NTEsMzI1MSAxMDEyLjU4NTc5LDMyNTEgTDEwMDEuNDE0MjEsMzI1MSBDMTAwMC44NjE5MywzMjUxIDEwMDAuNDE0MjEsMzI1MC41NTIyOCAxMDAwLjQxNDIxLDMyNTAgQzEwMDAuNDE0MjEsMzI0OS43MzQ3OCAxMDAwLjUxOTU3LDMyNDkuNDgwNDMgMTAwMC43MDcxMSwzMjQ5LjI5Mjg5IEwxMDA2LjI5Mjg5LDMyNDMuNzA3MTEgQzEwMDYuNjgzNDIsMzI0My4zMTY1OCAxMDA3LjMxNjU4LDMyNDMuMzE2NTggMTAwNy43MDcxMSwzMjQzLjcwNzExIFoiIHRyYW5zZm9ybT0icm90YXRlKDE4MCA1MDcgMTYyNS41KSIgZmlsbD0iIzRDNEM0QyIgZmlsbC1ydWxlPSJldmVub2RkIj48L3BhdGg+Cjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-position: 50%;
    opacity: .6;
  }
`;

const Description = styled.div`
  box-sizing: border-box;
  width: 100%;
  font-family: GTEesti;
  max-width: 700px;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
  font-weight: 500;
  padding: 4px 24px 18px;
`;

const Wrapper = styled.div`
  background: #f5f5f7;
  border-radius: 4px;
  width: 826px;

  ${({ opened }) => opened && css`
    background: #eeeef3;
  `}
`;

function Treatment({ className, tab, opened, onClick }) {
  return (
    <Wrapper className={className} onClick={() => onClick(tab)} opened={opened}>
      <Title dangerouslySetInnerHTML={{ __html: tab.title }} />

      {opened && (
        <Description dangerouslySetInnerHTML={{ __html: tab.description }} />
      )}
    </Wrapper>
  );
}

Treatment.propTypes = {
  className: PropTypes.string,
  tab: PropTypes.object,
  opened: PropTypes.bool,
  onClick: PropTypes.func
};

export default styled(Treatment)``;
