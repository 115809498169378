import React from 'react';
import { Box } from '@mantine/core';

import useStyles from './Layout.styles';

type Props = React.PropsWithChildren<{
  className?: string | undefined,
  children: React.ReactNode
}>

function Layout({ children, className }: Props) {
  const { classes, cx } = useStyles();

  return (
    <Box className={cx(classes.root, className)}>
      {children}
    </Box>
  );
}

export default Layout;
