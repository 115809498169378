
import { createStyles } from '@mantine/styles';

const useStyles = createStyles(() => ({
  root: {
    padding: 20,
    paddingTop: 0,
    boxSizing: 'border-box'
  },

  ul: {
    margin: 0,
    padding: 0,
    marginTop: 8
  },

  li: {
    listStyle: 'none',
    margin: 0,
    fontSize: 16,
    lineHeight: '18px',
    color: '#323232',
    padding: '6px 0'
  }
}));

export default useStyles;
