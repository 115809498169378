import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {
    padding: 20,
    boxSizing: 'border-box'
  },
  breadcrumbs: {
    marginTop: 20,
    flexWrap: 'wrap'
  },
  anchor: {
    lineHeight: '24px'
  },
  regions: {
    marginTop: 20
  },
  inputs: {
    display: 'flex',
    marginTop: 20
  },
  submit: {
    marginTop: 20
  },
  checkBox: {
    marginTop: 20
  },
  select: {

  },
  input: {
    marginLeft: 20
  },
  tabs: {
    marginTop: 40
  },
  sort: {
    marginTop: 20
  }
}));

export default useStyles;
