import isNil from 'lodash/isNil';

function type({ type }) {
  return {
    type: (isNil(type) ? '' : type)
  };
}

function title({ title }) {
  return {
    title: (isNil(title) ? '' : title)
  };
}

function sub_title({ sub_title }) {
  return {
    sub_title: (isNil(sub_title) ? '' : sub_title)
  };
}

function seoTitle({ seo_title }) {
  return {
    seo_title: (isNil(seo_title) ? '' : seo_title)
  };
}

function seoDescription({ seo_description }) {
  return {
    seo_description: (isNil(seo_description) ? '' : seo_description)
  };
}

function metaTags({ meta_tags }) {
  return {
    meta_tags: (isNil(meta_tags) ? [] : meta_tags)
  };
}

function formValues(value) {
  if (value === null) return null;

  return {
    ...title(value),
    ...seoTitle(value),
    ...seoDescription(value),
    ...metaTags(value),
    ...type(value),
    ...sub_title(value)
  };
}

export default formValues;
