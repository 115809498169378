import React from 'react';
import { Text, Box } from '@mantine/core';

import useStyles from './InfoBlock.styles';


type Props = React.PropsWithChildren<{
  className?: string;
  label: string;
  description: string;
}>;

function InfoBlock({ className, label, description }: Props) {
  const { classes, cx } = useStyles();

  return (
    <Box className={cx(classes.root, className)}>
      <Text className={classes.label} size={16} color='#212529'>
        {label}
      </Text>

      <Text className={classes.description} size={14} color='#999999'>
        {description}
      </Text>
    </Box>
  );
}

export default InfoBlock;
