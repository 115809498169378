import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #e0e0e0',
    paddingBottom: 12
  },
  name: {

  },
  icon: {
    marginTop: 2
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export default useStyles;
