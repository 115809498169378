import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from './Header.theme';

const Wrapper = styled.div`
  ${(props) => theme(props)}
`;

function Header({ children, level, className }) {
  return (
    <Wrapper
      level={level}
      className={className}
    >
      {children}
    </Wrapper>
  );
}

Header.defaultProps = {
  level: 1
};

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  level: PropTypes.number
};

export default styled(Header)``;
