import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {
    width: 1254,
    background: '#FFF',
    display: 'flex',
    justifyContent: 'center'
  },
  blog: {
    marginBottom: 24
  },
  content: {
    marginTop: 50
  },
  languageSelect: {
    width: 'fit-content',
    position: 'absolute',
    top: 10,
    right: 50
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  gallery: {
    marginTop: 36
  },
  leftContainer: {
    minWidth: 810,
    maxWidth: 810
  },
  bestReview: {
    marginTop: 50
  },
  rightContainer: {
    minWidth: 400,
    maxWidth: 400
  }
}));

export default useStyles;
