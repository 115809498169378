import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tab from './Tab';

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  font-family: GTEesti;
  line-height: 36px;
`;

const ItemsContainer = styled.div`
  margin-top: 32px;

  ${Tab} + ${Tab} {
    margin-top: 16px;
  }
`;

const Wrapper = styled.div`
  margin-top: 68px;
`;

function WhatToSee({ className, data }) {
  const [opened, setOpened] = useState();

  const handleClick = (tab) => {
    setOpened(opened?.title === tab.title ? null : tab);
  };

  const tabs = data.list.map((tab, index) => (
    <Tab
      tab={tab}
      key={index}
      opened={tab.title === opened?.title}
      onClick={handleClick}
    />
  ));

  return (
    <Wrapper className={className}>
      <Title dangerouslySetInnerHTML={{ __html: data.title }} />

      <ItemsContainer>
        {tabs}
      </ItemsContainer>
    </Wrapper>
  );
}

WhatToSee.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

export default styled(WhatToSee)``;
