import React from 'react';
import { Box, Text, Anchor, Container, Checkbox, Badge, Select, Input, Button, Breadcrumbs } from '@mantine/core';
import { useForm, Controller } from 'react-hook-form';
import { createSearchParams, Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'store';

import Tabs from './Tabs';

import * as yup from 'yup';

import useStyles from './Form.styles';

import { IRegionDocument, ISearchArea, ISearchAreaBreadcrumbs, ISearchAreaRequestParams, ISortBasis } from 'types';

import { updateSearchArea } from 'store/searchAreaSlice/actions';

type Props = React.PropsWithChildren<{
  className?: string;
  searchArea: ISearchArea;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose: Function;
  breadcrumbs: ISearchAreaBreadcrumbs[];
  selectedRegions: IRegionDocument[];
}>;

type Options = {
  value: string;
  label: string;
};

type Sorts = {
  value: string;
  label: string;
  sort_by: string;
  direction: number;
};

const sort_basis: any = {
  direction: 1,
  sort_by: 'price'
};

const sorts: Sorts[] = [
  {
    direction: 1,
    sort_by: 'price',
    label: 'от дешевых  к дорогим',
    value: 'toMax'
  },
  {
    direction: -1,
    sort_by: 'price',
    label: 'от дорогих к дешевым',
    value: 'toMin'
  },
  {
    direction: 1,
    sort_by: 'rating',
    label: 'по популярности',
    value: 'byRating'
  }
];

const options: Options[] = [
  {
    value: 'coordinates',
    label: 'по координатам'
  },
  {
    value: 'area',
    label: 'по границам'
  }
];

const mainBreadcrumb: ISearchAreaBreadcrumbs = {
  name: 'Главная',
  id: 12001
};

const IS_SEARCHABLE = 'is_searchable';
const SEARCH_MODE = 'search_mode';
const SEARCH_RADIUS = 'search_radius';
const SORT = 'sort_basis';

const schema = yup.object().shape({
  [SEARCH_RADIUS]: yup.number()
});

function SearchArea({ className, searchArea, onClose, breadcrumbs }: Props) {
  const { is_searchable, search_radius, search_mode, search_additionals, slug } = searchArea;
  const dispatch = useAppDispatch();

  const sortOptions = sorts.find(
    (sort) => sort.direction === sort_basis.direction && sort.sort_by === sort_basis.sort_by
  )?.value;

  const {
    formState: { errors },
    ...methods
  } = useForm({
    defaultValues: {
      is_searchable,
      search_radius,
      search_mode,
      search_additionals,
      sort_basis: sortOptions,
      slug
    },
    resolver: yupResolver(schema)
  });
  const { classes } = useStyles();

  const handleSubmit = async (data: ISearchAreaRequestParams) => {
    const { direction, sort_by } = sorts.find((sort) => sort.value === data.sort_basis) as ISortBasis;

    await dispatch(
      updateSearchArea({
        id: searchArea.id,
        data: {
          ...data,
          sort_basis: {
            direction,
            sort_by
          }
        }
      })
    );

    onClose();
  };

  const buildSearchPath = (id?: number) => {
    if (!id) return '';

    return createSearchParams({
      osm_id: String(id)
    }).toString();
  };

  const items = [mainBreadcrumb, ...breadcrumbs].map((item, index) => {
    if (index !== breadcrumbs.length) {
      return (
        <Anchor
          component={Link}
          to={{ search: buildSearchPath(item.osm_id), pathname: '/search_area' }}
          key={index}
          className={classes.anchor}
        >
          {item.name}
        </Anchor>
      );
    }

    return (
      <Anchor key={index} variant='text' className={classes.anchor}>
        {item.name}
      </Anchor>
    );
  });

  return (
    <Container className={classes.root}>
      <Box>
        <Text size='xl' weight='bold'>
          {searchArea?.name.ru}
        </Text>
      </Box>

      <Breadcrumbs className={classes.breadcrumbs}>{items}</Breadcrumbs>

      <Box>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>


          <Box className={classes.inputs}>
            <Controller
              name={SEARCH_MODE}
              control={methods.control}
              render={({ field }) => {
                return <Select {...field} className={classes.select} label='Режим поиска' data={options} />;
              }}
            />

            <Controller
              name={SEARCH_RADIUS}
              control={methods.control}
              render={({ field }) => {
                return (
                  <Input.Wrapper
                    error={errors[SEARCH_RADIUS]?.message}
                    className={classes.input}
                    required
                    label='Радиус поиска в км'
                  >
                    <Input {...field} disabled={methods.watch(SEARCH_MODE) === 'area'} />
                  </Input.Wrapper>
                );
              }}
            />
          </Box>

          <Box className={classes.sort}>
            <Controller
              name={SORT}
              control={methods.control}
              render={({ field }) => {
                return <Select {...field} className={classes.select} label='Сортировка' data={sorts} />;
              }}
            />
          </Box>

          <Box className={classes.tabs}>
            <Tabs methods={methods} />
          </Box>

          <Button className={classes.submit} type='submit'>
            Сохранить
          </Button>
        </form>
      </Box>
    </Container>
  );
}

export default SearchArea;
