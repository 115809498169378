import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import _uniqueId from 'lodash/uniqueId';

const TextAreaElement = styled.textarea`
  flex-grow: 1;
  width: 100%;
  height: inherit;
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  resize: none;

  &::placeholder {
    color: #999999;
  }

  ${({ valueAlign }) => valueAlign === 'right' && css`
    text-align: right;
  `}

  ${({ valueAlign }) => valueAlign === 'left' && css`
    text-align: left;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid #DCD8D2;
  background: #FFFFFF;
  font-family: Helvetica Neue;
  color: #333333;
  box-sizing: border-box;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 4px;

  ${({ isFocused }) => isFocused && css`
    border: 1px solid #C8C4BC;
    z-index: 9;
  `}

  ${({ isError }) => isError && css`
    border: 1px solid #FF5656;
    color: #FF5656;
  `}

  ${({ isDisabled }) => isDisabled && css`
    pointer-events: none;
    opacity: 0.7;
  `}
`;

const IconWrapper = styled.div`
  padding: 0 10px 0 0;
`;

const TextArea = React.forwardRef(({
  id: initialId,
  value: initialValue,
  valueAlign,
  error,
  disabled,
  className,
  onFocus,
  onBlur,
  onChange,
  selectOnFocus,
  withIcon,
  ...rest
}, ref) => {
  const textareaRef = useRef();

  const id = useRef(initialId);

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (value !== initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);

    if (selectOnFocus) {
      textareaRef.current.select();
    }

    onFocus && onFocus(e);
  };

  const handleBlur = (e) => {
    setFocused(false);
    onBlur && onBlur(e);
  };

  const handleClick = (e) => {
    textareaRef.current.focus();
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange && onChange(newValue);
  };

  return (
    <Wrapper
      isError={error}
      isDisabled={disabled}
      isFocused={focused}
      onClick={handleClick}
      className={className}
    >
      {withIcon && <IconWrapper>{withIcon}</IconWrapper>}
      <TextAreaElement
        id={id.current}
        ref={textareaRef}
        disabled={disabled}
        value={value}
        valueAlign={valueAlign}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        rows='5'
        {...rest}
      />
    </Wrapper>
  );
});

TextArea.defaultProps = {
  id: _uniqueId('text-area-'),
  value: '',
  valueAlign: 'left',
  selectOnFocus: false,
  error: false,
  disabled: false
};

TextArea.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  valueAlign: PropTypes.oneOf(['left', 'right']),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  selectOnFocus: PropTypes.bool,
  withIcon: PropTypes.any
};

export default styled(TextArea)``;
