import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';

import useReviewers from '../../hooks/useReviewers';

import { Main, TopBar, Content } from 'components/ui/Layout';
import Top from './Top';
import Body from './Body';

const Wrapper = styled(Main)`
`;

function Edit({ className }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const contentRef = useRef();

  const [value, { fetchOne, update }] = useReviewers();

  useEffect(() => {
    fetchOne(id);
  }, []);

  if (value === null || !value.id) {
    return null;
  }

  const handleSubmit = async () => {
    if (!contentRef.current) {
      return;
    }

    const content = contentRef.current.editor.getContent();
    const { error } = await update(id, { ...value, content });

    if (!error) {
      navigate('/reviewers');
    }
  };

  const handleReset = () => {
    navigate('/reviewers');
  };

  return (
    <Wrapper className={className}>
      <TopBar>
        <Top
          reviewer={value}
          onSubmit={handleSubmit}
          onReset={handleReset}
        />
      </TopBar>
      <Content>
        <Body
          reviewer={value}
          contentRef={contentRef}
        />
      </Content>
    </Wrapper>
  );
}

Edit.defaultProps = {
};

Edit.propTypes = {
  className: PropTypes.string
};

export default styled(Edit)``;
