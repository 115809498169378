import Ui from './UI.js';
import './index.css';
import _isEmpty from 'lodash/isEmpty';

import getTranslate from 'Utils/getTranslate';

import { showError, clearError } from '../utils/errorHandlers';
import chatGPTApi from 'Apis/ChatGPTApi';

class DescriptionChatGPT {
  static get toolbox() {
    return {
      icon: '<svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1"><path d="M32.12,10H3.88A1.88,1.88,0,0,0,2,11.88V30.12A1.88,1.88,0,0,0,3.88,32H32.12A1.88,1.88,0,0,0,34,30.12V11.88A1.88,1.88,0,0,0,32.12,10ZM32,30H4V12H32Z" class="clr-i-outline clr-i-outline-path-1"></path><path d="M8.56,19.45a3,3,0,1,0-3-3A3,3,0,0,0,8.56,19.45Zm0-4.6A1.6,1.6,0,1,1,7,16.45,1.6,1.6,0,0,1,8.56,14.85Z" class="clr-i-outline clr-i-outline-path-2"></path><path d="M7.9,28l6-6,3.18,3.18L14.26,28h2l7.46-7.46L30,26.77v-2L24.2,19a.71.71,0,0,0-1,0l-5.16,5.16L14.37,20.5a.71.71,0,0,0-1,0L5.92,28Z" class="clr-i-outline clr-i-outline-path-3"></path><path d="M30.14,3h0a1,1,0,0,0-1-1h-22a1,1,0,0,0-1,1h0V4h24Z" class="clr-i-outline clr-i-outline-path-4"></path><path d="M32.12,7V7a1,1,0,0,0-1-1h-26a1,1,0,0,0-1,1h0V8h28Z" class="clr-i-outline clr-i-outline-path-5"></path><rect x="0" y="0" width="36" height="36" fill-opacity="0"/></svg>',
      title: 'Описание (ChatGPT)'
    };
  }

  constructor({ data, config }) {
    const { lang } = config;
    const { t } = getTranslate(lang);
    const location = window.location.pathname.split('/edit')[0].split('/');

    this.hotelId = location.at(-1);
    this.t = t;
    this.lang = lang;

    const DEFAULT_DATA = {
      prompt: null,
      about: t('Description.About'),
      about_en: t('Description.AboutEN')
    };

    this.data = _isEmpty(data) ? DEFAULT_DATA : data;

    this.about = this.data.about;
    this.about_en = this.data.about_en || t('Description.AboutEN');
    this.prompt = this.data.prompt;
    this._error = this.data.error || null;

    !this.prompt && this.fetchPrompt();

    this.ui = new Ui({
      prompt: this.prompt,
      about: this.about,
      about_en: this.about_en,
      t,
      onGenerate: this._onGenerate,
      onUpdate: this._onUpdate
    });
  }

  static get enableLineBreaks() {
    return true;
  }

  static get sanitize() {
    return true;
  }

  fetchPrompt = async () => {
    const { data } = await chatGPTApi.fetchPrompt(this.hotelId);
    this.prompt = data;
    this.ui.prompt = this.prompt;
    this.ui.render();
  }

  _onGenerate = async () => {
    this.ui.createPendingButton();
    const { prompt } = this.ui.getData();
    this.prompt = prompt;
    const { data } = await chatGPTApi.createPrompt({ hotelId: this.hotelId, prompt });
    this.ui.createFullfilledButton();

    this.about = data.data.choices[0].text.ru;
    this.about_en = data.data.choices[0].text.en;
    this.ui.about = this.about;
    this.ui.about_en = this.about_en;
    this.ui.render();
  }

  _onUpdate = async () => {
    this.ui.createPendingButton();
    const { about } = this.ui.getData();
    const { data } = await chatGPTApi.createTranslate({ text: about, lang: 'en' });
    this.ui.createFullfilledButton();

    this.about = about;
    this.about_en = data.data.text;
    this.ui.about = this.about;
    this.ui.about_en = this.about_en;
    this.ui.render();
  }

  save() {
    const errors = this._validate();
    const { about, prompt } = this.ui.getData();

    return { about, prompt, errors };
  }

    _validate = () => {
      const { ui } = this;
      const errors = [];
      const { about, about_en, prompt } = ui.getData();

      if (!about) {
        showError(errors, this.t('Description.Error.About'), ui.displayErrorAbout);
      } else {
        clearError(ui.displayErrorAbout);
      }

      if (!about_en) {
        showError(errors, this.t('Description.Error.About'), ui.displayErrorAbout);
      } else {
        clearError(ui.displayErrorAbout);
      }

      if (!prompt) {
        showError(errors, this.t('Description.Error.Prompt'), ui.displayErrorAbout);
      } else {
        clearError(ui.displayErrorAbout);
      }

      return errors;
    }

    render() {
      return this.ui.render();
    }
}

export default DescriptionChatGPT;
