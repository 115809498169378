import React, { useMemo } from 'react';
import { Box } from '@mantine/core';

import useStyles from './Amenities.styles';

import arrayToTree from 'array-to-tree';
import Group from './Group';

type Props = {
  className?: string,
  hotel: any
}

function Amenities({ className, hotel }: Props) {
  const { classes, cx } = useStyles();

  const amenities = useMemo(() => {
    return arrayToTree(hotel.amenities || [], {
      parentProperty: 'parent_tag',
      customID: 'tag'
    });
  }, [hotel]);

  const elements = amenities.map((group: any) => <Group group={group} key={group.id} />);

  return (
    <Box className={cx(className, classes.root)}>
      {elements}
    </Box>
  );
}

export default Amenities;
