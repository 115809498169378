import { PayloadAction } from '@reduxjs/toolkit';
import { IArticleRequest, IArticleResponse, SHOW_TYPE, STATUSES, ARTICLE_FILTER_TYPE } from 'types';
import _cloneDeep from 'lodash/cloneDeep';

const DEFAULTS = {
  state: STATUSES.IDLE,
  articleState: STATUSES.IDLE,
  articles: [],
  type: SHOW_TYPE.LIST,
  articleType: ARTICLE_FILTER_TYPE.BLOG
};

export interface IAdapter {
  state: STATUSES;
  articles: IArticleResponse[],
  article?: IArticleResponse,
  type: SHOW_TYPE,
  articleState: STATUSES,
  articleType: ARTICLE_FILTER_TYPE
}

class Adapter {
  public getInitialState(props?: IAdapter): IAdapter {
    return { ...DEFAULTS, ...props };
  }

  public articlesFetchPending(
    state: IAdapter,
    action: PayloadAction<any>
  ): IAdapter {
    const currentState = { state: STATUSES.PENDING };
    return { ...state, ...currentState };
  }

  public articleSetType(
    state: IAdapter,
    action: PayloadAction<ARTICLE_FILTER_TYPE>
  ): IAdapter {
    const acritlceType: ARTICLE_FILTER_TYPE = action.payload;

    const currentState = {
      acritlceType
    };

    return {
      ...state,
      ...currentState
    };
  }

  public articlesFetchFulfilled(
    state: IAdapter,
    action: PayloadAction<IArticleResponse[]>
  ): IAdapter {
    const articles: IArticleResponse[] = action.payload;

    const currentState = {
      state: STATUSES.FULFILLED,
      articles
    };

    return { ...state, ...currentState };
  }

  public articlesOneFetchPending(
    state: IAdapter,
    action: PayloadAction<any>
  ): IAdapter {
    const currentState = { articleState: STATUSES.PENDING };
    return { ...state, ...currentState };
  }

  public articlesOneFetchFulfilled(
    state: IAdapter,
    action: PayloadAction<IArticleResponse>
  ): IAdapter {
    const article = action.payload;

    const currentState = {
      articleState: STATUSES.FULFILLED,
      article
    };

    return { ...state, ...currentState };
  }

  public articlesUpdateFulfilled(
    state: IAdapter,
    action: PayloadAction<IArticleResponse>
  ): IAdapter {
    const article = action.payload;
    const ind = state.articles.findIndex((art) => art.id === article.id);
    const articles = _cloneDeep(state.articles);
    articles[ind] = article;

    const currentState = {
      state: STATUSES.FULFILLED,
      articles
    };

    return {
      ...state,
      ...currentState
    };
  }

  public articleDeleteFullfilled(
    state: IAdapter,
    action: PayloadAction<IArticleResponse>
  ): IAdapter {
    const data: IArticleResponse = action.payload;

    const currentState = {
      state: STATUSES.FULFILLED,
      articles: state.articles.filter((art) => art.id !== data.id)
    };

    return {
      ...state,
      ...currentState
    };
  }

  public articlesClear(
    state: IAdapter,
    action: PayloadAction<any>
  ): IAdapter {
    const currentState = {
      article: undefined
    };

    return {
      ...state,
      ...currentState
    };
  }

  public articleSave(
    state: IAdapter,
    action: PayloadAction<IArticleResponse>
  ): IAdapter {
    const article: IArticleResponse = action.payload;

    const currentState = {
      article
    };

    return {
      ...state,
      ...currentState
    };
  }

  public articleCreate(
    state: IAdapter,
    action: PayloadAction<IArticleResponse>
  ): IAdapter {
    const article: IArticleResponse = action.payload;

    const currentState = {
      article,
      articles: [
        article,
        ...state.articles
      ]
    };

    return {
      ...state,
      ...currentState
    };
  }

  public articleChangeType(
    state: IAdapter,
    action: PayloadAction<any>
  ): IAdapter {
    const data: SHOW_TYPE = action.payload;

    const currentState = {
      type: data
    };

    return {
      ...state,
      ...currentState
    };
  }
}

const adapter = new Adapter();

const initialState = adapter.getInitialState();

export { initialState };

export default adapter;
