
import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {

  },
  contentContainer: {
    marginTop: 28
  },
  header: {
    width: 810,
    height: 50,
    gap: 20,
    background: '#FAF4F0',
    borderRadius: 4,
    padding: '7px 8px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  }
}));

export default useStyles;
