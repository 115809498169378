import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  button_green: {
    height: 41,
    fontSize: 14,
    borderRadius: 4,
    color: '#fff',
    transition: 'all .2s ease-in-out',
    background: '#52AB18',

    '&:hover': {
      background: '#428616'
    }
  },
  button_gray: {
    background: '#D2D1CC',
    height: 41,
    fontSize: 14,
    borderRadius: 4,
    color: '#333333',
    transition: 'all .2s ease-in-out',
    marginRight: '111px',

    '&:hover': {
      background: '#C4C2BB'
    }
  },
  field: {
    marginTop: '30px'
  },
  buttons_wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginTop: '24px'
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '24px'
  },
  row: {
    gap: '16px'
  },
  hidden: {
    display: 'none'
  },
  datepicker: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #DCD8D2',
    background: '#FFFFFF',
    color: '#333333',
    boxSizing: 'border-box',
    fontSize: '14px',
    padding: '0 12px',
    borderRadius: '4px',
    flex: '1',
    width: '100%',
    height: '32px',
    outline: 'none'
  }
}));

export default useStyles;
