import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LanguageSelect from 'components/ui/LanguageSelect';
import Modal from 'components/ui/Modal';

import Group from './Group';

const languages = [
  'ru',
  'en'
];

const Content = styled.div`
  min-width: 788px;
  max-width: 788px;
  margin: 0 auto;
`;

const CloseButton = styled.div`
  position: fixed;
  top: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
  background: #c0c0c0;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    transform: rotate(90deg);
  }

  &:before, &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 3px;
    top: 50%;
    left: 50%;
    background: white;
    transition: 0.2s;
  }
  
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  min-width: 98vw;
  max-width: 98vw;
  min-height: calc(100vh - 30px);
  max-height: 100vh;
  overflow: auto;
  position: relative;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: GTEesti;

  p + p {
    margin-top: 16px;
  }

  div + p {
    margin-top: 16px;
  }

  ${LanguageSelect} {
    position: absolute;
    top: 12px;
    right: 50px;
  }
`;

const hideEnContent = process.env.REACT_APP_HIDE_EN_CONTENT || true;

function Add({ className, onClose, data: { content } }) {
  const [currentLanguage, setCurrentLanguage] = useState(languages[0]);
  const groups = content[currentLanguage]?.blocks?.map((block) => <Group key={block.id} group={block} />);

  const onChange = (lang) => {
    if (currentLanguage !== lang) {
      setCurrentLanguage(lang);
    }
  };

  return (
    <Modal>
      <Wrapper className={className}>
        {!hideEnContent && (
          <LanguageSelect
            languages={languages}
            onChange={onChange}
            language={currentLanguage}
          />
        )}

        <Content>
          {groups}

          <CloseButton onClick={onClose} />
        </Content>
      </Wrapper>
    </Modal>
  );
}

Add.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  data: PropTypes.object
};

export default styled(Add)``;
