
import { createStyles } from '@mantine/styles';

const useStyles = createStyles(() => ({
  root: {
    letterSpacing: 0,
    alignItems: 'center'
  },
  container: {
    fontSize: 16,
    lineHeight: '18px',
    padding: '7px 0',
    paddingLeft: 28,
    position: 'relative',

    '&:before': {
      position: 'absolute',
      content: '""',
      backgroundImage: 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgNS44Njk0OUw1LjcyMDI1IDExLjUwNTFMMTMuMzQ3OCAyIiBzdHJva2U9IiMxQkM0N0QiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      left: -2,
      width: 20,
      height: 20,
      top: 8,
      borderRadius: '50%'
    }
  }

}));

export default useStyles;
