import React, { useState } from 'react';
import { Box, Text, ActionIcon, Button } from '@mantine/core';
import { ChevronLeft, Menu2 } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';

import useStyles from './SideLeft.styles';
import { useAppDispatch, useAppSelector } from 'store';
import { loginSelector } from 'store/authSlice/selectors';
import { logoutAction } from 'store/authSlice/actions';
import { removeFromLocalStorage } from 'utils/storage';

type Props = React.PropsWithChildren<{
  className?: string | undefined;
}>;

function SideLeft({ children, className }: Props) {
  const [opened, setOpened] = useState<boolean>(true);
  const { classes, cx } = useStyles();
  const { loading } = useAppSelector(loginSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = (flag: boolean) => {
    setOpened(flag);
  };

  const handleLogout = async () => {
    await dispatch(logoutAction());
    removeFromLocalStorage('user');

    navigate('/login');
  };

  return (
    <Box className={cx(className, classes.root)}>
      {opened && (
        <Box className={classes.fullContainer}>
          <Box className={classes.header}>
            <Text color='#ebecec' transform='uppercase' weight='bold' size='sm'>
              Sanatory.ru
            </Text>

            <ActionIcon size={36} onClick={() => handleClick(false)} variant='transparent'>
              <ChevronLeft color='#fff' size={36} />
            </ActionIcon>
          </Box>

          <Box className={classes.content}>{children}</Box>
          <Button onClick={handleLogout} loading={loading} size='lg' color='dark' className={classes.btn}>
            Выйти
          </Button>
        </Box>
      )}

      {!opened && (
        <Box className={classes.shortContainer} onClick={() => handleClick(true)}>
          <Menu2 color='#fff' />
        </Box>
      )}
    </Box>
  );
}

export default SideLeft;
