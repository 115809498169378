import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {
    '.echarts-for-react': {
      height: '205px !important'
    }
  },
  breadcrumbs: {
    marginBottom: 20
  },
  sideLeft: {
    flexGrow: 0,
    flexShrink: 0
  },
  main: {
    flexGrow: 1
  },
  topbar: {
    flexGrow: 0,
    flexShrink: 0
  },

  loaderMore: {
    height: 50,
    textAlign: 'center'
  },

  date: {
    width: 250
  },

  source: {
    width: 200
  },

  type: {
    width: 200
  },

  topInputs: {
    display: 'flex',
    gap: 24,
    marginLeft: 24
  },

  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
 
  loader: {
    height: '100%'
  },
  
  hotel: {
    boxSizing: 'border-box',
    position: 'relative',
    paddingBottom: 20,
    marginBottom: 25
  },

  scrollWrapper: {
    '& > div': {
      overflow: 'auto'
    }
  }
}));

export default useStyles;
