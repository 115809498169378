import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {
    // width: 300
  },
  tr: {
    background: 'rgb(234,233,228)'
  },
  breadcrumbs: {
    marginBottom: 20
  },
  th: {},
  td: {
    cursor: 'pointer',
    maxWidth: 160
  },
  table: {
    background: '#FFF',
    border: '1px solid #c0c0c0'
  },
  sideLeft: {
    flexGrow: 0,
    flexShrink: 0
  },
  main: {
    flexGrow: 1
  },
  topbar: {
    flexGrow: 0,
    flexShrink: 0
  },
  content: {
    flexGrow: 1
  },
  error: {
    border: '1px solid #FF5656'
  }
}));

export default useStyles;
