
import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme, _, getRef) => ({
  root: {

  },
  element: {
    fontSize: 18,
    lineHeight: '20px',
    color: '#323232',
    padding: '7px 0'
  },
  title: {
    fontSize: 20,
    lineHeight: '22px',
    fontWeight: 600,
    marginTop: 13,
    marginBottom: 10
  }
}));

export default useStyles;
