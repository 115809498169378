import isNil from 'lodash/isNil';

function type({ type }) {
  return {
    type: (isNil(type) ? '' : type)
  };
}

function place({ place }) {
  return {
    place
  };
}

function title({ title }) {
  if (isNil(title)) {
    return {
      title_ru: ''
    };
  }

  return {
    title_ru: title.ru ? title.ru : '',
    title_en: title.en ? title.en : ''
  };
}

function seoTitle({ seo_title }) {
  if (isNil(seo_title)) {
    return {
      seo_title_ru: ''
    };
  }

  return {
    seo_title_ru: seo_title.ru ? seo_title.ru : '',
    seo_title_en: seo_title.en ? seo_title.en : ''
  };
}

function seoDescription({ seo_description }) {
  if (isNil(seo_description)) {
    return {
      seo_description_ru: ''
    };
  }

  return {
    seo_description_ru: seo_description.ru ? seo_description.ru : '',
    seo_description_en: seo_description.en ? seo_description.en : ''
  };
}

function metaTags({ meta_tags }) {
  if (isNil(meta_tags)) {
    return {
      meta_tags: []
    };
  }

  return {
    meta_tags
  };
}

function subTitle({ sub_title }) {
  if (isNil(sub_title)) {
    return {
      sub_title_ru: ''
    };
  }

  return {
    sub_title_ru: sub_title.ru ? sub_title.ru : '',
    sub_title_en: sub_title.en ? sub_title.en : ''
  };
}

function coordinates({ coordinates }) {
  if (coordinates?.length === 2) {
    return {
      coord_x_field: coordinates[0],
      coord_y_field: coordinates[1]
    };
  }

  return {};
}

function formValues(value) {
  if (value === null) return null;

  return {
    ...place(value),
    ...coordinates(value),
    ...subTitle(value),
    ...title(value),
    ...seoTitle(value),
    ...seoDescription(value),
    ...metaTags(value),
    ...type(value)
  };
}

export default formValues;
