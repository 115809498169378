import * as yup from 'yup';

export const PROVIDER_ID = 'provider_id';
export const PROVIDER = 'provider';
export const LOCATION = 'location';
export const PLACE_ID = 'place_id';
export const COORDINATES = 'coordinates';
export const LAT = 'lat';
export const LNG = 'lng';
export const TIMEZONE = 'timezone';

export const addNewOstrovokSchema = yup.object().shape({
  [PROVIDER_ID]: yup.string().required('Обязательное поле'),
  [LOCATION]: yup.string().required('Обязательное поле'),
  [PLACE_ID]: yup.string().required('Обязательное поле'),
  [COORDINATES]: yup.object().shape({
    [LAT]: yup.string().matches(
      /^-?([1-8]?[0-9](\.\d+)?|90(\.0+)?)$/,
      'Некорректный формат широты. Пример: 45.9999999'
    ).required('Обязательное поле'),
    [LNG]: yup.string().matches(
      /^-?((1[0-7][0-9]|[1-9]?[0-9])(\.\d+)?|180(\.0+)?)$/,
      'Некорректный формат долготы. Пример: 45.9999999'
    ).required('Обязательное поле')
  }),
  [TIMEZONE]: yup.string().required('Обязательное поле')

});
