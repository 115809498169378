import React, { forwardRef, useEffect, useRef, useState } from 'react';
import _uniqBy from 'lodash/uniqBy';
import { Box, Select, Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import useStyles from './RegionSelect.styles';

import useRegions from './hook/useRegions';
import useHotels from './hook/useHotels';

type Props = React.PropsWithChildren<{
  className?: string;
  onSelect?: (region: any) => void;
  clearAfterSelect?: boolean;
  type?: string;
  placeholder?: string;
  value?: any;
  errors?: any;
  defaultName?: string;
}>;

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  description: string;
  errors?: any;
}

function SearchArea({ className, onSelect, clearAfterSelect, placeholder, value, type = 'regions', errors, defaultName, ...selectProps }: Props) {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const selectRef = useRef<any>();
  const [shouldClear, setShouldClear] = useState(value);

  const [{ regions }, { fetch, clear }] = type === 'regions' ? useRegions() : useHotels();
  const values = value ? [value] : [];

  const names = _uniqBy([...values, ...regions], 'place_id').map((h) => ({
    label: h.label_ru,
    value: h.place_id,
    description: h.description_ru
  }));

  useEffect(() => {
    defaultName && fetch(defaultName);
  }, []);

  const defaultValue = names.find((item) => item.value === value) ? value : null;

  const handleSelect = (region: string) => {
    const regionObj = regions?.find((r) => r.place_id === region);

    if (regionObj) {
      onSelect && onSelect(regionObj);
    }

    clearAfterSelect && setTimeout(handleClear, 0);
  };

  const handleClear = () => {
    setShouldClear(!shouldClear);
  };

  const handleSearchChange = (q: string) => {
    if (q.length > 2) {
      fetch(q);
    } else {
      clear();
    }
  };

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size='sm'>{label}</Text>
          <Text size='xs' color='dimmed'>
            {description}
          </Text>
        </div>
      </Group>
    </div>
  ));

  return (
    <Box className={cx(classes.root, className)}>
      <Select
        ref={selectRef}
        itemComponent={SelectItem}
        onSearchChange={handleSearchChange}
        filter={() => true}
        key={shouldClear}
        data={names}
        allowDeselect
        filterDataOnExactSearchMatch
        clearable
        value={defaultValue}
        nothingFound={names.length > 0 ? t('Common.Form.Empty') : ''}
        placeholder={placeholder || t('SearchArea.RegionSelect.Placeholder')}
        searchable
        onChange={handleSelect}
        {...selectProps}
      />
    </Box>
  );
}

export default SearchArea;
