import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import styled from 'styled-components';

import { RegionIcon, RoundedArrowIcon } from 'components/ui/Icons';
import { useTranslation } from 'react-i18next';

import getSearchDates from 'Utils/getSearchDates';

const site = process.env.REACT_APP_SITE;

const DEFAULT_PARAMS = {
  ...getSearchDates(),
  travellers: [
    { age_group: 'adult', age: 0 }
  ]
};

const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

const RegionIconContainer = styled.div`
  font-size: 60px;

  svg {
    display: block;
  }
`;

const ArrowIconContainer = styled.div`
  font-size: 46px;

  svg {
    display: block;
  }
`;

const RightContent = styled.div`

`;

const TextContainer = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: #323232;
  max-width: 400px;
  margin-left: 28px;
`;

const CommonText = styled.div`
  display: inline;
  font-family: 'GTEesti';
`;

const FocusText = styled.div`
  display: inline;
  font-family: 'GTEesti';
  color: #3582DB;
  margin-left: 8px;
`;

const CountText = styled.div`
  display: inline;
  font-family: 'GTEesti';
  margin-left: 8px;
`;

const Wrapper = styled.div`
  width: 100%;
  background: linear-gradient(89.47deg, #D3D9FC 29.49%, #F5E3FF 99.5%);
  border-radius: 6px;
  height: 144px;
  padding: 0 44px 0 57px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 48px;
`;

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

function Region({ className, data }) {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open(`${site}/search/list/?${qs.stringify({
      place: {
        ...data.region,
        label: data.region.name,
        id: data.region.place_id
      },
      check_in: DEFAULT_PARAMS.check_in,
      check_out: DEFAULT_PARAMS.check_out
    })}`);
  };

  return (
    <Wrapper className={className} onClick={handleClick}>
      <LeftContent>
        <RegionIconContainer>
          <RegionIcon />
        </RegionIconContainer>

        <TextContainer>
          <CommonText>
            {t('RegionCard.Check')}
          </CommonText>

          <FocusText>
            {data.mainText}
          </FocusText>

          <CountText>
            ({getRandomInt(50, 100)})
          </CountText>
        </TextContainer>
      </LeftContent>

      <RightContent>
        <ArrowIconContainer>
          <RoundedArrowIcon />
        </ArrowIconContainer>
      </RightContent>
    </Wrapper>
  );
}

Region.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

export default styled(Region)``;
