import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
`;

const FileInput = React.forwardRef(({
  className,
  disabled,
  name,
  onBlur,
  onChange,
  placeholder,
  value: initialValue
}, ref) => {
  const handleChange = (ev) => {
    onChange({ file: ev.target.files[0] });
  };

  return (
    <Wrapper
      className={className}
    >
      <input
        type='file'
        onChange={handleChange}
      />
    </Wrapper>
  );
});

FileInput.defaultProps = {
  className: PropTypes.string,
  disabled: false,
  placeholder: 'Выберите файл',
  value: null
};

FileInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any
};

export default styled(FileInput)``;
