import connection from 'connection/instance';

class ChatGPTApi {
  async createPrompt(data) {
    const { hotelId, prompt } = data;

    try {
      const { data } = await connection.post(`/api/hotels/${hotelId}/hotel_gpt_description`, { data: { prompt } });
      return { data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async createTranslate(data) {
    const { text, lang } = data;

    try {
      const { data } = await connection.post('/api/hotels/translate', { data: { text, lang } });
      return { data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async fetchPrompt(hotelId) {
    try {
      const {
        data: { data }
      } = await connection.get(`/api/hotels/${hotelId}/hotel_gpt_prompt`);

      return { data: data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }
}

export default new ChatGPTApi();
