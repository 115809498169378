import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {},
  label: {

  },
  description: {
    marginTop: 4
  }
}));

export default useStyles;
