
import { createStyles } from '@mantine/styles';

const useStyles = createStyles(() => ({
  root: {
    padding: 20,
    paddingTop: 0,
    boxSizing: 'border-box'
  },

  programContent: {
    marginTop: 8,
    fontSize: 18,
    lineHeight: '24px',

    p: {
      fontSize: 18,
      lineHeight: '24px',
      color: '#323232',
      margin: '16px 0'
    },

    div: {
      fontSize: 18,
      lineHeight: '24px',
      color: '#323232',
      margin: '16px 0'
    },

    h5: {
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 600,
      marginBottom: 0,
      color: '#323232',
      marginTop: 16
    },

    'h5 ~ p': {
      fontSize: 16,
      lineHeight: '20px'
    }
  }
}));

export default useStyles;
