import React from 'react';
import { Box, ActionIcon, Input } from '@mantine/core';
import { X } from 'tabler-icons-react';

import useStyles from './SelectedRegion.styled';

type Object = {
  area_id: string;
  type: string;
  name: string;
  radius?: number;
};

type Props = React.PropsWithChildren<{
  className?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  region: Object;
  handleDelete: (id: string) => void;
  searchWithHotels?: boolean | undefined;
  onChangeRadius: (radius: number, index: number) => void;
  index: number;
  disabled?: boolean;
}>;

function SearchArea({ className, region, handleDelete, searchWithHotels, onChangeRadius, index, disabled }: Props) {
  const { classes, cx } = useStyles();

  const handleClick = () => {
    handleDelete(region.area_id);
  };

  const handleChange = (e: any) => {
    onChangeRadius(Number(e.target.value), index);
  };

  return (
    <Box className={cx(className, classes.root)}>
      <Box>{region.name}</Box>
      <Box className={classes.inputWrapper}>
        {!searchWithHotels && <Input type='number' onChange={handleChange} value={region.radius} disabled={disabled} />}
        <ActionIcon size={18} onClick={handleClick} variant='transparent' className={classes.icon}>
          <X color='black' size={18} />
        </ActionIcon>
      </Box>
    </Box>
  );
}

export default SearchArea;
