import pasteEvent from '../utils/pasteEvent';

export default class Ui {
  constructor({ list, onAdd, onRemove, t }) {
    this.units = [
      {
        value: 'km',
        text: t('Distances.Unit.Km')
      },
      {
        value: 'm',
        text: t('Distances.Unit.M')
      }
    ];

    this.transport = [
      {
        value: 'train',
        text: t('Distances.Transport.Train')
      },
      {
        value: 'plane',
        text: t('Distances.Transport.Plane')
      },
      {
        value: 'transfer',
        text: t('Distances.Transport.Transfer')
      }
    ];

    this.t = t;
    this.list = list;
    this.onAdd = onAdd;
    this.onRemove = onRemove;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      listElement: make('div', [this.CSS.listElement]),
      title: make('h2', this.CSS.title, {
        innerHTML: t('Distances.Title')
      }),
      listContainer: make('div', this.CSS.listContainer),
      button: this.createAddButton()
    };

    this.nodes.title.addEventListener('paste', pasteEvent);
  }

  createOptions(options, value) {
    return options.map((unit) => make('option', this.CSS.option, {
      ...unit,
      selected: unit.value === value
    }));
  }

  createAddButton() {
    const button = make('div', [this.CSS.button]);

    button.innerHTML = this.t('Common.Add');

    button.addEventListener('click', () => {
      this.onAdd();
    });

    return button;
  }

  get CSS() {
    return {
      wrapper: 'distances',
      option: 'distances_option',
      listElement: 'distances_element',
      title: 'distances_title',
      listContainer: 'distances_list',
      remove: 'distances_settings_remove',
      button: 'distances_add',
      removeIcon: 'distances_element_remove',
      listText: 'distances_element_text',
      listTitle: 'distances_element_title',
      valueInput: 'distances_value_input',
      selectUnit: 'distances_unit_unit',
      icon: 'distances_icon',
      valueInputContainer: 'distances_value_input_container',
      selectUnitContainer: 'distances_unit_unit_container',
      iconContainer: 'distances_icon_container',
      inputName: 'distances_name_input',
      label: 'distances_label',
      inputDescription: 'distances_description_input',
      inputWrapper: 'distances_input_wrapper'
    };
  }

  onKeyUp(e) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    console.log(e.code);
  }

  getData() {
    const list = this.nodes.wrapper.getElementsByClassName(this.CSS.listElement);

    return {
      list: Array.from(list).map((el) => {
        const name = el.querySelector(`.${this.CSS.inputName}`);
        const description = el.querySelector(`.${this.CSS.inputDescription}`);
        const unit = el.querySelector(`.${this.CSS.selectUnit}`);
        const icon = el.querySelector(`.${this.CSS.icon}`);
        const value = el.querySelector(`.${this.CSS.valueInput}`);

        return {
          name: name.innerHTML,
          description: description.innerHTML,
          unit: unit.value,
          icon: icon.value,
          value: value.value
        };
      })
    };
  }

  renderList() {
    return this.list.map((element, index) => {
      const listElement = make('div', this.CSS.listElement);
      const listElementName = make('div', this.CSS.inputName, {
        contentEditable: true,
        innerHTML: element.name
      });

      const listElementDesc = make('div', this.CSS.inputDescription, {
        contentEditable: true,
        innerHTML: element.description
      });

      const inputsWrapper = make('div', this.CSS.inputWrapper);

      listElementName.addEventListener('paste', pasteEvent);
      listElementDesc.addEventListener('paste', pasteEvent);

      const removeIcon = make('div', this.CSS.removeIcon);

      removeIcon.addEventListener('click', () => {
        this.onRemove(index);
      });

      inputsWrapper.appendChild(this.createInput(element.value));
      inputsWrapper.appendChild(this.createUnitSelect(element.unit));
      inputsWrapper.appendChild(this.createIconSelect(element.icon));

      listElement.appendChild(inputsWrapper);
      listElement.appendChild(listElementName);
      listElement.appendChild(listElementDesc);
      listElement.appendChild(removeIcon);

      return listElement;
    });
  }

  createUnitSelect(unit) {
    const listElementValueContainer = make('div', this.CSS.selectUnitContainer);
    const label = make('div', this.CSS.label, {
      innerHTML: this.t('Distances.Label.Unit')
    });

    const listElementUnit = make('select', this.CSS.selectUnit, {
      placeholder: this.t('Distances.Placeholder.Unit'),
      value: unit
    });

    this.createOptions(this.units, unit).forEach((element) => {
      listElementUnit.appendChild(element);
    });

    listElementValueContainer.appendChild(label);
    listElementValueContainer.appendChild(listElementUnit);

    return listElementValueContainer;
  }

  createIconSelect(icon) {
    const listElementValueContainer = make('div', this.CSS.selectUnitContainer);
    const label = make('div', this.CSS.label, {
      innerHTML: this.t('Distances.Label.Transport')
    });

    const listElementIcon = make('select', this.CSS.icon, {
      placeholder: this.t('Distances.Placeholder.Transport'),
      value: icon
    });

    this.createOptions(this.transport, icon).forEach((element) => {
      listElementIcon.appendChild(element);
    });

    listElementValueContainer.appendChild(label);
    listElementValueContainer.appendChild(listElementIcon);

    return listElementValueContainer;
  }

  createInput(value) {
    const listElementValueContainer = make('div', this.CSS.valueInputContainer);
    const label = make('div', this.CSS.label, {
      innerHTML: this.t('Distances.Label.Value')
    });

    const listElementValue = make('input', this.CSS.valueInput, {
      placeholder: this.t('Distances.Placeholder.Value'),
      type: 'number',
      value
    });

    listElementValueContainer.appendChild(label);
    listElementValueContainer.appendChild(listElementValue);

    return listElementValueContainer;
  }

  render() {
    const list = this.renderList();

    this.clear(this.nodes.listContainer);

    list.forEach((element) => {
      this.nodes.listContainer.appendChild(element);
    });

    this.nodes.wrapper.appendChild(this.nodes.title);
    this.nodes.wrapper.appendChild(this.nodes.listContainer);
    this.nodes.wrapper.appendChild(this.nodes.button);

    return this.nodes.wrapper;
  }

  clear(nodeElement) {
    nodeElement.innerHTML = '';
  }

  displayErrorTitle = (flag) => {
    flag ? this.nodes.title.classList.add('error') : this.nodes.title.classList.remove('error');

    return this.nodes.title;
  }

  displayErrorItemTitle = (flag, index) => {
    return this.displayItemElementError(flag, index, this.CSS.inputName);
  }

  displayErrorItemDescription = (flag, index) => {
    return this.displayItemElementError(flag, index, this.CSS.inputDescription);
  }

  displayItemElementError = (flag, index, cssClass) => {
    const item = this.getItem(index);
    const element = item.querySelector(`.${cssClass}`);

    flag ? element.classList.add('error') : element.classList.remove('error');

    return element;
  }

  getItem = (index) => {
    return this.nodes.wrapper.getElementsByClassName(this.CSS.listElement)[index];
  }

  createFileButton() {
    const button = make('div', [this.CSS.button]);

    button.innerHTML = this.config.buttonContent || `${this.api.i18n.t('Select an Image')}`;

    button.addEventListener('click', () => {
      this.onSelectFile();
    });

    return button;
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
