
import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {
    borderTopRightRadius: 9,
    borderTopLeftRadius: 9,
    overflow: 'hidden'
  },
  mapInfoContainer: {
    padding: '25px 30px 28px',
    borderBottomRightRadius: 9,
    borderBottomLeftRadius: 9,
    border: '2px solid #F1E6DF',
    borderTop: 0
  },
  contentContainer: {
    marginTop: 28
  },
  header: {
    width: 810,
    height: 50,
    gap: 23,
    background: '#FAF4F0',
    borderRadius: 4,
    padding: '7px 8px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  }
}));

export default useStyles;
