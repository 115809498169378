import React, { useState } from 'react';

import Dropdown from './Dropdown';
import CommonPart from '../CommonPart';

import useStyles from './Procedures.styles';
import { Box } from '@mantine/core';
import { IObjectDataFull } from 'types';

type Props = {
  className?: string,
  object: IObjectDataFull,
  currentLanguage: string
}

function Procedures({ className, object, currentLanguage }: Props) {
  const [openedProgramId, setOpenedProgramId] = useState<string | null>();

  const { classes, cx } = useStyles();

  const handleOpen = (id: string) => () => {
    if (openedProgramId === id) {
      setOpenedProgramId(null);
      return;
    }

    setOpenedProgramId(id);
  };

  const manipulation_programs = object.body_translations[currentLanguage]?.blocks.find((b) => b.type === 'procedures').data.list;

  const treatmentComponents = manipulation_programs.map((program: any) => (
    <Dropdown
      program={program}
      key={program.id}
      className={classes.dropdown}
      onClick={handleOpen(program.title)}
      opened={program.title === openedProgramId}
    />
  ));

  return (
    <Box className={cx(className, classes.root)}>
      <CommonPart>
        {treatmentComponents}
      </CommonPart>
    </Box>
  );
}

export default Procedures;
