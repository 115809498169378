import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Plane, Bus, Train, Car } from 'tabler-icons-react';
import { distanceTextFormation } from 'utils/distance';
import useStyles from './Distance.styles';
import { Box } from '@mantine/core';

type Props = {
  className?: string,
  distance: any
}

type Distances = {
  [key: string]: DistanceTemp
}

type DistanceTemp = {
  icon: any,
  text: string
}

function Distance({ className, distance }: Props) {
  const { t } = useTranslation();

  const distances: Distances = {
    plane: {
      icon: <Plane size={20} />,
      text: t('Distances.FromAirport')
    },
    plain: {
      icon: <Plane size={20} />,
      text: t('Distances.FromAirport')
    },
    bus: {
      icon: <Bus size={20} />,
      text: t('Distances.FromBusStop')
    },
    train: {
      icon: <Train size={20} />,
      text: t('Distances.FromRailwayStation')
    },
    transfer: {
      icon: <Car size={20} />,
      text: t('Distances.FromBusStop')
    }
  };

  const { classes, cx } = useStyles();

  const [distanceText] = useMemo(() => {
    const { text, value } = distanceTextFormation(distance.name);
    return [text, value || distance.value];
  }, [distance]);

  const index: string = distance.icon;

  const distanceElement: DistanceTemp = distances[index] || distance[0];

  return (
    <Box className={cx(className, classes.root)}>
      <Box className={classes.iconContainer}>
        <Box className={classes.icon}>
          {distanceElement.icon}
        </Box>

        <Box className={classes.description}>
          {distanceText}
        </Box>
      </Box>

      <Box>
        {distance.value} {distance.unit}
      </Box>
    </Box>
  );
}

export default Distance;
