import { DateTime } from 'luxon';

const format = 'yyyy-MM-dd';

function getSearchDate() {
  const today = DateTime.now();
  const check_in = today.plus({ days: 7 }).toFormat(format);
  const check_out = today.plus({ days: 14 }).toFormat(format);

  return {
    check_in,
    check_out
  };
}

export default getSearchDate;
