import React, { useEffect } from 'react';
import _ from 'lodash';
import { Box, Button, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Content } from 'components/ui/Layout';
import { FormField } from '../FormField';
import {
  addNewObjectSchema,
  COMMISSION,
  CONTRACTS,
  COORDINATES,
  CURRENCY, DATE,
  EMAIL,
  INN, INSURANCE_ID,
  KPP,
  LAT,
  LEGAL_ENTITY,
  LNG,
  LOCATION,
  NAME, NUMBER,
  PHONE,
  PLACE_ID, SOURCES, TIMEZONE
} from './form';
import { Row } from 'components/ui/Grid';
import Field from 'components/ui/Field/Field';
import CurrencySelect from 'components/forms/CurrencySelect';
import RegionSelect from 'components/ui/RegionSelect/RegionSelect';
import TimezonesSelect from 'components/forms/TimezonesSelect';
import DatePicker from 'components/ui/DatePicker/DatePicker';
import useStyles from '../AddObject.styles';
import { useAppDispatch } from 'store';
import createObjectAction from 'store/objectsSlice/actions/createObjectAction';
import { toastify } from 'utils/toatify';
import useSources from './useSources';

function SanatoryForm({ setOpenModal, setModalData, provider }) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors }, ...methods
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(addNewObjectSchema),
    defaultValues: {
      timezone: 'Europe/Moscow',
      currency: 'RUB'
    }
  });

  const [sources, { fetch }] = useSources();

  const onSubmit = async (data) => {
    const formatedData = {
      ...data,
      provider: provider,
      timezone: data.timezone,
      sources: _.map(data.sources, (source, index) => ({
        ...source,
        insurance_id: sources?.[index].id,
        contracts: _.filter(source?.contracts, (contract) => contract?.commission_in_percent !== undefined).map((contract) => ({
          ...contract

        }))
      })).filter(Boolean)
    };
    const result = await dispatch(createObjectAction(formatedData));

    if (result?.error) {
      toastify('error', 'Возникла ошибка');
    } else {
      setOpenModal(true);
      setModalData(result?.payload?.meta);
    }
  };

  const handleSelect = (search_area) => {
    methods.setValue(PLACE_ID, search_area);
  };

  const searchArea = methods.watch(PLACE_ID);

  useEffect(() => {
    fetch();
    methods.setValue(`${SOURCES}.${0}.${INSURANCE_ID}`, sources[0]?.id);
    methods.setValue(`${SOURCES}.${1}.${INSURANCE_ID}`, sources[1]?.id);
  }, []);

  return (
    <form name='sanatory_form' onSubmit={handleSubmit(onSubmit)}>
      <Content className={classes.content}>
        <Box className={classes.box}>
          <Text size={30} color='#000'>{t('Objects.New.Form1.Title')}</Text>

          {/* First block */}
          <div className={classes.block}>
            <Text size={20} color='#000'>{t('Objects.New.Form.All')}</Text>

            <FormField
              name={NAME}
              control={control}
              errors={errors?.[NAME]}
              label={t('Objects.New.Form.Name')}
              placeholder={t('Objects.New.Form.Name.Placeholder')}
              required
            />
            <FormField
              name={LOCATION}
              control={control}
              errors={errors?.[LOCATION]}
              label={t('Objects.New.Form.Address')}
              placeholder={t('Objects.New.Form.Address.Placeholder')}
              required
            />
            <Row className={classes.row}>
              <FormField
                name={EMAIL}
                control={control}
                errors={errors?.[EMAIL]}
                label={t('Objects.New.Form.Email')}
                placeholder={t('Objects.New.Form.Email.Placeholder')}
                required
              />
              <FormField
                name={PHONE}
                control={control}
                errors={errors?.[PHONE]}
                label={t('Objects.New.Form.Phone')}
                placeholder={t('Objects.New.Form.Phone.Placeholder')}
                required
              />
            </Row>
          </div>

          {/* Second block */}
          <div className={classes.block}>
            <Text size={20} color='#000'>{t('Objects.New.Form.Law')}</Text>

            <Row className={classes.row}>
              <FormField
                name={`${LEGAL_ENTITY}.${INN}`}
                control={control}
                errors={errors?.[LEGAL_ENTITY]?.[INN]}
                label={t('Objects.New.Form.Inn')}
                placeholder={t('Objects.New.Form.Inn.Placeholder')}
                required
              />
              <FormField
                name={`${LEGAL_ENTITY}.${KPP}`}
                control={control}
                errors={errors?.[LEGAL_ENTITY]?.[KPP]}
                label={t('Objects.New.Form.Kpp')}
                placeholder={t('Objects.New.Form.Kpp.Placeholder')}
              />

              <Field
                label={t('Objects.New.Form.Currency')}
                name={CURRENCY}
                error={errors?.[CURRENCY]}
                required
              >
                <Controller
                  name={CURRENCY}
                  control={control}
                  render={({ field }) => (
                    <CurrencySelect
                      error={!!errors[CURRENCY]}
                      simpleValue='RUB'
                      {...field}
                    />
                  )}
                />
              </Field>

            </Row>
          </div>

          {/* Third block */}
          <div className={classes.block}>
            <Text size={20} color='#000'>{t('Objects.New.Form.Search')}</Text>
            <Field
              label={t('Objects.New.Form.SearchArea')}
              name={PLACE_ID}
              error={errors?.[PLACE_ID]}
              required

            >
              <Controller
                name={PLACE_ID}
                control={control}
                render={({ field }) => (
                  <RegionSelect
                    errors={errors?.[PLACE_ID]} onSelect={handleSelect} value={searchArea}
                    error={!!errors[PLACE_ID]}
                    {...field}
                  />
                )}
              />

            </Field>

            <Row className={classes.row}>
              <FormField
                name={`${COORDINATES}.${LNG}`}
                control={control}
                errors={errors?.[COORDINATES]?.[LNG]}
                label={t('Objects.New.Form.Lng')}
                placeholder={t('Objects.New.Form.Lng.Placeholder')}
                required
              />
              <FormField
                name={`${COORDINATES}.${LAT}`}
                control={control}
                errors={errors?.[COORDINATES]?.[LAT]}
                label={t('Objects.New.Form.Lat')}
                placeholder={t('Objects.New.Form.Lat.Placeholder')}
                required
              />

              <div style={{ minWidth: '280px' }}>
                <Field
                  label={t('Objects.New.Form.Timezone')}
                  name={TIMEZONE}
                  required
                  error={errors[TIMEZONE]}
                >
                  <Controller
                    name={TIMEZONE}
                    control={control}
                    render={({ field }) => (
                      <TimezonesSelect
                        {...field}
                        error={!!errors[TIMEZONE]}
                      />
                    )}
                  />
                </Field>
              </div>

            </Row>
          </div>

        </Box>
        <Box className={classes.box}>
          <Text size={30} color='#000'>{t('Objects.New.Form2.Title')}</Text>
          {sources?.map(({ name, id }, index) => {
            return (
              <div key={id} className={classes.block}>
                <Text size={20} color='#000'>{name}</Text>
                <div>
                  <Row className={classes.row}>
                    <FormField
                      name={`${SOURCES}.${index}.${INSURANCE_ID}`}
                      control={control}
                      className={classes.hidden}
                      type='hidden'
                    />
                    <FormField
                      name={`${SOURCES}.${index}.${CONTRACTS}.${index}.${NUMBER}`}
                      control={control}
                      errors={errors?.[SOURCES]?.[index]?.[CONTRACTS]?.[index]?.[NUMBER]}
                      label={t('Objects.New.Form2.Number')}
                      placeholder={t('Objects.New.Form2.Number.Placeholder')}
                      required
                    />

                    <Field
                      label={t('Objects.New.Form2.Date')}
                      name={`${SOURCES}.${index}.${CONTRACTS}.${index}.${DATE}`}
                      error={errors?.[SOURCES]?.[index]?.[CONTRACTS]?.[index]?.[DATE]}
                      required
                    >
                      <Controller
                        name={`${SOURCES}.${index}.${CONTRACTS}.${index}.${DATE}`}
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            className={classes.datepicker}
                            error={errors?.[SOURCES]?.[index]?.[CONTRACTS]?.[index]?.[DATE]}
                            {...field}
                          />
                        )}
                      />
                    </Field>

                    <FormField
                      name={`${SOURCES}.${index}.${CONTRACTS}.${index}.${COMMISSION}`}
                      control={control}
                      errors={errors?.[SOURCES]?.[index]?.[CONTRACTS]?.[index]?.[COMMISSION]}
                      label={t('Objects.New.Form2.Commission')}
                      placeholder={t('Objects.New.Form2.Commission.Placeholder')}
                      required
                      type='number'
                    />
                  </Row>
                </div>
              </div>
            );
          })}

        </Box>
        <Button type='submit' className={classes.button_green}>
          {t('Action.Add')}
        </Button>

      </Content>

    </form>
  );
}

SanatoryForm.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  setModalData: PropTypes.func.isRequired,
  provider: PropTypes.string.isRequired
};

export default styled(SanatoryForm)``;
