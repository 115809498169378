import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// @ts-ignore
import Badge from 'components/ui/Badge';
// @ts-ignore
import Image from 'components/ui/Image';
import Header from 'components/ui/Header';
// @ts-ignore
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import ButtonGroup, { LeftGroup } from 'components/ui/ButtonGroup';
// @ts-ignore
import { MoreIcon } from 'components/ui/Icons';
// @ts-ignore
import Dropdown, { DropdownList, DropdownItem } from 'components/ui/Dropdown';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;

  ${Header} + ${Text} {
    margin-top: 4px;
  }

  ${Text} + ${ButtonGroup} {
    margin-top: 16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  border: 1px solid #DCD8D2;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  background: #FFFFFF;

  ${Badge} {
    position: absolute;
    left: 16px;
    bottom: 16px;
  }

  ${Body} {
    flex-grow: 1;
  }
`;

function ReviewerCard({ reviewer, onEdit, onDelete, onEditAdditional, className }) {
  const { avatar, full_name, specializations } = reviewer;
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const specializationsLabels = specializations.reduce((accum, item, index) => {
    accum += item.name;

    if (specializations.length !== index + 1) {
      accum += ', ';
    }

    return accum;
  }, '');

  const handleEdit = (e) => {
    onEdit(e);
  };

  const handleEditAdditional = (e) => {
    onEditAdditional(e);
    setDropdownIsOpen(false);
  };

  return (
    <Wrapper className={className}>
      <Image
        width='auto'
        height='152px'
        src={avatar?.url}
      >
        {reviewer.category && (
          <Badge>{reviewer.category.name}</Badge>
        )}
      </Image>

      <Body>
        <Header level={5}>
          {full_name}
        </Header>

        <Text variant='secondary'>
          {specializationsLabels}
        </Text>

        <ButtonGroup>
          <LeftGroup>
            <Button
              size='medium'
              variant='secondary'
              onClick={handleEdit}
            >
              Редактировать
            </Button>

            <Dropdown
              isOpen={dropdownIsOpen}
              onClickOutside={() => setDropdownIsOpen(false)}
              content={
                <DropdownList>
                  <DropdownItem onClick={() => onDelete(reviewer.id)}>
                    <Text>Удалить</Text>
                  </DropdownItem>
                  <DropdownItem>
                    <Text>Дублировать</Text>
                  </DropdownItem>
                  <DropdownItem>
                    <Text>Посмотреть на сайте</Text>
                  </DropdownItem>
                  <DropdownItem
                    onClick={handleEditAdditional}
                  >
                    <Text>Изменить категорию</Text>
                  </DropdownItem>
                </DropdownList>
              }
            >
              <Button
                size='medium'
                variant='secondary'
                onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
              >
                <MoreIcon />
              </Button>
            </Dropdown>
          </LeftGroup>
        </ButtonGroup>
      </Body>
    </Wrapper>
  );
}

ReviewerCard.defaultProps = {
  onEdit: () => {},
  onPublish: () => {}
};

ReviewerCard.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func,
  reviewer: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
    full_name: PropTypes.string,
    start_of_practice: PropTypes.string,
    specializations: PropTypes.array,
    avatar: PropTypes.shape({
      url: PropTypes.string.isRequired
    }),
    category: PropTypes.shape({
      name: PropTypes.string.isRequired,
      tag: PropTypes.string.isRequired
    })
  }).isRequired,
  onEdit: PropTypes.func,
  onEditAdditional: PropTypes.func
};

export default styled(ReviewerCard)``;
