import connection from 'connection/instance';
import { AxiosResponse } from 'axios';
import {
  ISearchAreaFetchParams,
  ISearchArea,
  ISearchAreaResponseData,
  ISearchAreaMeta,
  ISearchAreaRequestParamsFull,
  ICatalog
} from 'types';

const limit = 100;

const site = process.env.REACT_APP_SITE;

export const searchAreaFetch = async ({
  page,
  parent_osm_id
}: {
  page: number;
  parent_osm_id?: string;
}): Promise<ISearchAreaResponseData<ISearchArea[], ISearchAreaMeta>> => {
  const params: ISearchAreaFetchParams = {
    data: {
      filter: {
        parent_osm_id
      },
      paginate: {
        page,
        limit
      }
    }
  };

  const response: AxiosResponse<ISearchAreaResponseData<ISearchArea[], ISearchAreaMeta>> = await connection.get(
    '/api/search_area',
    { params }
  );

  return response.data;
};

export const searchAreaFetchOne = async (
  id: string
): Promise<ISearchAreaResponseData<ISearchArea, ISearchAreaMeta>> => {
  const response: AxiosResponse<ISearchAreaResponseData<ISearchArea, ISearchAreaMeta>> = await connection.get(
    `/api/search_area/${id}`
  );

  return response.data;
};

export const catalogFetch = async (): Promise<ISearchAreaResponseData<ICatalog[], ISearchAreaMeta>> => {
  const response: AxiosResponse<ISearchAreaResponseData<ICatalog[], ISearchAreaMeta>> = await connection.get(
    `${site}/api/pages/seo`
  );

  return response.data;
};

export const searchAreaUpdate = async ({
  id,
  data
}: {
  id: string;
  data: ISearchAreaRequestParamsFull;
}): Promise<ISearchAreaResponseData<ISearchArea, ISearchAreaMeta>> => {
  const response: AxiosResponse<ISearchAreaResponseData<ISearchArea, ISearchAreaMeta>> = await connection.put(
    `/api/search_area/${id}`,
    { data }
  );

  return response.data;
};
