import React from 'react';
import { DateTime } from 'luxon';

import { Text, Box } from '@mantine/core';

import useStyles from './Hotel.styles';

import AvailabilityTable from 'components/ui/AvailabilityTable';
import { IAvailabilitiesResponse } from 'types/availabilities';

type Props = React.PropsWithChildren<{
  className?: string;
  data: IAvailabilitiesResponse;
  variant: string;
}>;

function SearchArea({ className, data, variant }: Props) {
  const { classes, cx } = useStyles();

  const availabilities: [string, number][] = data.availabilities.map((d: any) => {
    return [
      DateTime.fromISO(d.day).toFormat('yyyy-MM-dd'),
      d[variant]
    ]
  });

  return (
    <Box className={cx(classes.root, className)}>
      <Box className={classes.top}>
        <Text size={18} weight={400} align='center' color='#000'>
          {data.hotel.name}
        </Text>

        <Text size={12} color='#777777' className={classes.location} align='center'>
          {data.hotel.address.location}
        </Text>
      </Box>

      <AvailabilityTable availabilities={availabilities} className={classes.table} />
    </Box>
  );
}

export default SearchArea;
