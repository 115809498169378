import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import List from '@editorjs/list';
import Warning from '@editorjs/warning';
import Code from '@editorjs/code';
import LinkTool from '@editorjs/link';
import Image from './plugins/Image';
import Raw from '@editorjs/raw';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';

import Programs from './plugins/Programs';
import Documents from './plugins/Documents';
import BlogCard from './plugins/BlogCard';
import RegionCard from './plugins/RegionCard';
import Stars from './plugins/Stars';
import Procedures from './plugins/Procedures';
import Distances from './plugins/Distances';
import Links from './plugins/Links';
import FeedTypes from './plugins/FeedTypes';
import InfoBlock from './plugins/InfoBlock';
import Gallery from './plugins/Gallery';
import ListItems from './plugins/List';
import WhatToSee from './plugins/WhatToSee';
import Treatments from './plugins/Treatments';
import HotelCard from './plugins/HotelCard';
import HotelCardSmall from './plugins/HotelCardSmall';
import Comments from './plugins/Comments';
import Facts from './plugins/Facts';
import Description from './plugins/Description';
import Map from './plugins/Map';
import TitleAndDescription from './plugins/TitleAndDescription';
import DescriptionChatGPT from './plugins/DescriptionChatGPT';
import NewYear from './plugins/NewYear';

const customTools = {
  links: Links,
  newYear: NewYear,
  blogCard: BlogCard,
  distances: Distances,
  documents: Documents,
  stars: Stars,
  description: Description,
  titleAndDescription: TitleAndDescription,
  hotelCard: HotelCard,
  programs: Programs,
  procedures: Procedures,
  comments: Comments,
  listItems: ListItems,
  treatments: Treatments,
  whatToSee: WhatToSee,
  feeding_programs: FeedTypes,
  facts: Facts,
  gallery: Gallery,
  regionCard: RegionCard,
  map: Map,
  hotelCardSmall: HotelCardSmall,
  descriptionChatGPT: DescriptionChatGPT
};

const defaultTools = {
  infoBlock: InfoBlock,
  image: Image,
  header: Header,
  list: List
};

const getConstants = (plugins, lang) => {
  const custom = {};

  plugins.forEach((element) => {
    if (customTools[element]) {
      custom[element] = {
        class: customTools[element],
        inlineToolbar: true,
        config: {
          lang
        }
      };
    }
  });

  const tools = {};

  for (const key in defaultTools) {
    tools[key] = {
      class: defaultTools[key],
      inlineToolbar: true,
      config: {
        lang
      }
    };
  }

  return {
    ...custom,
    ...tools
  };
};

export default getConstants;
