import { createStyles } from '@mantine/styles';
import { relative } from 'path';

const useStyles = createStyles((theme, _, getRef) => ({
  root: {},
  label: {

  },
  description: {
    marginTop: 4
  },

  periods: {
    marginTop: 4,
    paddingLeft: 8
  },

  rates: {
    marginTop: 4,
    marginLeft: 8
  },

  period: {
    ref: getRef('period'),
    position: 'relative',
    [`& + .${getRef('period')}`]: {
      marginTop: 12
    }
  },

  leftRate: {

  },

  rightRate: {
    marginLeft: 20
  }
}));

export default useStyles;
