import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getImage } from 'Utils/getImage';

const Title = styled.div`
  font-size: 18px;
  font-family: GTEesti;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 600;
`;

const ImageContainer = styled.div`
  width: 294px;
  user-select: none;  
  cursor: pointer;
  position: relative;
  height: 240px;
  border-radius: 4px;
  background: url(${({ image }) => image});
  background-size: cover;
  padding: 22px 24px;
  box-sizing: border-box;

  &:before {
    content: 'Подробнее';
    height: min-content;
    top: auto;
    right: auto;
    background-image: none;
    font-size: 16px;
    line-height: 20px;
    color: #3582db;
    position: absolute;
    bottom: 19px;
    left: 24px;
  }
`;

const Wrapper = styled.div`
  

  ${({ opened }) => opened && css`
    ${ImageContainer} {
      &:before {
        content: 'Скрыть'
      }
    }
  `}
`;

function Treatment({ className, treatment, opened, onClick }) {
  const image = getImage(treatment.image.encoded_url, { width: 588, height: 480 });

  return (
    <Wrapper className={className} onClick={() => onClick(treatment)} opened={opened}>
      <ImageContainer image={image.defaultSrc}>
        <Title dangerouslySetInnerHTML={{ __html: treatment.title }} />
      </ImageContainer>
    </Wrapper>
  );
}

Treatment.propTypes = {
  className: PropTypes.string,
  treatment: PropTypes.object,
  opened: PropTypes.bool,
  onClick: PropTypes.func
};

export default styled(Treatment)``;
