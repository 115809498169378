import React from 'react';
import { Text, Box } from '@mantine/core';

import useStyles from './Rates.styles';
import { useTranslation } from 'react-i18next';

import Rate from '../shared/Rate';
import { IRate } from 'types/parsing';


type Props = React.PropsWithChildren<{
  className?: string;
  rates: IRate[]
}>;

function Rates({ className, rates }: Props) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const ratesComponent = rates.map(rate => <Rate rate={rate} />);

  return (
    <Box className={cx(classes.root, className)}>
      <Text className={classes.label} size={16} color='#212529'>
        {t('Tariffs.Rates.Title')}
      </Text>

      <Box className={classes.rates}>
        {ratesComponent}
      </Box>
    </Box>
  );
}

export default Rates;
