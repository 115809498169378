import { css } from 'styled-components';

const defaults = (props) => css`
  border: 1px solid #DCD8D2;
  border-radius: 4px;
`;

const theme = (props) => ([
  defaults(props)
]);

export default theme;
