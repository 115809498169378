import { useState } from 'react';
import reviewersApi from 'Apis/ReviewersApi';

function useReviewers() {
  const [value, setValue] = useState(null);

  const replaceValue = (data) => {
    if (!Array.isArray(value)) {
      setValue(data);
      return;
    }

    const newValues = value.map((item) => {
      return item.id === data.id ? data : item;
    });

    setValue(newValues);
  };

  const removeValue = (id) => {
    const newValues = value.filter((item) => {
      return item.id !== id;
    });

    setValue(newValues);
  };

  const appendValue = (data) => {
    if (!Array.isArray(value)) {
      setValue(data);
      return;
    }

    const newValues = [...value, data];
    setValue(newValues);
  };

  const fetch = async () => {
    const { data, error } = await reviewersApi.fetchList();

    if (!error) {
      setValue(data);
    }

    return { data, error };
  };

  const fetchOne = async (id) => {
    const { data, error } = await reviewersApi.fetchOne(id);

    if (!error) {
      setValue(data);
    }

    return { data, error };
  };

  const publish = async ({ id }) => {
    const { data, error } = await reviewersApi.publish(id);

    if (!error) {
      replaceValue(data);
    }

    return { data, error };
  };

  const unpublish = async ({ id }) => {
    const { data, error } = await reviewersApi.unpublish(id);

    if (!error) {
      replaceValue(data);
    }

    return { data, error };
  };

  const create = async (props) => {
    const { data, error } = await reviewersApi.create(props);

    if (!error) {
      appendValue(data);
    }

    return { data, error };
  };

  const deleteReviewer = async (id) => {
    const { data, error } = await reviewersApi.delete(id);

    if (!error) {
      removeValue(id);
    }

    return { data, error };
  };

  const update = async (id, props) => {
    const { data, error } = await reviewersApi.update(id, props);

    if (!error) {
      appendValue(data);
    }

    return { data, error };
  };

  return [value, { create, update, publish, unpublish, fetch, deleteReviewer, fetchOne }];
}

export default useReviewers;
