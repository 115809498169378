import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

import { RegionIcon, RoundedArrowIcon } from 'components/ui/Icons';
import { useTranslation } from 'react-i18next';

import getSearchDates from 'Utils/getSearchDates';

const site = process.env.REACT_APP_SITE;

const DEFAULT_PARAMS = {
  ...getSearchDates(),
  travellers: [
    { age_group: 'adult', age: 0 }
  ]
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

function Region({ data }) {
  const { t } = useTranslation();

  const siteData = `/${data.region.slug}/?${qs.stringify({
      place: {
        ...data.region,
        label: data.region.name,
        id: data.region.place_id
      },
      check_in: DEFAULT_PARAMS.check_in,
      check_out: DEFAULT_PARAMS.check_out
    })}`.replace(/^\/+/g, '/');

  return (
    <div className='region_wrapper'>
      <div className='region_leftContent'>
        <div className='region_container'>
          <RegionIcon className='region_container_svg' />
        </div>

        <div className='region_textContainer'>
          <div className='region_commonText'>
            {t('RegionCard.Check')}
          </div>

          <a href={`${site}${siteData}`} target='_blank' className='region_focusText' rel='noreferrer'>
            {data.mainText}
          </a>

          <div className='region_countText'>
            ({getRandomInt(50, 100)})
          </div>
        </div>
      </div>

      <div>
        <div className='region_iconContainer'>
          <RoundedArrowIcon className='region_iconContainer_svg' />
        </div>
      </div>
    </div>
  );
}

Region.propTypes = {
  data: PropTypes.object
};

export default Region;
