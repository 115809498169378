import React from 'react';
import { Tabs, Badge } from '@mantine/core';
import _cloneDeep from 'lodash/cloneDeep';

import RegionSelect from './RegionSelect';
import HotelRegionSelect from './HotelRegionSelect';
import CatalogSelect from './CatalogSelect';

import useStyles from './Tabs.styles';

const SEARCH_ADDITIONALS = 'search_additionals';
const SLUG = 'slug';
const SEARCH_MODE = 'search_mode';

type Props = React.PropsWithChildren<{
  className?: string;
  methods: any
}>;

type Object = {
  area_id: string;
  type: string;
  name: string;
  radius?: number;
};

function HotelSearchArea({ className, methods }: Props) {
  const { classes } = useStyles();

  const searchAdditionals = methods.watch(SEARCH_ADDITIONALS);
  const catalogSlug = methods.watch(SLUG);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleSelect = (obj: Object) => {
    methods.setValue(SEARCH_ADDITIONALS, [...searchAdditionals, obj]);
  };

  const handleSelectCatalog = (tag: string | undefined) => {
    methods.setValue(SLUG, tag);
  };

  const handleDelete = (id: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-types
    methods.setValue(
      SEARCH_ADDITIONALS,
      // eslint-disable-next-line @typescript-eslint/ban-types
      searchAdditionals.filter((region: Object) => region.area_id !== id)
    );
  };

  const handleChangeRadius = (radius: number, index: number) => {
    const areas = _cloneDeep(searchAdditionals);
    areas[index].radius = radius;
    methods.setValue(SEARCH_ADDITIONALS, areas);
  };

  return (
    <Tabs defaultValue='region'>
      <Tabs.List grow>
        <Tabs.Tab
          className={classes.tab} value='region'
          rightSection={
            <Badge
              className={classes.badge}
              variant='filled'
              size='xs'
              p={0}
            >
              {searchAdditionals.filter((s: any) => s.type === 'region').length}
            </Badge>
          }
        >
          Регионы
        </Tabs.Tab>

        <Tabs.Tab
          className={classes.tab}
          value='hotel'
          rightSection={
            <Badge
              className={classes.badge}
              variant='filled'
              size='xs'
              p={0}
            >
              {searchAdditionals.filter((s: any) => s.type === 'hotels').length}
            </Badge>
          }
        >
          Объекты размещения
        </Tabs.Tab>

        <Tabs.Tab
          className={classes.tab}
          value='links'
          rightSection={
            <Badge
              className={classes.badge}
              variant='filled'
              size='xs'
              p={0}
            >
              {catalogSlug ? 1 : 0}
            </Badge>
          }
        >
          Каталог
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value='region' pt='xs'>
        <RegionSelect
          onChangeRadius={handleChangeRadius}
          searchAdditionals={searchAdditionals}
          onSelect={handleSelect}
          onDelete={handleDelete}
          className={classes.regions}
          disabled={methods.watch(SEARCH_MODE) === 'area'}
        />
      </Tabs.Panel>

      <Tabs.Panel value='hotel' pt='xs'>
        <HotelRegionSelect
          searchAdditionals={searchAdditionals}
          onSelect={handleSelect}
          onDelete={handleDelete}
          className={classes.regions}
          onChangeRadius={handleChangeRadius}
        />
      </Tabs.Panel>

      <Tabs.Panel value='links' pt='xs'>
        <CatalogSelect
          onSelect={handleSelectCatalog}
          className={classes.regions}
          catalogSlug={catalogSlug}
        />
      </Tabs.Panel>
    </Tabs>
  );
}

export default HotelSearchArea;
