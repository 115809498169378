import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BlockCards from './BlockCards';
import ListCards from './ListCards';

const Wrapper = styled.div`
  width: 100%;
`;

function BlogCards({ className, ...rest }) {
  const { type } = rest;

  return (
    <Wrapper className={className}>
      {type === 'list' ? <ListCards {...rest} /> : <BlockCards {...rest} />}
    </Wrapper>
  );
}

BlogCards.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  onEdit: PropTypes.func,
  type: PropTypes.string,
  onEditAdditional: PropTypes.func,
  onPublish: PropTypes.func
};

export default styled(BlogCards)``;
