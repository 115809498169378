import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {

  },
  images: {
    display: 'flex',
    overflow: 'hidden',
    marginTop: 18,
    gap: 8
  }
}));

export default useStyles;
