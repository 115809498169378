const translates = {
  regions: { ru: 'Регионы', en: 'Regions' },
  formTitle: { ru: 'Добавить регион', en: 'Add region' },
  generalSection: { ru: 'Общее', en: 'General' },
  articleName: { ru: 'Название статьи', en: 'Article name' },
  articlePlaceholder: { ru: 'Введите название', en: 'Enter name' },
  category: { ru: 'Категория', en: 'Category' },
  categoryPlaceholder: { ru: 'Выберите категорию', en: 'Choose category' },
  author: { ru: 'Автор', en: 'Author' },
  authorPlaceholder: { ru: 'Выберите автора', en: 'Choose author' },
  coordsSection: { ru: 'Координаты', en: 'Coordinates' },
  coordX: { ru: 'Широта', en: 'Latitude' },
  coordY: { ru: 'Долгота', en: 'Longitude' },
  coordXPlaceholder: { ru: 'Введите широту', en: 'Enter latitude' },
  coordYPlaceholder: { ru: 'Введите долготу', en: 'Enter longitude' },
  seoSection: { ru: 'Данные для SEO', en: 'SEO data' },
  seoTitle: { ru: 'Заголовок', en: 'Title' },
  seoTitlePlaceholder: { ru: 'Введите заголовок', en: 'Enter title' },
  seoDescription: { ru: 'Описание', en: 'Description' },
  seoDescriptionPlaceholder: { ru: 'Введите описание', en: 'Enter description' },
  addTagPair: { ru: 'Добавить СЕО-аттрабиуты', en: 'Add SEO-attributes' },
  tag: { ru: 'Тэг', en: 'Tag' },
  tagPlaceholder: { ru: 'Выберите тэг', en: 'Choose tag' },
  property: { ru: 'Свойство', en: 'Property' },
  propertyPlaceholder: { ru: 'Введите свойство', en: 'Enter property' },
  value: { ru: 'Значение', en: 'Value' },
  valuePlaceholder: { ru: 'Введите значение', en: 'Enter value' },
  deleteButton: { ru: 'Удалить', en: 'Delete' },
  saveButton: { ru: 'Сохранить', en: 'Save' },
  cancelButton: { ru: 'Отмена', en: 'Cancel' }
};

export const getRegionTranslate = (component, lang) => {
  return translates?.[component]?.[lang];
};

export const languages = [
  'ru',
  'en'
];
