import { useState } from 'react';
import reviewersApi from 'Apis/ReviewersApi';

function useOptions() {
  const [value, setValue] = useState([]);

  const fetch = async () => {
    const { data, error } = await reviewersApi.fetchList();

    if (!error) {
      setValue(data);
    }

    return { data, error };
  };

  return [value, { fetch }];
}

export default useOptions;
