import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {},
  button: {
    background: '#D2D1CC',
    height: 36,
    fontSize: 14,
    borderRadius: 4,
    color: '#333333',
    transition: 'all .2s ease-in-out',
    marginRight: '111px',

    '&:hover': {
      background: '#C4C2BB'
    }
  },
  button_green: {
    height: 36,
    fontSize: 14,
    borderRadius: 4,
    color: '#fff',
    transition: 'all .2s ease-in-out',
    background: '#52AB18',
    position: 'absolute',
    top: '22px',
    zIndex: '99',
    right: '94px',

    '&:hover': {
      background: '#428616'
    }
  },
  button_green_static: {
    height: 36,
    fontSize: 14,
    borderRadius: 4,
    color: '#fff',
    transition: 'all .2s ease-in-out',
    background: '#52AB18',

    '&:hover': {
      background: '#428616'
    }
  },
  tabs: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px'
  },
  tab: {
    height: 41,
    fontSize: 14,
    borderRadius: 4,
    transition: 'all .2s ease-in-out'
  },
  left_wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0'
  },
  right_wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px'
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '672px 437px',
    marginTop: '12px',
    gap: '36px'
  },
  box: {
    background: '#fff',
    padding: '36px',
    height: 'fit-content'
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '24px'
  },
  row: {
    gap: '16px'
  },
  hidden: {
    display: 'none'
  },
  datepicker: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #DCD8D2',
    background: '#FFFFFF',
    color: '#333333',
    boxSizing: 'border-box',
    fontSize: '14px',
    padding: '0 12px',
    borderRadius: '4px',
    flex: '1',
    width: '100%',
    height: '32px',
    outline: 'none'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '20px',
    marginBottom: '20px'
  },
  modalRow: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center'
  },
  icon: {
    cursor: 'pointer'
  }
}));

export default useStyles;
