import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'inherit',
    minHeight: 90,
    maxHeight: 90,
    bottom: 0,
    overflow: 'hidden',
    background: 'blue',
    position: 'fixed'
  }
}));

export default useStyles;
