import { toast } from 'react-toastify';

export const toastify = (type, text) => {
  toast[type](text, {
    position: 'top-left',
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined
  });
};
