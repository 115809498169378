import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

const Wrapper = styled.form`
`;

function Form({ children, onSubmit, onReset, className, initialValue, schema }) {
  const methods = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(schema)
  });

  const handleReset = (e) => {
    e.stopPropagation();
    e.preventDefault();

    onReset();
  };

  return (
    <FormProvider {...methods}>
      <Wrapper
        onSubmit={methods.handleSubmit(onSubmit)}
        onReset={handleReset}
        className={className}
      >
        {children}
      </Wrapper>
    </FormProvider>
  );
}

Form.defaultProps = {
  onSubmit: () => {},
  onReset: () => {}
};

Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  initialValue: PropTypes.object,
  schema: PropTypes.any
};

export default styled(Form)``;
