import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import Input from 'components/ui/Input/Input';
import Field from 'components/ui/Field/Field';

export const FormField = ({ control, errors, name, label, placeholder, required, type, className }) => {
  return (
    <Field
      label={label}
      error={errors}
      name={name}
      required={required}
      className={className}
      isHidden={type === 'hidden'}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <Input
              error={!!errors}
              placeholder={placeholder}
              type={type}
              className={className}
              {...field}
            />
          );
        }}
      />
    </Field>
  );
};

FormField.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string
};
