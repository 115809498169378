import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mantine/core';

import useStyles from './Content.styles';

type Props = {
  className?: string,
  program: any
}

function Content({ className, program }: Props) {
  const { classes, cx } = useStyles();

  return (
    <Box className={cx(className, classes.root)}>
      <Box className={classes.programContent} dangerouslySetInnerHTML={{ __html: program.description }} />
    </Box>
  );
}

Content.propTypes = {
  className: PropTypes.string,
  program: PropTypes.object
};

export default Content;
