import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme, _, getRef) => ({
  root: {
    fontFamily: 'GTEesti !impotant'
  },
  title: {
    lineHeight: '42px'
  },
  address: {
    marginTop: 20
  },
  hotelName: {
    display: 'inline',

    [`& + .${getRef('stars')}`]: {
      marginLeft: 14
    }
  },
  stars: {
    ref: getRef('stars'),
    display: 'inline-flex',
    height: 28,
    alignItems: 'center',
    minHeight: 26,
    marginTop: 8
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 22px',
    boxSizing: 'border-box',
    background: '#e4f9ee',
    borderRadius: 22,
    fontWeight: 700,
    fontSize: 30,
    lineHeight: '35px',
    color: '#1bc47d',
    marginBottom: 6
  },
  reviewContainer: {

  },
  ratingText: {
    whiteSpace: 'nowrap',
    marginLeft: 6
  },
  rightContant: {
    marginLeft: 60
  }
}));

export default useStyles;
