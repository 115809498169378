import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Loader from 'components/ui/Loader';

import Top from './Top';

const PriceContent = styled.div`

`;

const Price = styled.div`
  font-family: GTEesti;
  white-space: nowrap;
  line-height: 19px;
  font-weight: 700;
  font-size: 22px;
`;

const Billing = styled.div`
  font-family: GTEesti;
  line-height: 19px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
`;

const Button = styled.div`
  width: 180px;
  color: #fff;
  background: #00bb58;
  border-radius: 43px;
  line-height: 44px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:hover {
    background-color: #35df80;
  }
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #323232;
  font-family: GTEesti;
  margin-top: 20px;

  br{
    content: '';
    margin: 8px 0;
    white-space: pre;
    display: block;
  } 
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`;

const TopContent = styled.div``;

const PriceBlock = styled.div``;

const NotAvailable = styled.div`
  color: #EC4B61;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const site = process.env.REACT_APP_SITE || 'https://sanatory-testing.ru';

function RightContainer({ className, hotel, price, isPending }) {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open(`${site}/hotel/${hotel.slug}#roomTypes`);
  };

  const pretiffyPrice = (price) => {
    const n = Math.round(price).toString();
    const separator = ' ';
    return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + separator);
  };

  const enteredPrice = {
    amount: Number(hotel.price_text.match(/\d+/)[0]),
    currency: price?.currency || 'RUB'
  };

  return (
    <Wrapper className={className}>
      <TopContent>
        <Top hotel={hotel} />

        <Description dangerouslySetInnerHTML={{ __html: hotel.description }} />
      </TopContent>

      <Bottom>
        <PriceBlock>
          {!isPending && enteredPrice?.amount > 0 && (
            <PriceContent>
              <Price>
                {pretiffyPrice(price?.amount > 0 ? price.amount : enteredPrice.amount)} ₽
              </Price>

              <Billing>
                за сутки
              </Billing>
            </PriceContent>
          )}

          {!isPending && enteredPrice?.amount === 0 && (
            <NotAvailable>{t('HotelCard.Unavailable')}</NotAvailable>
          )}

          {isPending && <Loader />}
        </PriceBlock>

        <Button onClick={handleClick}>
          {t('HotelCard.Action.ShowRooms')}
        </Button>
      </Bottom>
    </Wrapper>
  );
}

RightContainer.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object,
  price: PropTypes.object,
  isPending: PropTypes.bool
};

export default styled(RightContainer)``;
