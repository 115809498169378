import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Input, Button } from '@mantine/core';
import * as yup from 'yup';

import { useFormContext, Controller } from 'react-hook-form';

import RegionSelect from 'components/ui/RegionSelect';
import Field from 'components/ui/Field';

import useStyles from './Form.styles';

import { IRegionDocument } from 'types';

type Props = React.PropsWithChildren<{
  className?: string;
  loading: boolean;
}>;

const HOTEL_CODE = 'hotel_code';
const PLACE = 'place';

export const schema = yup.object().shape({
  [HOTEL_CODE]: yup.string().required(),
  [PLACE]: yup.object().required()
});

function Add({ className, loading }: Props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { formState: { errors }, setValue } = useFormContext();

  const handleSelect = (hotelObj: IRegionDocument) => {
    setValue(PLACE, hotelObj);
  };

  return (
    <Box>
      <RegionSelect
        placeholder={t('SearchArea.Objects.Placeholder')}
        onSelect={handleSelect}
        className={classes.select}
        type='hotels'
      />

      <Box className={classes.container}>
        <Field
          label={false}
          error={!!errors[HOTEL_CODE]}
          required
          className={classes.form}
          name={HOTEL_CODE}
        >
          <Controller
            name={HOTEL_CODE}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  className={classes.input}
                  placeholder={t('Parsing.Add.UUID.Placeholder')}
                />
              );
            }}
          />
        </Field>

      </Box>

      <Box className={classes.container}>
        <Button
          className={classes.button}
          type='submit'
          loading={loading}
          loaderProps={{ color: '#52AB18' }}
        >
          {t('Common.Create')}
        </Button>
      </Box>
    </Box >
  );
}

Add.propTypes = {
  className: PropTypes.string
};

export default Add;
