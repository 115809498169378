import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DragDrop from 'editorjs-drag-drop';
import { createReactEditorJS } from 'react-editor-js';

import getConstants from './getConstants';

import './style.css';

const ReactEditorJS = createReactEditorJS();

const Wrapper = styled.div`
  background: #fff;
  margin: 0 auto;
  padding: 40px 40px;
  box-sizing: border-box;
  position: relative;
  width: 100%;

  br {
    min-height: 20px;
  }

  >div {
    &:nth-child(2) {
      min-width: 672px;
    }
  }
`;

function Editorjs({ editorRef, defaultValue, lang, plugins }) {
  const handleInitialize = React.useCallback((instance) => {
    editorRef.current = instance;
  }, []);

  const handleReady = () => {
    const editor = editorRef.current._editorJS;

    // eslint-disable-next-line no-new
    new DragDrop(editor);
  };

  const constants = getConstants(plugins, lang);

  return (
    <Wrapper>
      <ReactEditorJS
        holder={lang}
        onReady={handleReady}
        tools={constants}
        onInitialize={handleInitialize}
        i18n={{
          messages: {
            ui: {
              blockTunes: {
                toggler: {
                  'Click to tune': 'Нажмите, чтобы настроить',
                  'or drag to move': 'или перетащите'
                }
              },
              toolbar: {
                toolbox: {
                  Add: 'Добавить'
                }
              }
            },

            toolNames: {
              Text: 'Параграф',
              procedures: 'test',
              Heading: 'Заголовок',
              List: 'Список',
              Warning: 'Примечание',
              Checklist: 'Чеклист',
              Quote: 'Цитата',
              Code: 'Код',
              Delimiter: 'Разделитель',
              'Raw HTML': 'HTML-фрагмент',
              Table: 'Таблица',
              Link: 'Ссылка',
              Marker: 'Маркер',
              Bold: 'Полужирный',
              Italic: 'Курсив',
              InlineCode: 'Моноширинный'
            },

            tools: {
              warning: {
                Title: 'Название',
                Message: 'Сообщение'
              },

              link: {
                'Add a link': 'Вставьте ссылку'
              },
              stub: {
                'The block can not be displayed correctly.': 'Блок не может быть отображен'
              }
            },

            blockTunes: {
              delete: {
                Delete: 'Удалить'
              },
              moveUp: {
                'Move up': 'Переместить вверх'
              },
              moveDown: {
                'Move down': 'Переместить вниз'
              }
            }
          }
        }}
        defaultValue={defaultValue}
      />
    </Wrapper>
  );
}

Editorjs.defaultProps = {
  plugins: []
};

Editorjs.propTypes = {
  editorRef: PropTypes.object,
  defaultValue: PropTypes.object,
  lang: PropTypes.string,
  plugins: PropTypes.array
};

export default Editorjs;
