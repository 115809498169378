import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
`;

function Image({ children, className, ...rest }) {
  return (
    <Wrapper
      {...rest}
      className={className}
    >
      {children}
    </Wrapper>
  );
}

Image.defaultProps = {
  width: 'auto',
  height: '100px'
};

Image.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.any
};

export default styled(Image)``;
