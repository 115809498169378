import { css } from 'styled-components';

const defaults = (props) => css`
  .rc-switch {
    position: relative;
    display: inline-flex;
    width: 38px;
    height: 18px;
    padding: 0;
    background: #EAE9E4;
    border: none;
    border-radius: 70px;
    cursor: pointer;
    transition: all .3s cubic-bezier(0.35, 0, 0.25, 1);
    box-sizing: border-box;

    &-inner {
      color:#fff;
      font-size: 12px;
      position: absolute;
      left: 24px;
      top: 0;
    }

    &:after{
      position: absolute;
      width: 22px;
      height: 22px;
      left: 0px;
      top: -2px;
      border-radius: 50% 50%;
      background: #D2D1CC;
      content: " ";
      cursor: pointer;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
      transform: scale(1);
      transition: left .3s cubic-bezier(0.35, 0, 0.25, 1);
      animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
      animation-duration: .3s;
      animation-name: rcSwitchOff;
    }

    &:hover:after {
      transform: scale(1.1);
      animation-name: rcSwitchOn;
    }

    &:focus {
      outline: none;
    }

    &-checked {
      background: #DCEED1;

      .rc-switch-inner {
        left: 6px;
      }

      &:after{
        left: 16px;
        background: #52AB18;
      }
    }

    &-disabled{
      cursor: no-drop;
      background: #EAE9E4;

      &:after{
        background: #D2D1CC;
        animation-name: none;
        cursor: no-drop;
      }

      &:hover:after{
        transform: scale(1);
        animation-name: none;
      }
    }

    &-label {
      display: inline-block;
      line-height: 20px;
      font-size: 14px;
      padding-left: 10px;
      vertical-align: middle;
      white-space: normal;
      pointer-events: none;
      user-select: text;
    }
  }

  @keyframes rcSwitchOn {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.25);
    }
    100% {
      transform: scale(1.1);
    }
  }

  @keyframes rcSwitchOff {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const theme = (props) => ([
  defaults(props)
]);

export default theme;
