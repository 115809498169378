import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {},
  breadcrumbs: {
    marginBottom: 20
  },

  anchor: {
    lineHeight: '24px'
  },

  main: {
    flexGrow: 1
  },

  topbar: {
    flexGrow: 0,
    flexShrink: 0
  },

  actions: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end'
  },

  button: {
    background: '#52AB18'
  },

  loader: {
    height: '100vh'
  },
  
  line: {
    border: '1px solid #D9D9D9',
    width: '100%',
    marginTop: 12
  },

  content: {
    flexGrow: 1
  },

  blockContainer: {
    marginTop: 20
  },

  container: {
    marginLeft: 30,
    padding: '33px 25px',
    boxSizing: 'border-box',
    background: '#fff'
  }
}));

export default useStyles;
