import { RootState } from 'store';
import { IArticleResponse, SHOW_TYPE, STATUSES } from 'types';
import _orderBy from 'lodash/orderBy';

export const articlesAllSelector = (state: RootState): [STATUSES, IArticleResponse[], SHOW_TYPE] => {
  return [
    state.articles.state,
    state.articles.articles,
    state.articles.type
  ];
};
