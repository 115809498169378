import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MenuItem from './MenuItem';

const Wrapper = styled.span`
  display: flex;
  flex-direction: column;
  background-color: #2f343a;
`;

function Menu({ data, className }) {
  return (
    <Wrapper
      className={className}
    >
      {data.map((menu, index) => <MenuItem data={menu} key={index} />)}
    </Wrapper>
  );
}

Menu.defaultProps = {
  className: '',
  data: {}
};

Menu.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

export default styled(Menu)``;
