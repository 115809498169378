import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import settings from './Editor.settings';

const TextArea = styled.textarea`
`;

const Wrapper = styled.div`
  position: relative;
  width: 820px;
  padding: 32px 0;
`;

function Editor({ content, contentRef, ArticleEditor, className, ...rest }) {
  const wrapperRef = useRef();

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }

    const editorInstance = new ArticleEditor(
      wrapperRef.current,
      {
        ...settings(rest),
        content: content
      }
    );

    contentRef.current = editorInstance;

    return () => {
      try {
        editorInstance.destroy();
      } catch {
        return undefined;
      }
    };
  }, []);

  return (
    <Wrapper className={className}>
      <TextArea ref={wrapperRef} />
    </Wrapper>
  );
}

Editor.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string.isRequired,
  autoSaveUrl: PropTypes.string.isRequired,
  uploadImgUrl: PropTypes.string.isRequired,
  selectImgUrl: PropTypes.string.isRequired,
  contentRef: PropTypes.any,
  ArticleEditor: PropTypes.func.isRequired
};

export default styled(Editor)``;
