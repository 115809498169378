import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { getImage } from 'Utils/getImage';

import Top from './Top';
import Bottom from './Bottom';
import useHotelPrice from 'hooks/useHotelPrice';

function HotelCard({ data: { hotel } }) {
  const [{ price, isPending }, { fetch }] = useHotelPrice(hotel);

  if (!hotel.id) return;

  useEffect(() => {
    !isPending && !price && fetch();
  }, [isPending, price]);

  const img = getImage(hotel.images[0].encoded_url, { width: 960, height: 456 });

  return (
    <div>
      <div className='hotelCard_wrapper'>
        <Top hotel={hotel} />

        <img className='hotelCard_image' src={img.defaultSrc} />

        <Bottom hotel={hotel} price={price} isPending={isPending} />
      </div>
    </div>
  );
}

HotelCard.propTypes = {
  data: PropTypes.object
};

export default HotelCard;
