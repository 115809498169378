import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { Outlet, useParams } from 'react-router-dom';
import { Text, Breadcrumbs, Anchor, Box, Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import getCancellationRules from 'utils/getCancellationRules';

import InfoBlock from './InfoBlock';
import Rates from './Rates';
import Periods from './Periods';

import { Main, TopBar, Content } from 'components/ui/Layout';

import useStyles from './Tariff.styles';

import { createParsingTariffAction, fetchParsingTariffsAction } from 'store/parsingSlice/actions';
import { parsingTariffsAllSelector } from 'store/parsingSlice/selectors';
import { STATUSES } from 'types';
import Loader from 'components/ui/Loader';
import RoomTypes from './RoomTypes';
import parsingRoomTypesFetchAction from 'store/parsingSlice/actions/parsingRoomTypesFetchAction';
import { parsingRoomTypesSelector } from 'store/parsingSlice/selectors/roomTypes';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

function SearchArea({ className }: Props) {
  const { t } = useTranslation();
  const [roomType, setRoomType] = useState<any>();

  const types: any = {
    base: t('Tariffs.Base'),
    tour: t('Tariffs.Tour'),
    package: t('Tariffs.Package')
  };

  const { classes, cx } = useStyles();
  const { id, tariffId } = useParams();

  const dispatch = useAppDispatch();
  const [state, data, tariffState]: [STATUSES, any, STATUSES?] = useAppSelector(parsingTariffsAllSelector);
  const [roomTypesState, roomTypes]: [STATUSES, any] = useAppSelector(parsingRoomTypesSelector);

  useEffect(() => {
    id && dispatch(fetchParsingTariffsAction(id));
  }, [id]);

  useEffect(() => {
    data && dispatch(parsingRoomTypesFetchAction(data.place.place_id));
  }, [data]);

  const handleCreate = () => {
    dispatch(createParsingTariffAction({
      id: data.place.place_id.replace('hotel/', ''),
      tariff: { ...tariff, room_type: roomType }
    }
    ));
  };

  if (state !== STATUSES.FULFILLED) {
    return <Loader className={classes.loader} />;
  }

  const tariff = data.tariffs.find((tr: any) => tr._id === tariffId);

  const date: string = [
    DateTime.fromFormat(tariff.available_period.from, 'yyyy-MM-dd').toFormat('dd.MM.yyyy'),
    DateTime.fromFormat(tariff.available_period.to, 'yyyy-MM-dd').toFormat('dd.MM.yyyy')
  ].join(' - ');

  return (
    <Main className={cx(className, classes.root)}>
      <TopBar>
        <Text size={25} color='#000'>
          <Breadcrumbs
            separator={<Text size={25}>/</Text>}
          >
            <Anchor
              href='/parsing/'
            >
              {t('Parsing.Title')}
            </Anchor>

            <Anchor
              href={`/parsing/${id}/`}
            >
              {t('Tariffs.Title')}
            </Anchor>

            <Text>
              {tariff.name_translations.ru}
            </Text>
          </Breadcrumbs>

        </Text>

      </TopBar>

      <Content className={classes.content}>
        <Box className={classes.container}>
          <Text size={18} color='#339AF0'>
            {tariff.name_translations.ru}
          </Text>

          {tariff.available_period && (
            <Text size={18} color='#D2D5DA'>
              {date}
            </Text>
          )}

          <Box className={classes.line} />

          <Box className={classes.blockContainer}>
            <RoomTypes
              roomTypes={roomTypes}
              roomTypesState={roomTypesState}
              onSelect={setRoomType}
              roomType={roomType}
            />
          </Box>

          <Box className={classes.blockContainer}>
            <InfoBlock
              label={t('Tariffs.RoomTypes.TL.Title')}
              description={tariff.room_type_object.name_translations.ru}
            />
          </Box>

          <Box className={classes.blockContainer}>
            <InfoBlock
              label={t('Tariffs.Status.Label')}
              description={t('Tariffs.Status.New')}
            />
          </Box>

          <Box className={classes.blockContainer}>
            <InfoBlock
              label={t('Tariffs.Type.Label')}
              description={types[tariff.type]}
            />
          </Box>

          <Box className={classes.blockContainer}>
            <InfoBlock
              label={t('Common.Description')}
              description={tariff.description_translations.ru}
            />
          </Box>

          <Box className={classes.blockContainer}>
            <InfoBlock
              label={t('Tariffs.AvailablePeriod.Label')}
              description={date}
            />
          </Box>

          <Box className={classes.blockContainer}>
            <InfoBlock
              label={t('Tariffs.Cancellation.Label')}
              description={getCancellationRules(tariff)}
            />
          </Box>

          <Box className={classes.blockContainer}>
            <Rates
              rates={tariff.rates}
            />
          </Box>

          <Box className={classes.blockContainer}>
            <Periods
              periods={tariff.periods}
            />
          </Box>

          <Box className={classes.actions}>
            <Button
              className={classes.button}
              onClick={handleCreate}
              loading={tariffState === STATUSES.PENDING}
              disabled={!roomType}
            >
              {t('Action.Save')}
            </Button>
          </Box>
        </Box>
      </Content>

      <Outlet />
    </Main>
  );
}

export default SearchArea;
