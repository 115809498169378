import React from 'react';
import { Box } from '@mantine/core';

import useStyles from './Treatments.styles';

import CommonPart from '../CommonPart';
import Treatment from './Treatment';

type Props = {
  className?: string,
  hotel: any
}

function Treatments({ className, hotel }: Props) {
  const specializationElements = hotel.specializations.map((treatment: any, index: number) => <Treatment key={index} treatment={treatment} />);

  const isColumn: boolean = specializationElements.length > 1;
  const { classes, cx } = useStyles({ isColumn });

  return (
    <Box className={cx(className, classes.root)}>
      <CommonPart>

        <Box
          className={classes.container}
        >
          {specializationElements}
        </Box>
      </CommonPart>
    </Box>
  );
}

export default Treatments;
