import React from 'react';
import PropTypes from 'prop-types';

import HotelCard from './HotelCard';
import Region from './Region';
import Header from './Header';
import Paragraph from './Paragraph';
import Image from './Image';
import HotelCardSmall from './HotelCardSmall';

function Group({ group, region, ...rest }) {
  switch (group.type) {
    case 'regionCard':
      return <Region data={group.data} region={region} {...rest} />;

    case 'header':
      return <Header data={group.data} region={region} {...rest} />;

    case 'hotelCard': // +
      return <HotelCard data={group.data} region={region} {...rest} />;

    case 'paragraph':
      return <Paragraph data={group.data} region={region} {...rest} />;

    case 'image':
      return <Image data={group.data} region={region} {...rest} />;

    case 'hotelCardSmall':
      return <HotelCardSmall data={group.data} region={region} {...rest} />;

    default:
      return <div />;
  }
}

Group.propTypes = {
  className: PropTypes.string,
  group: PropTypes.object,
  region: PropTypes.object
};

Group.defaultProps = {
  region: {
    name: 'Тестовый регион'
  }
};

export default Group;
