import connection from 'connection/instance';

class SourcesApi {
  async fetchList() {
    try {
      const { data } = await connection.get('/api/filters/sources');

      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }
}

export default new SourcesApi();
