import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useNavigate, Outlet } from 'react-router-dom';
import { Text, Table, Button, Menu, Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Dots } from 'tabler-icons-react';
import { DateTime } from 'luxon';

import Loader from 'components/ui/Loader';

import RegionSelect from 'components/ui/RegionSelect';

import { Main, TopBar, Content } from 'components/ui/Layout';

import useStyles from './List.styles';

import { IObjectResponse, IRegionDocument, STATUSES } from 'types';
import { fetchObjectAction, fetchObjectMoreAction } from 'store/objectsSlice/actions';
import { objectsAllSelector } from 'store/objectsSlice/selectors';
import { getProviderTranslate } from './getProviderTranslate';

const format = 'dd-MM-yyyy HH:mm';

const site = process.env.REACT_APP_SITE;

type Props = React.PropsWithChildren<{
  className?: string;
}>;

function SearchArea({ className }: Props) {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const [page, setPage] = useState(2);

  const dispatch = useAppDispatch();
  const [state, objects, stateMore]: [STATUSES, IObjectResponse[], string] = useAppSelector(objectsAllSelector);

  useEffect(() => {
    dispatch(fetchObjectAction(1));
  }, []);

  const handleEdit = (object: IObjectResponse) => () => {
    navigate(`/objects/${object.id}/edit`);
  };

  if (state !== STATUSES.FULFILLED) {
    return <Loader className={classes.loader} />;
  }

  const handleCheck = (object: IObjectResponse) => () => {
    window.open(`${site}/hotel/${object.slug}`);
  };

  const handleScroll = (e: any) => {
    const scrollHeight = e.currentTarget.scrollHeight;
    const scrollTop = e.currentTarget.scrollTop;
    const clientHeight = e.currentTarget.clientHeight;

    if (scrollHeight - (scrollTop + clientHeight) < 200) {
      if (stateMore !== STATUSES.PENDING) {
        setPage(page + 1);

        dispatch(fetchObjectMoreAction(page));
      }
    }
  };

  const handleSelect = (hotelObj: IRegionDocument) => {
    navigate(`/objects/${hotelObj?.place_id}/edit`);
  };

  const rows = objects.map((element) => (
    <tr key={element.id}>
      <td className={classes.td}>{element.name}</td>
      <td className={classes.td}>{element.address.location}</td>
      <td className={classes.td}>
        {DateTime.fromISO(element.created_at).isValid ? DateTime.fromISO(element.created_at).toFormat(format) : null}
      </td>
      <td className={classes.td}>{DateTime.fromISO(element.updated_at).toFormat(format)}</td>
      <td className={classes.td}>{getProviderTranslate(element.provider)}</td>
      <td className={classes.td}>
        <Box className={classes.actionsTd}>
          <Button className={classes.button} onClick={handleEdit(element)}>
            Редактировать
          </Button>

          <Menu>
            <Menu.Target>
              <Box className={classes.target}>
                <Dots />
              </Box>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item onClick={handleCheck(element)}>Посмотреть на сайте</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Box>
      </td>
    </tr>
  ));

  return (
    <Main onScroll={handleScroll} className={cx(className, classes.root)}>
      <TopBar>
        <div className={classes.left_wrapper}>
          <Text size={40} color='#000'>
            {t('Objects.Title')}
          </Text>

          <RegionSelect placeholder={t('SearchArea.Objects.Placeholder')} onSelect={handleSelect} type='hotels' />
        </div>
        <Button onClick={() => navigate('/objects/new')} className={classes.button_green}>
          {t('Action.AddObject')}
        </Button>
      </TopBar>

      <Content className={classes.content}>
        <Table verticalSpacing='xl' horizontalSpacing='xl' className={classes.table} fontSize='md'>
          <thead>
            <tr className={classes.tr}>
              <th className={classes.th}>{t('Objects.Table.Field1')}</th>
              <th className={classes.th}>{t('Objects.Table.Field2')}</th>
              <th className={classes.th}>{t('Objects.Table.Field3')}</th>
              <th className={classes.th}>{t('Objects.Table.Field4')}</th>
              <th className={classes.th}>{t('Objects.Table.Field5')}</th>
              <th className={classes.th} />
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        {stateMore === STATUSES.PENDING && (
          <Text align='center' weight='bold' size='lg' transform='uppercase'>
            Loading...
          </Text>
        )}
      </Content>

      <Outlet />
    </Main>
  );
}

export default SearchArea;
