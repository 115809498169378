import React from 'react';

import { useTranslation } from 'react-i18next';

import useStyles from './Content.styles';
import { Box } from '@mantine/core';

type Props = {
  className?: string,
  program: any
}

function Content({ className, program }: Props) {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  const includes = program.includes.map((pr: any, index: number) => <li key={index}>{pr}</li>);

  return (
    <Box className={cx(className, classes.root)}>
      <Box className={classes.duration}>
        {t('Common.Duration', { ...program })}
      </Box>

      <Box className={classes.description} dangerouslySetInnerHTML={{ __html: program.description }} />

      {program.includes.length !== 0 && (
        <Box className={classes.listTitle}>
          {t('HotelPage.TreatmentPrograms.IncludedText')}
        </Box>
      )}

      <Box className={classes.programContent}>
        <ul>
          {includes}
        </ul>
      </Box>
    </Box>
  );
}

export default Content;
