import * as yup from 'yup';

export const EMAIL = 'email';
export const SOURCES = 'sources';
export const NUMBER = 'number';
export const COMMISSION = 'commission_in_percent';
export const DATE = 'from_date';
export const CONTRACTS = 'contracts';

export const sanatoryProviderSchema = yup.object().shape({
  [EMAIL]: yup.string().email('Введите корректный e-mail').required('Обязательное поле'),
  [SOURCES]: yup.array().of(
    yup.object().shape({
      [CONTRACTS]: yup.array().of(
        yup.object().shape({
          [NUMBER]: yup.string().required('Обязательное поле'),
          [DATE]: yup.string().required('Обязательное поле'),
          [COMMISSION]: yup.number().required('Обязательное поле').min(0, 'Значение должно быть не менее 0')
            .max(100, 'Значение должно быть не более 100')
        })
      )
    })
  )

});
