import connection from 'connection/instance';
import { AxiosResponse } from 'axios';
import { IRegionRequest, IRegionResponse } from 'types';

export const regionFetch = async (q?: string): Promise<IRegionResponse> => {
  const params: IRegionRequest = {
    q: q?.toLowerCase(),
    query_by: 'index_strings',
    sort_by: '_text_match:desc',
    num_typos: 1
  };

  const headers = {
    'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY
  };

  const response: AxiosResponse<IRegionResponse> = await connection.get(
    '/api/ts/collections/regions/documents/search',
    { params, headers }
  );

  return response.data;
};
