import { createStyles } from '@mantine/styles';

type Props = {
  hideEnContent: boolean
}

const useStyles = createStyles((theme, { hideEnContent }: Props, getRef) => ({
  root: {
    width: '100%',
    overflow: 'auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: '100vh',
    background: 'rgb(234, 233, 228)'
  },
  topContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  content: {
    background: '#EAE9E4 !important',
    display: 'flex',
    margin: '20px auto 0',
    justifyContent: hideEnContent ? 'center' : 'space-between'
  },
  editorContainer: {
    ref: getRef('editorContainer'),
    position: 'relative',
    minWidth: hideEnContent ? '80%' : '47%',
    maxWidth: hideEnContent ? '80%' : '47%',

    [`+.${getRef('editorContainer')}`]: {
      marginLeft: 10,
      background: '#fff'
    }
  },
  main: {
    flexGrow: 1
  },
  icons: {
    position: 'sticky',
    top: '50%',
    height: 'min-content',
    margin: '0 6px'
  },
  iconRu: {
    transform: 'rotate(180deg)',
    fontSize: 25,
    cursor: 'pointer',
    display: 'block'
  },
  iconEn: {
    fontSize: 25,
    cursor: 'pointer',
    left: 'calc(50% - 14px)',
    display: 'block',
    marginTop: 10
  },
  loader: {
    height: '100vh'
  },
  arrow: {
    marginRight: 12
  },
  topbar: {
    flexGrow: 0,
    flexShrink: 0
  },
  iconContainer: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1
  },
  leftTopbarContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  rightTopbarContainer: {
    display: 'flex'
  },
  button: {
    ref: getRef('button'),

    [`+.${getRef('button')}`]: {
      marginLeft: 20
    }
  }
}));

export default useStyles;
