import React from 'react';
import { Box } from '@mantine/core';

import useStyles from './Treatment.styles';

type Props = {
  className?: string,
  treatment: any
}

function Treatment({ className, treatment }: Props) {
  const { cx, classes } = useStyles();

  return (
    <Box className={cx(className, classes.root)}>
      <Box className={classes.container}>
        {treatment.name}
      </Box>
    </Box>
  );
}

export default Treatment;
