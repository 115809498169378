import React from 'react';
import { Text, Box } from '@mantine/core';

import useStyles from './Rate.styles';
import { useTranslation } from 'react-i18next';


type Props = React.PropsWithChildren<{
  className?: string;
  rate: any
}>;

function Rate({ className, rate }: Props) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={cx(classes.rate, className)}>
      <Box className={classes.leftRate}>
        <Text size={14} color='#999999'>
          {t(`Tariffs.Rates.Description`, { ageGroup: rate.age_group, ...rate.age_period, ...rate })}
        </Text>
      </Box>

      <Box className={classes.rightRate}>
        <Text size={14} color='#999999'>
          {rate.price.amount} ₽
        </Text>
      </Box>
    </Box>
  );
}

export default Rate;
