
import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {

  },

  content: {
    marginTop: 17
  }

}));

export default useStyles;
