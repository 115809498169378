
import { createStyles } from '@mantine/styles';

type Props = {
  opened: boolean
}

const useStyles = createStyles((theme, { opened }: Props, getRef) => ({
  root: {
    width: '100%',
    background: opened ? '#EEEEF3' : '#F5F5F7',
    borderRadius: 4
  },

  icon: {
    transform: `rotate(${opened ? 180 : 0}deg)`
  },

  title: {
    padding: '15px 20px',
    boxSizing: 'border-box',
    width: '100%',
    fontSize: 18,
    lineHeight: '21px',
    color: '#323232',
    cursor: 'pointer',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    userSelect: 'none',
    alignItems: 'center'
  },
  content: {
    marginTop: 28
  },
  dropdown: {
    ref: getRef('dropdown'),

    [`+.${getRef('dropdown')}`]: {
      marginTop: 20
    }
  }

}));

export default useStyles;
