import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Breadcrumbs from 'components/ui/Breadcrumbs';
import Header from 'components/ui/Header';
import Button from 'components/ui/Button';
import ButtonGroup, { LeftGroup, RightGroup } from 'components/ui/ButtonGroup';

const Wrapper = styled(ButtonGroup)`
  width: 100%;

  ${Header} {
    max-width: 520px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ${LeftGroup} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  ${Breadcrumbs} + ${Header} {
    margin-top: 8px;
  }
`;

function Top({ reviewer, onSubmit, onReset, className }) {
  const links = [
    { name: 'Авторы', href: '/reviewers' },
    { name: 'Редактирование автора' }
  ];

  return (
    <Wrapper className={className}>
      <LeftGroup>
        <Breadcrumbs links={links} />
        <Header level={3}>
          {reviewer.first_name}
        </Header>
      </LeftGroup>
      <RightGroup>
        <Button
          variant='secondary'
          onClick={onReset}
        >
          Отменить
        </Button>
        <Button
          onClick={onSubmit}
        >
          Сохранить
        </Button>
      </RightGroup>
    </Wrapper>
  );
}

Top.defaultProps = {
  onSubmit: () => {},
  onReset: () => {}
};

Top.propTypes = {
  className: PropTypes.string,
  reviewer: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func
};

export default styled(Top)``;
