import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Number = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #bdbdbd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background: #f5f5f7;
  top: 50%;
  transform: translateY(-50%);
  left: -52px;
  justify-content: center;
  position: absolute;
  width: 28px;
  height: 28px;
`;

const Title = styled.div``;

const Wrapper = styled.a`
  padding-left: 0;
  margin-top: 6px;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  padding: 0;
  font-family: GTEesti;
  display: block;
  text-decoration: none;
  color: #2e7cd6;
  position: relative;
  cursor: pointer;
`;

function Element({ title, href, className, index }) {
  return (
    <Wrapper href={href} className={className} data-counter>
      <Title dangerouslySetInnerHTML={{ __html: title }} />

      <Number>
        {index}
      </Number>
    </Wrapper>
  );
}

Element.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  index: PropTypes.number
};

export default styled(Element)``;
