import React from 'react';
import { Box, Text } from '@mantine/core';

import Content from './Content';
import { Triangle } from 'tabler-icons-react';

import useStyles from './Dropdown.styles';

type Props = {
  className?: string,
  program: any,
  onClick: () => void,
  opened: boolean
}

function Dropdown({ className, program, onClick, opened }: Props) {
  const { classes, cx } = useStyles({ opened });

  return (
    <Box
      className={cx(className, classes.root)}
    >
      <Box className={classes.title} onClick={onClick}>
        <Text dangerouslySetInnerHTML={{ __html: program.title }} />

        <Triangle className={classes.icon} size={10} fill='#999999' />
      </Box>

      {opened && (
        <Box>
          <Content program={program} />
        </Box>
      )}
    </Box>
  );
}

export default Dropdown;
