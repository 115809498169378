import { css } from 'styled-components';

const transition = (props) => css`
  transition: all .2s ease-in-out;
`;

const size = ({ size }) => {
  if (size === 'medium') {
    return css`
      height: 32px;
      padding: 0 12px;
      font-size: 14px;
      border-radius: 4px;
    `;
  }

  if (size === 'large') {
    return css`
      height: 42px;
      padding: 0 24px;
      font-size: 14px;
      border-radius: 4px;
    `;
  }

  return css``;
};

const variant = ({ variant, loading }) => {
  if (variant === 'primary') {
    return css`
      color: #FFFFFF;
      background: #52AB18;

      &:hover {
        background-color: #428616;
      }

      &:active {
        background-color: #366C13;
      }
    `;
  }

  if (variant === 'secondary') {
    return css`
      color: #333333;
      background: #D2D1CC;

      &:hover {
        color: currentColor;
        background: #C4C2BB;
      }

      &:active {
        color: currentColor;
        background: #B8B6AA;
      }
    `;
  }

  if (variant === 'inverted') {
    return css`
      color: #FFFFFF;
      background: rgba(210, 209, 204, 0.1);

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      &:active {
        background: rgba(255, 255, 255, 0.1);
      }
    `;
  }

  if (variant === 'danger') {
    return css`
      color: #FFFFFF;
      background-color: #E16868;

      &:hover {
        background-color: #BE4040;
      }

      &:active {
        background-color: #9D2F2F;
      }
    `;
  }

  return css``;
};

const outline = ({ variant, loading }) => {
  if (variant === 'primary') {
    return css`
      color: #52AB18;
      background: #FFFFFF;
      border: 1px solid currentColor;

      &:hover {
        color: #428616;
      }

      &:active {
        color: #366C13;
      }
    `;
  }

  if (variant === 'secondary') {
    return css`
      color: #999999;
      background: #FFFFFF;
      border: 1px solid currentColor;

      &:hover {
        color: #828282;
      }

      &:active {
        color: #777777;
      }
    `;
  }

  if (variant === 'inverted') {
    return css`
      color: #FFFFFF;
      background: rgba(210, 209, 204, 0.1);

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      &:active {
        background: rgba(255, 255, 255, 0.1);
      }
    `;
  }

  if (variant === 'danger') {
    return css`
      color: #E16868;
      background-color: #FFFFFF;
      border: 1px solid currentColor;

      &:hover {
        color: #BE4040;
      }

      &:active {
        color: #9D2F2F;
      }
    `;
  }

  return css``;
};

const compact = ({ variant, loading }) => {
  if (variant === 'primary') {
    return css`
      padding: 0;
      color: #52AB18;

      &:hover {
        color: #428616;
      }

      &:active {
        color: #366C13;
      }
    `;
  }

  if (variant === 'secondary') {
    return css`
      padding: 0;
      color: #999999;

      &:hover {
        color: #828282;
      }

      &:active {
        color: #777777;
      }
    `;
  }

  if (variant === 'inverted') {
    return css`
      color: #FFFFFF;
      background: rgba(210, 209, 204, 0.1);

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      &:active {
        background: rgba(255, 255, 255, 0.1);
      }
    `;
  }

  if (variant === 'danger') {
    return css`
      padding: 0;
      color: #E16868;

      &:hover {
        color: #BE4040;
      }

      &:active {
        color: #9D2F2F;
      }
    `;
  }

  if (variant === 'info') {
    return css`
      padding: 0;
      color: #1F6FCC;
      background-color: #FFFFFF;

      &:hover {
        color: #184881;
      }

      &:active {
        color: #094fa2;
      }
    `;
  }

  return css``;
};

const disabled = (props) => css`
  pointer-events: none;
  opacity: 0.5;
`;

const loading = ({ loading }) => css`
  pointer-events: none;

  & > * {
    opacity: 0;
  }
`;

const defaults = (props) => css`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  outline: none;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
`;

const theme = (props) => ([
  defaults(props),
  transition(props),
  size(props),
  (props.outline ? outline(props) : css``),
  (props.compact ? compact(props) : css``),
  (props.disabled ? disabled(props) : css``),
  (props.loading ? loading(props) : css``),
  (!props.compact && !props.outline ? variant(props) : css``)
]);

export default theme;
