import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

function Row({ className, children }) {
  return (
    <Container
      className={className}
    >
      {children}
    </Container>
  );
}

Row.defaultProps = {
  className: ''
};

Row.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default styled(Row)``;
