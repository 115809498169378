import React from 'react';
import { Box, Text } from '@mantine/core';

import SelectedRegion from './SelectedRegion';

import useStyles from './RegionSelect.styles';

import { IRegionDocument } from 'types';

import RegionSelect from 'components/ui/RegionSelect';

type Object = {
  area_id: string;
  type: string;
  name: string;
  radius: number;
};

type Props = React.PropsWithChildren<{
  className?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSelect: ({ area_id, type, name }: Object) => void;
  onChangeRadius: (radius: number, index: number) => void;
  onDelete: (id: string) => void;
  searchAdditionals: any[];
  disabled: boolean;
}>;

function SearchArea({ className, onSelect, onDelete, searchAdditionals, onChangeRadius, disabled }: Props) {
  const { classes, cx } = useStyles();

  const handleSelect = async (regionObj: IRegionDocument) => {
    regionObj &&
      onSelect({
        type: 'region',
        area_id: String(regionObj.place_id),
        name: regionObj.label_ru,
        radius: 50
      });
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const selectedRegionComponents = searchAdditionals
    .filter(({ type }) => type === 'region')
    // eslint-disable-next-line @typescript-eslint/ban-types
    .map((region: Object, index) => (
      <SelectedRegion
        key={region.area_id}
        className={classes.region}
        region={region}
        handleDelete={onDelete}
        onChangeRadius={onChangeRadius}
        index={index}
        disabled={disabled}
      />
    ));

  return (
    <Box className={cx(className, classes.root)}>
      <Text>Показывать также объекты областей</Text>

      <RegionSelect
        onSelect={handleSelect}
        clearAfterSelect
      />

      <Box className={classes.regions}>{selectedRegionComponents}</Box>
    </Box>
  );
}

export default SearchArea;
