import React from 'react';
import { Box, Text } from '@mantine/core';
import { ThumbUp } from 'tabler-icons-react';

import useStyles from './BestReview.styles';
import { IObjectDataFull } from 'types';
import RatingElement from './RatingElement';

type Props = {
  className?: string,
  object: IObjectDataFull
}

function BestReview({ className, object }: Props) {
  const { classes, cx } = useStyles();

  if (!object.review) return <div />;

  const elements = object.review.best.categories.map((cat) => <RatingElement className={classes.element} element={cat} key={cat.name} />);

  return (
    <Box className={cx(className, classes.root)}>
      <Box className={classes.top}>
        <Text weight={600} size={32}>
          Отзывы
        </Text>

        {object.review && (
          <Text color='#3582db' size={32} weight={600}>
            {object.review.total}
          </Text>
        )}

        <Box className={classes.thumb}>
          <ThumbUp fill='#1bc47d' stroke='transparent' size={25} />

          <Text size={18} weight={700} color='#6dd098'>
            Отлично
          </Text>
        </Box>
      </Box>

      <Box className={classes.stat}>
        {elements}
      </Box>

      <Box className={classes.review}>
        <Box className={classes.reviewTop}>
          <Text size={20} weight={600}>
            {object.review.best.user_name}
          </Text>

          <Box className={classes.reviewRating}>
            <ThumbUp fill='#fff' stroke='transparent' size={16} />

            <Text className={classes.reviewRatingValue} color='#fff' weight={700}>
              {object.review.best.rating}
            </Text>
          </Box>
        </Box>

        <Text size={18} className={classes.reviewComment} dangerouslySetInnerHTML={{ __html: object.review.best.description }} />
      </Box>
    </Box>
  );
}
export default BestReview;
