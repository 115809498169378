import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ListIcon, BlocksIcon } from 'components/ui/Icons';

const IconContainer = styled.div`
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ active }) => active && css`
    font-size: 28px;
    background: #3b4149;

    svg {
      fill: #fff;
    }
  `}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${IconContainer} + ${IconContainer} {
    margin-left: 20px;
  }
`;

function Card({ className, type, onChange, types }) {
  const handleClick = (selectedType) => () => {
    onChange && onChange(selectedType);
  };

  const typesComponent = types.map((typeCurrent, index) => {
    return (
      <IconContainer
        key={index}
        active={typeCurrent.name === type}
        onClick={handleClick(typeCurrent.name)}
      >
        {typeCurrent.icon}
      </IconContainer>
    );
  });

  return (
    <Wrapper className={className}>
      {typesComponent}
    </Wrapper>
  );
}

Card.defaultProps = {
  type: 'default',
  types: [{
    name: 'default',
    icon: <BlocksIcon />
  },
  {
    name: 'list',
    icon: <ListIcon />
  }]
};

Card.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  types: PropTypes.array
};

export default styled(Card)``;
