const TAGS = [
  { tag: 'property', name: 'property' },
  { tag: 'name', name: 'name' },
  { tag: 'httpEquiv', name: 'httpEquiv' }
];

function useOptions() {
  return TAGS;
}

export default useOptions;
