import instance from '../connection/instance';

export const setDefaulsHeader = (data) => {
  if (!data) return;

  const { token } = data;
  instance.defaults.headers.common.Authorization = ['Bearer', token].join(' ');

  return data;
};
