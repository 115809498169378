import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    minHeight: '100vh',
    background: '#EAE9E4',
    overflow: 'auto'
  }
}));

export default useStyles;
