import React, { useState } from 'react';
import { Box } from '@mantine/core';

import useStyles from './Meal.styles';

import Dropdown from './Dropdown';
import CommonPart from '../CommonPart';

import { IObjectDataFull } from 'types';

type Props = {
  className?: string,
  object: IObjectDataFull,
  currentLanguage: string
}

function Meal({ className, object, currentLanguage }: Props) {
  const { classes, cx } = useStyles();
  const [openedProgramId, setOpenedProgramId] = useState<string | null>();

  const handleOpen = (id: string) => () => {
    if (openedProgramId === id) {
      setOpenedProgramId(null);
      return;
    }

    setOpenedProgramId(id);
  };

  const programs = object.body_translations[currentLanguage]?.blocks.find((b) => b.type === 'feeding_programs').data.list;

  const feedingComponents = programs.map((program: any) => (
    <Dropdown
      program={program}
      className={classes.dropdown}
      key={program.id}
      onClick={handleOpen(program.title)}
      opened={program.title === openedProgramId}
    />
  ));

  return (
    <Box className={cx(className, classes.root)}>
      <CommonPart>
        {feedingComponents}
      </CommonPart>
    </Box>
  );
}

export default Meal;
