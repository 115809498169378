import React from 'react';
import { Box } from '@mantine/core';

import CommonPart from '../CommonPart';
import { IObjectDataFull } from 'types';
import useStyles from './DescriptionChatGPT.styles';

type Props = {
  className?: string,
  object: IObjectDataFull,
  currentLanguage: string
}

function DescriptionChatGPT({ className, object, currentLanguage }: Props) {
  const description = object.body_translations[currentLanguage]?.blocks.find((b) => b.type === 'descriptionChatGPT').data;
  const { classes, cx } = useStyles();

  return (
    <Box className={cx(className, classes.root)}>
      <CommonPart>
        <Box
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: description?.about || description }}
        />
      </CommonPart>
    </Box>
  );
}

export default DescriptionChatGPT;
