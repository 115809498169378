import React, { useRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SelectElement, { Option } from 'rc-select';

// @ts-ignore
import Wrapper from './Select.styled';
import { SelectArrowIcon } from 'components/ui/Icons';

const dropdownAlign = {
  offset: [-13, 1]
};

const Select = React.forwardRef(({
  value: initialValue,
  placeholder,
  valueField,
  labelField,
  options,
  searchable,
  error,
  className,
  onChange,
  simpleValue,
  mode
}, ref) => {
  const wrapperRef = useRef();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(value);
  }, [initialValue]);

  const selectValue = useMemo(() => {
    if (value === null) return null;
    if (simpleValue && mode === 'multiple') return [...value];
    if (!simpleValue && mode === 'multiple') return value.map((item) => item[valueField]);
    if (simpleValue) return value;
    if (!simpleValue) return value[valueField];

    return null;
  }, [value, simpleValue]);

  const handleSelect = (newValue, option) => {
    const selectedOption = options.find((item) => item[valueField] === newValue);

    if (mode === 'multiple') {
      const multipleValue = simpleValue ? newValue : { ...selectedOption };
      const multipleValueArr = [...value, multipleValue];

      setValue(multipleValueArr);
      onChange(multipleValueArr);

      return;
    }

    const singleValue = simpleValue ? newValue : { ...selectedOption };

    setValue(singleValue);
    onChange(singleValue);
  };

  const handleDeselect = (deselectValue, option) => {
    if (mode === 'multiple') {
      const multipleValueArr = value.filter((item) => {
        if (simpleValue) {
          return deselectValue !== item;
        } else {
          return deselectValue !== item[valueField];
        }
      });

      setValue(multipleValueArr);
      onChange(multipleValueArr);
    }
  };

  return (
    <Wrapper
      ref={wrapperRef}
      isSearch={searchable}
      className={className}
      error={error}
    >
      <SelectElement
        getPopupContainer={() => wrapperRef.current}
        inputIcon={<SelectArrowIcon />}
        notFoundContent='Нет данных'
        onSelect={handleSelect}
        onDeselect={handleDeselect}
        placeholder={placeholder}
        showSearch={searchable}
        value={selectValue}
        dropdownAlign={dropdownAlign}
        mode={mode}
      >
        {options.map((option, index) => (
          <Option
            key={option[valueField] + index}
            title={option[labelField]}
            value={option[valueField]}
          >
            {option[labelField]}
          </Option>
        ))}
      </SelectElement>
    </Wrapper>
  );
});

Select.defaultProps = {
  value: null,
  placeholder: '',
  options: [],
  valueField: 'key',
  labelField: 'value',
  searchable: false,
  onChange: () => {},
  simpleValue: false
};

Select.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  valueField: PropTypes.string,
  labelField: PropTypes.string,
  labelField2: PropTypes.string,
  searchable: PropTypes.bool,
  onChange: PropTypes.func,
  simpleValue: PropTypes.bool,
  mode: PropTypes.oneOf(['single', 'multiple'])
};

export default styled(Select)``;
