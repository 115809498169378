import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from './Text.theme';

const Wrapper = styled.p`
  ${(props) => theme(props)}
`;

function Text({ children, variant, className }) {
  return (
    <Wrapper
      variant={variant}
      className={className}
    >
      {children}
    </Wrapper>
  );
}

Text.defaultProps = {
  varian: 'primary'
};

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  variant: PropTypes.oneOf(['success', 'error', 'warning', 'secondary', 'primary'])
};

export default styled(Text)``;
