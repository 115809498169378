import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Layout, { SideLeft } from 'components/ui/Layout';
import Menu from 'components/ui/Menu';
import Reviewers from 'pages/Reviewers';
import Regions from 'pages/Regions';
import Diseases from 'pages/Diseases';
import BlogNew from 'pages/BlogNew';
import Objects from 'pages/Objects';
import Reports from 'pages/Reports';
import SearchArea from 'pages/SearchArea';
import ParsingTariffs from 'pages/ParsingTariffs';

import menu from './menu';

import Login from 'pages/Auth';
import PrivateRoute from 'components/PrivateRoute';
import { useAppSelector, useAppDispatch } from 'store';
import { loginSelector } from 'store/authSlice/selectors';
import { readFromLocalStorage } from 'utils/storage';
import { authenticateAction } from 'store/authSlice/actions';

export const ToastifyWrapper = styled(ToastContainer)`
  max-width: 642px;
  width: 100%;
  padding: 0;
  .Toastify__toast--error {
    background: #dd6369;
    padding: 21px 22px;
    border-radius: 5px;
    align-items: center;
  }

  .Toastify__toast-body {
    font-size: 18px;
    line-height: 22px;
  }
  .Toastify__toast--success {
    background: #52ab18;
    padding: 13px 22px;
    border-radius: 5px;
    align-items: center;
  }
`;

function App() {
  const { isAuthenticated } = useAppSelector(loginSelector);
  const dispatch = useAppDispatch();
  const currentUser = readFromLocalStorage('user');

  const authProps = { isAuthenticated, currentUser };

  useEffect(() => {
    dispatch(authenticateAction());
  }, [dispatch]);

  return (
    <Layout>
      <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.css' />

      <BrowserRouter>
        {isAuthenticated && (
          <SideLeft>
            <Menu data={menu} />
          </SideLeft>
        )}

        <Routes>
          {!isAuthenticated && <Route path='/' element={<Login />} />}

          <Route
            path='/reviewers/*'
            element={<PrivateRoute {...authProps}>{(props) => <Reviewers {...props} />}</PrivateRoute>}
          />

          <Route
            path='/search_area/*'
            element={<PrivateRoute {...authProps}>{(props) => <SearchArea {...props} />}</PrivateRoute>}
          />

          <Route
            path='/regions/*'
            element={<PrivateRoute {...authProps}>{(props) => <Regions {...props} />}</PrivateRoute>}
          />

          <Route
            path='/diseases/*'
            element={<PrivateRoute {...authProps}>{(props) => <Diseases {...props} />}</PrivateRoute>}
          />

          <Route
            path='/parsing/*'
            element={<PrivateRoute {...authProps}>{(props) => <ParsingTariffs {...props} />}</PrivateRoute>}
          />

          <Route
            path='/articles/*'
            element={<PrivateRoute {...authProps}>{(props) => <BlogNew {...props} />}</PrivateRoute>}
          />

          <Route
            path='/objects/*'
            element={<PrivateRoute {...authProps}>{(props) => <Objects {...props} />}</PrivateRoute>}
          />

          <Route
            path='/reports/*'
            element={<PrivateRoute {...authProps}>{(props) => <Reports {...props} />}</PrivateRoute>}
          />

          <Route path='/login/*' element={<Login />} />

          <Route path='*' element={<Navigate to='/articles' replace />} />
        </Routes>
      </BrowserRouter>
      <ToastifyWrapper>
        <ToastContainer />
      </ToastifyWrapper>
    </Layout>
  );
}

export default App;
