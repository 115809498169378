import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Input = styled.input`
  width: 100%;
  ${({ error }) => error && css`
    border: 1px solid #FF5656;
  `}
`;

const DatePicker = React.forwardRef(({ className, value: initialValue, onChange, error }, ref) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (ev) => {
    setValue(ev.target.value);
    onChange && onChange(ev.target.value);
  };

  return (
    <Input
      error={error}
      value={value}
      className={className}
      type='date'
      onChange={handleChange}
    />
  );
});

DatePicker.defaultProps = {
  className: ''
};

DatePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export default styled(DatePicker)``;
