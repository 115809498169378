import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MenuItem from './Item';

const Text = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 46px;
  padding: 0px 15px;
`;

const Wrapper = styled.span`
  display: flex;
  flex-direction: column;
  background-color: #2f343a;
`;

function Menu({ data, className }) {
  return (
    <Wrapper
      className={className}
    >
      <Text>{data.label}</Text>

      {data.items.map((item, index) => (
        <MenuItem
          key={index}
          menuItem={item}
        />
      ))}
    </Wrapper>
  );
}

Menu.defaultProps = {
  className: '',
  data: {}
};

Menu.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

export default styled(Menu)``;
