import React from 'react';
import { Box, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import SelectedRegion from '../RegionSelect/SelectedRegion';

import useStyles from './HotelRegionSelect.styles';

import RegionSelect from 'components/ui/RegionSelect';

import { IRegionDocument } from 'types';

type Object = {
  area_id: string;
  type: string;
  name: string;
};

type Props = React.PropsWithChildren<{
  className?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSelect: ({ area_id, type, name }: Object) => void;
  onChangeRadius: (radius: number, index: number) => void;
  onDelete: (id: string) => void;
  searchAdditionals: any[];
}>;

function HotelSearchArea({ className, onSelect, onDelete, searchAdditionals, onChangeRadius }: Props) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const handleSelect = async (regionObj: IRegionDocument) => {
    regionObj &&
      onSelect({
        type: 'hotels',
        area_id: String(regionObj.place_id),
        name: regionObj.label_ru
      });
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const selectedRegionComponents = searchAdditionals
    .filter(({ type }) => type === 'hotels')
    // eslint-disable-next-line @typescript-eslint/ban-types
    .map((region: Object, index) => (
      <SelectedRegion
        key={region.area_id}
        className={classes.region}
        region={region}
        handleDelete={onDelete}
        searchWithHotels
        index={index}
        onChangeRadius={onChangeRadius}
      />
    ));

  return (
    <Box className={cx(className, classes.root)}>
      <Text>Показывать также объекты размещения</Text>

      <RegionSelect
        onSelect={handleSelect}
        clearAfterSelect
        type='hotels'
        placeholder={t('Common.Name.Placeholder')}
      />

      <Box className={classes.regions}>{selectedRegionComponents}</Box>
    </Box>
  );
}

export default HotelSearchArea;
