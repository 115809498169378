import { startAppListening } from '../../listenerMiddleware';

import { toastify } from 'utils/toatify';

import { loginAction } from '../actions';

startAppListening({
  actionCreator: loginAction.rejected,
  effect: async (action, listenerApi) => {
    toastify('error', 'Не удалось войти в систему');
  }
});

startAppListening({
  actionCreator: loginAction.fulfilled,
  effect: async (action, listenerApi) => {
    toastify('success', 'Вход выполнен');
  }
});
