import React from 'react';
import { Box, Text } from '@mantine/core';

import { RussiaIcon, UnitedKingdomIcon } from 'components/ui/Icons';
import { Content } from 'components/ui/Layout';
import Editor from 'components/forms/EditorJs/Editorjs';
import Preview from './Preview';
import useStyles from '../Edit.styles';
import { IObjectData } from '../../../../types';

const plugins = [
  'programs',
  'newYear',
  'blogCard',
  'documents',
  'stars',
  'description',
  'descriptionChatGPT',
  'comments',
  'distances',
  'procedures',
  'feeding_programs'
];

type EditContentProps = {
  hotel: any;
  fetchHotel: (lang: string) => void;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  opened: boolean;
  hiddenContent: string;
  handleClickHiddenAction: (name: string) => () => void;
  editorRefRu: React.RefObject<any>;
  shouldUpdateRu: boolean;
  object: IObjectData;
  editorRefEn: React.RefObject<any>;
  shouldUpdateEn: boolean;
}

function EditContent({ hotel, fetchHotel, setOpened, opened, hiddenContent, handleClickHiddenAction, editorRefRu, shouldUpdateRu, object, editorRefEn, shouldUpdateEn }:EditContentProps) {
  const { classes, cx } = useStyles({ hiddenContent });
  return (

    <>
      <Box className={classes.header}>
        <Box />

        <Text size={24} align='center' className={classes.headerTitle}>
          {hotel.name}
        </Text>

        <Box className={classes.flags}>
          <Box
            className={cx(classes.flagsBlock, hiddenContent === 'en' && classes.activeFlag)}
            onClick={handleClickHiddenAction('en')}
          >
            <RussiaIcon />
          </Box>

          <Box
            className={cx(classes.flagsBlock, hiddenContent === 'ru' && classes.activeFlag)}
            onClick={handleClickHiddenAction('ru')}
          >
            <UnitedKingdomIcon />
          </Box>

          <Box
            className={cx(classes.flagsBlock, !hiddenContent && classes.activeFlag)}
            onClick={handleClickHiddenAction('')}
          >
            <RussiaIcon />

            <UnitedKingdomIcon />
          </Box>
        </Box>
      </Box>

      <Content className={classes.content}>
        {hiddenContent !== 'ru' && (
          <Box className={classes.editorContainer}>
            <Box className={classes.iconContainer}>
              <RussiaIcon />
            </Box>

            <Editor
              editorRef={editorRefRu}
              lang='ru'
              plugins={plugins}
              key={String(shouldUpdateRu)}
              defaultValue={object.content.ru}
            />
          </Box>
        )}

        {hiddenContent !== 'en' && (
          <Box className={classes.editorContainer}>
            <Box className={classes.iconContainer}>
              <UnitedKingdomIcon />
            </Box>

            <Editor
              editorRef={editorRefEn}
              lang='en'
              plugins={plugins}
              key={String(shouldUpdateEn)}
              defaultValue={object.content.en}
            />
          </Box>
        )}
      </Content>

      {opened && (
        <Preview
          onClose={() => setOpened(false)}
          object={object}
          hotel={hotel}
          fetchHotel={fetchHotel}
        />
      )}
    </>
  );
}

export default EditContent;
