import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  button_green: {
    height: 41,
    fontSize: 14,
    borderRadius: 4,
    color: '#fff',
    transition: 'all .2s ease-in-out',
    background: '#52AB18',

    '&:hover': {
      background: '#428616'
    }
  },
  button_gray: {
    background: '#D2D1CC',
    height: 41,
    fontSize: 14,
    borderRadius: 4,
    color: '#333333',
    transition: 'all .2s ease-in-out',
    marginRight: '111px',

    '&:hover': {
      background: '#C4C2BB'
    }
  },
  field: {
    marginTop: '30px'
  },
  buttons_wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginTop: '24px'
  }
}));

export default useStyles;
