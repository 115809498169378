import { createAsyncThunk } from '@reduxjs/toolkit';

import { articlesPublish } from 'apis/ArticlesApi';
import { IArticleResponse } from 'types';

const ACTION = 'articles/publish';

const publishArticle = createAsyncThunk(
  ACTION,
  async (data: IArticleResponse): Promise<IArticleResponse> => {
    const article = await articlesPublish(data.id);

    return article;
  }
);

export default publishArticle;
