import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {

  },
  form: {},
  button: {
    width: 165,
    height: 33
  },

  container: {
    width: '100%',
    marginTop: 20
  },
  input: {
    width: '100%'
  },
  select: {
    width: '100%'
  }
}));

export default useStyles;
