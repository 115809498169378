import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    width: '100%',
    minHeight: '100vh',
    maxHeight: '100vh',
    overflow: 'hidden',
    background: '#F2F2F2'
  },
  sideLeft: {
    flexGrow: 0,
    flexShrink: 0
  },
  main: {
    flexGrow: 1
  },
  topbar: {
    flexGrow: 0,
    flexShrink: 0
  },
  content: {
    flexGrow: 1
  }
}));

export default useStyles;
