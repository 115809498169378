import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from './Badge.theme';

const Wrapper = styled.span`
  ${(props) => theme(props)}
`;

function Badge({ children, variant, className }) {
  return (
    <Wrapper
      variant={variant}
      className={className}
    >
      {children}
    </Wrapper>
  );
}

Badge.defaultProps = {
  varian: 'primary'
};

Badge.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  variant: PropTypes.oneOf(['success', 'error', 'warning', 'secondary', 'primary'])
};

export default styled(Badge)``;
