import { css } from 'styled-components';

const size = ({ level }) => {
  if (level === 1) {
    return css`
      font-size: 40px;
    `;
  }

  if (level === 2) {
    return css`
      font-size: 36px;
    `;
  }

  if (level === 3) {
    return css`
      font-size: 30px;
    `;
  }

  if (level === 4) {
    return css`
      font-size: 24px;
    `;
  }

  if (level === 5) {
    return css`
      font-size: 16px;
      line-height: 24px;
    `;
  }

  return css``;
};

const defaults = (props) => css`
  color: #000000;
  line-height: 1.2;
`;

const theme = (props) => ([
  defaults(props),
  size(props)
]);

export default theme;
