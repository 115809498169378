import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Select from 'components/ui/Select';
import useOptions from './hooks/useOptions';

const CategoriesSelect = React.forwardRef(({ className, placeholder, ...rest }, ref) => {
  const [options, { fetch }] = useOptions();

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Select
      options={options}
      placeholder={placeholder}
      valueField='tag'
      labelField='name'
      {...rest}
    />
  );
});

CategoriesSelect.defaultProps = {
  className: '',
  placeholder: 'Выберите категорию'
};

CategoriesSelect.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string
};

export default CategoriesSelect;
