import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'store';

import { articlesAllSelector } from 'store/articlesSlice/selectors';
import { changeTypeArticleAction, deleteArticleAction, fetchArticlesAction, publishArticleAction, unpublishArticleAction } from 'store/articlesSlice/actions';

import { ListIcon, BlocksIcon } from 'components/ui/Icons';

import { Main, TopBar, Content } from 'components/ui/Layout';
import Loader from 'components/ui/Loader';

import Header from 'components/ui/Header';
import ButtonGroup from 'components/ui/ButtonGroup';
import Button from 'components/ui/Button';
import BlogCards from 'components/ui/BlogCards';
import DisplayType from 'components/ui/DisplayType';
import { ARTICLE_FILTER_TYPE, SHOW_TYPE } from 'types';

const DisplayTypeContent = styled.div``;

const LoaderComponent = styled(Loader)`
  height: 100vh;
`;

const AddButton = styled(Button)`
  width: 201px;
`;

const Wrapper = styled(Main)`
  ${Header} {
    width: 250px;
  }
`;

const types = [{
  name: SHOW_TYPE.LIST,
  icon: <ListIcon />
},
{
  name: SHOW_TYPE.REGION,
  icon: <BlocksIcon />
}];

function List({ className }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [_, articles, type] = useAppSelector(articlesAllSelector);

  const navigate = useNavigate();

  const handleChange = (t) => {
    dispatch(changeTypeArticleAction(t));
  };

  useEffect(() => {
    dispatch(fetchArticlesAction(ARTICLE_FILTER_TYPE.REGION));
  }, []);

  const handleDelete = async (blog) => {
    dispatch(deleteArticleAction(blog));
  };

  const handleAdd = (e) => {
    navigate('/regions/add');
  };

  const handleEdit = (blog) => (e) => {
    navigate(`/regions/${blog.id}/edit`);
  };

  const handlePublish = (blog) => (checked) => {
    const action = checked ? publishArticleAction : unpublishArticleAction;

    dispatch(action(blog));
  };

  const handleEditForm = (blog) => {
    navigate(`/regions/${blog.id}/editform`);
  };

  const handleEditAdditional = (blog) => (e) => {
    navigate(`/regions/${blog.id}/edit-additional`);
  };

  if (!articles.length) return <LoaderComponent />;

  return (
    <Wrapper className={className}>
      <TopBar transparent>
        <Header>
          {t('Regions.Title')}
        </Header>

        <DisplayTypeContent>
          <DisplayType
            types={types}
            type={type}
            onChange={handleChange}
          />
        </DisplayTypeContent>

        <ButtonGroup>
          <AddButton onClick={handleAdd}>
            {t('Regions.Add')}
          </AddButton>
        </ButtonGroup>

      </TopBar>

      <Content>
        <BlogCards
          data={articles}
          sitePath='region'
          type={type}
          onEdit={handleEdit}
          onEditForm={handleEditForm}
          onDelete={handleDelete}
          onPublish={handlePublish}
          onEditAdditional={handleEditAdditional}
        />
      </Content>

      <Outlet />
    </Wrapper>
  );
}

List.propTypes = {
  className: PropTypes.string
};

export default List;
