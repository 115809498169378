import { createAsyncThunk } from '@reduxjs/toolkit';

import { articlesCreate } from 'apis/ArticlesApi';
import { IArticleRequest, IArticleResponse } from 'types';

const ACTION = 'articles/create';

const createArticleAction = createAsyncThunk(
  ACTION,
  async (article: IArticleRequest): Promise<IArticleResponse> => {
    const data: IArticleResponse = await articlesCreate(article);

    return data;
  }
);

export default createArticleAction;
