import React, { useState } from 'react';
import { Modal, Box } from '@mantine/core';

import BestReview from './BestReview';
import Header from './Header';
import Map from './Map';
import Gallery from './Gallery';
import { IObjectData, IObjectDataFull } from 'types';

import LanguageSelect from 'components/ui/LanguageSelect';

import useStyles from './Preview.styles';
import Content from './Content';

import { fromBlocksMapper } from 'utils/hotelMapper';
import Blogcard from './Blogcard';

const languages = [
  'ru',
  'en'
];

type Props = {
  onClose: () => void,
  object: IObjectData,
  hotel: any,
  fetchHotel: (lang: string) => void
}

function Add({ onClose, object, hotel, fetchHotel }: Props) {
  const [currentLanguage, setCurrentLanguage] = useState<string>(languages[0]);
  const mappedObject: IObjectDataFull = fromBlocksMapper(object, currentLanguage);
  const { classes, cx } = useStyles();

  const handleChangeLanguage = (val: string): void => {
    setCurrentLanguage(val);

    fetchHotel(val);
  };

  return (
    <Modal opened onClose={onClose} size={1284}>
      <Box className='preview'>
        <LanguageSelect
          className={classes.languageSelect}
          languages={languages}
          onChange={handleChangeLanguage}
          language={currentLanguage}
        />

        <Box className={classes.container}>
          <Box className={classes.leftContainer}>
            <Header hotel={hotel} object={mappedObject} />

            <Gallery className={classes.gallery} hotel={hotel} />

            <BestReview className={classes.bestReview} object={mappedObject} />

            <Content
              className={classes.content}
              hotel={hotel}
              object={mappedObject}
              currentLanguage={currentLanguage}
              key={currentLanguage}
            />
          </Box>

          <Box className={classes.rightContainer}>
            {mappedObject.blogCard && (
              <Blogcard
                data={mappedObject.blogCard}
                className={classes.blog}
              />
            )}

            <Map
              hotel={hotel}
              object={mappedObject}
              currentLanguage={currentLanguage}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
export default Add;
