import { ILocaleString, IPagination, IResponseData } from './global';

export interface IImage {
  encoded_url: string,
  url: string,
  _id: string
}

export interface IMetaTag {
  content: string,
  name: string,
  property?: string
}

export interface ICategory {
  name: string,
  tag: string
}

export interface IAvatar {
  encoded_url: string,
  url: string,
  id: string,
  thumb_url?: string,
  title: string
}

export interface IReviewer {
  avatar: IAvatar,
  first_name: string,
  full_name: string,
  id: string,
  last_name?: string,
  middle_name: string,
  slug: string,
  specializations: ICategory[],
  start_of_practice: string
}

export enum ARTICLE_FILTER_TYPE {
  BLOG = 'blog',
  DISEASE = 'disease',
  OBJECT = 'object',
  REGION = 'region'
}

export enum SHOW_TYPE {
  DEFAULT = 'default',
  LIST = 'list',
  REGION = 'region'
}

export interface IArticleLangContent {
  blocks: any[]
}

export interface IArticleContent {
  ru: IArticleLangContent,
  en?: IArticleLangContent
}

export interface IArticle {
  category?: ICategory,
  reviewer?: IReviewer,
  meta_tags: IMetaTag[],
  seo_description: ILocaleString,
  coordinates?: number[],
  seo_title: ILocaleString,
  sub_title?: ILocaleString,
  title: ILocaleString,
  type: ARTICLE_FILTER_TYPE,
  content: IArticleContent
}

export interface IFilter {
  type: string
}

export interface IArticleFilter {
  type: ARTICLE_FILTER_TYPE
}

export interface IArticlesFetchData {
  filter: IArticleFilter,
  pagination: IPagination
}

export interface IAticlesFetchParams {
  data: IArticlesFetchData
}

export type IQueryParams = IArticlesFetchData

export type IArticleResponseData<d, m> = IResponseData<d, m>

export interface IArticlesMeta {
  total: number,
  query_params: IQueryParams
}

export type IArticleRequest = IArticle

export interface IArticleResponse extends IArticle {
  created_at: string,
  id: string,
  image?: IImage,
  published: boolean,
  published_at?: string,
  slug: string,
  updated_at: string
}
