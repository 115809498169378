
import { createStyles } from '@mantine/styles';

type Props = {
  isColumn: boolean
}

const useStyles = createStyles((theme, { isColumn }: Props) => ({
  root: {

  },
  container: {
    columnCount: isColumn ? 2 : 0,
    width: '100%',
    display: 'inline-block'
  }

}));

export default useStyles;
