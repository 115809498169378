import { css } from 'styled-components';

const styles = css`
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @font-face {
    font-display: swap;
    font-family: 'GTEesti';
    src: url('/fonts/GTEestiProDisplay-Bold/GTEestiProDisplay-Bold-Semibold.eot');
    src: local('GTEesti'), local('GTEestiProDisplay-Semibold'),
      url('/fonts/GjEesti/GTEestiProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/GjEesti/GTEestiProDisplay-Semibold.woff2') format('woff2'),
      url('/fonts/GjEesti/GTEestiProDisplay-Semibold.woff') format('woff'),
      url('/fonts/GjEesti/GTEestiProDisplay-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-display: swap;
    font-family: 'GTEesti';
    src: url('/fonts/GjEesti/GTEestiProDisplay-Regular.eot');
    src: local('GTEesti'), local('GTEestiProDisplay-Regular'),
      url('/fonts/GjEesti/GTEestiProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/GjEesti/GTEestiProDisplay-Regular.woff2') format('woff2'),
      url('/fonts/GjEesti/GTEestiProDisplay-Regular.woff') format('woff'),
      url('/fonts/GjEesti/GTEestiProDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-display: swap;
    font-family: 'GTEesti';
    src: url('/fonts/GjEesti/GTEestiProDisplay-Bold.eot');
    src: local('GTEesti'), local('GTEestiProDisplay-Bold'),
      url('/fonts/GjEesti/GTEestiProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/GjEesti/GTEestiProDisplay-Bold.woff2') format('woff2'),
      url('/fonts/GjEesti/GTEestiProDisplay-Bold.woff') format('woff'),
      url('/fonts/GjEesti/GTEestiProDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  .leaflet-popup-close-button{
    display: none;
  }

  .leaflet-control-attribution {
    display: none;
  }

  .leaflet-right .leaflet-control{
    margin-right: 30px;
  }

  .leaflet-bottom .leaflet-control{
    margin-bottom: 20px;
  }

  .leaflet-control-zoom-in, .leaflet-control-zoom-out, .leaflet-control-zoom-in:hover, .leaflet-control-zoom-out:hover {
    width: 40px;
    height: 43px;
    font-size: 30px;
    line-height: 34px !important;
    color: #656665;
    box-sizing: border-box;
    font-weight: 600;
  }

  .leaflet-bar a {
    width: 40px;
    height: 43px;
  }

  .leaflet-control-zoom-out{
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }

  

  .leaflet-control-zoom-in{
    border-bottom: 0;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 15%;
      width: 70%;
      top: 100%;
      height: 1px;
      background-color: #ebebeb;
    }
  }

  .leaflet-touch .leaflet-bar {
    border: 0;
  }

  .leaflet-bar {
    box-shadow: 0 0 1px #c0c0c0;
    box-shadow: inherit;
    border: 0;
    border-radius: 0;
  }

  .leaflet-container{
    position: relative !important;
    width: 400px;
    height: 200px;
  };

  .leaflet-bottom {
    z-index: 8;
  }

  img.leaflet-marker-icon{
    cursor: pointer;
    margin-left: -21px !important;
    margin-top: -21px !important;
  }

  .leaflet-marker-shadow {
    display: none;
  }
`;

export default styles;
