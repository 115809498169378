import { createAsyncThunk } from '@reduxjs/toolkit';
import { searchAreaFetchOne } from 'apis/SearchAreaApi';

const ACTION = 'search_area/fetchOne';

const fetchOneSearchAreaAction = createAsyncThunk(
  ACTION,
  searchAreaFetchOne
);

export default fetchOneSearchAreaAction;
