import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from './Item.theme';

const Wrapper = styled.div`
  ${(props) => theme(props)}
`;

function Item({ children, className, disabled, onClick, ...rest }) {
  return (
    <Wrapper
      {...rest}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Wrapper>
  );
}

Item.defaultProps = {
  className: '',
  disabled: false
};

Item.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default styled(Item)``;
