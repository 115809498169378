import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate, Outlet } from 'react-router-dom';

import useReviewers from 'Pages/Reviewers/hooks/useReviewers';
import { Main, TopBar, Content } from 'components/ui/Layout';
import Header from 'components/ui/Header';
import ButtonGroup from 'components/ui/ButtonGroup';
import Button from 'components/ui/Button';
import ReviewerCard from './ReviewerCard';
import ReviewerCards from './ReviewersCards';

const Wrapper = styled(Main)`
  ${TopBar} {
    display: flex;
    justify-content: space-between;
  }
`;

function List({ className }) {
  const navigate = useNavigate();

  const [data, { publish, unpublish, fetch, deleteReviewer }] = useReviewers();

  useEffect(() => {
    fetch();
  }, []);

  const handleAdd = (e) => {
    navigate('/reviewers/add');
  };

  const handleEdit = (reviewer) => (e) => {
    navigate(`/reviewers/${reviewer.id}/edit`);
  };

  const handlePublish = (reviewer) => (checked) => {
    checked ? publish(reviewer) : unpublish(reviewer);
  };

  const handleEditAdditional = (reviewer) => (e) => {
    navigate(`/reviewers/${reviewer.id}/edit-additional`);
  };

  if (data === null) return null;

  return (
    <Wrapper className={className}>
      <TopBar transparent>
        <Header>
          Авторы
        </Header>
        <ButtonGroup>
          <Button onClick={handleAdd}>
            Добавить автора
          </Button>
        </ButtonGroup>
      </TopBar>

      <Content>
        <ReviewerCards>
          {data.map((reviewer) => (
            <ReviewerCard
              key={reviewer.id}
              onDelete={deleteReviewer}
              reviewer={reviewer}
              onEdit={handleEdit(reviewer)}
              onEditAdditional={handleEditAdditional(reviewer)}
              onPublish={handlePublish(reviewer)}
            />
          ))}
        </ReviewerCards>
      </Content>

      <Outlet />
    </Wrapper>
  );
}

List.propTypes = {
  className: PropTypes.string
};

export default List;
