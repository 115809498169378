import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 20px;
  line-height: 34px;
  font-family: GTEesti;
  margin-top: 28px;
`;

function Paragraph({ data, ...rest }) {
  return <Wrapper {...rest} dangerouslySetInnerHTML={{ __html: data.text }} />;
}

Paragraph.propTypes = {
  data: PropTypes.object
};

export default styled(Paragraph)``;
