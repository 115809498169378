import React from 'react';
import { Routes, Route } from 'react-router-dom';

import List from './List';
import Edit from './Edit';

type Props = React.PropsWithChildren <{
  className?: string
}>

function SearchArea({ className }: Props) {
  return (
    <Routes>
      <Route path='/' element={<List />}>
        <Route path=':id' element={<Edit />} />
      </Route>
    </Routes>
  );
}

export default SearchArea;
