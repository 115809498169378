import { IBlogCard, IObjectData, IObjectDataFull, IObjectReview, IObjectReviewCategory } from 'types';

function fromBlocksMapper(hotelDraft: IObjectData, lang: string): IObjectDataFull {
  const { content } = hotelDraft;
  const star = getStar(content[lang]?.blocks);
  const review = getReview(content[lang]?.blocks);
  const blogCard = getBlogCard(content[lang]?.blocks);

  return {
    body_translations: content,
    star,
    review,
    blogCard
  };
}

function getBlogCard(blocks: any[] | undefined): IBlogCard | undefined {
  if (!blocks) {
    return;
  }

  const blogCard: IBlogCard = blocks.find((b) => b.type === 'blogCard')?.data;

  if (!blogCard) {
    return;
  }

  return blogCard;
}

function getStar(blocks: any[] | undefined): number | undefined {
  if (!blocks) {
    return;
  }

  const rating: number = blocks.find((b) => b.type === 'stars')?.data?.rating;
  if (!rating) {
    return;
  }

  return Number(rating);
}

function getReview(blocks: any[] | undefined): IObjectReview | undefined {
  if (!blocks) {
    return;
  }

  const review = blocks.find((b) => b.type === 'comments')?.data;

  if (!review) {
    return undefined;
  }

  const categories: IObjectReviewCategory[] = [
    {
      name: 'Расположение',
      rating: Number(review.location)
    },
    {
      name: 'Чистота',
      rating: Number(review.purity)
    },
    {
      name: 'Обслуживание',
      rating: Number(review.service)
    },
    {
      name: 'Цена/качество',
      rating: Number(review.price)
    }
  ];

  return {
    best: {
      user_name: review.name,
      rating: Number(review.rating),
      title: review.title,
      description: review.comment,
      categories
    },
    total: Number(review.total)
  };
}

export default fromBlocksMapper;
