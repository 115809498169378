import { css } from 'styled-components';

const variant = (props) => css`
  color: #FFFFFF;
  background: #3B4149;
`;

const size = (props) => css`
  width: auto;
  height: 28px;
  font-size: 14px;
  line-height: 18px;
  padding: 0 12px;
  border-radius: 4px;
`;

const defaults = (props) => css`
  display: inline-flex;
  align-self: center;
  align-items: center;
  justify-content: center;
`;

const theme = (props) => ([
  defaults(props),
  size(props),
  variant(props)
]);

export default theme;
