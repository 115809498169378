import { Box } from '@mantine/core';
import React from 'react';

import useStyles from './CommonPart.styles';

type Props = React.PropsWithChildren<{
  className?: string
}>

function CommonPart({ className, children }: Props) {
  const { classes, cx } = useStyles();

  return (
    <Box className={cx(className, classes.root)}>
      <Box className={classes.content}>
        {children}
      </Box>
    </Box>
  );
}

export default CommonPart;
