import { MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
  colorScheme: 'light',
  colors: {

  },

  globalStyles: (theme) => ({
    '.preview div': {
      fontFamily: 'GTEesti'
    }
  }),

  shadows: {

  }
};

export default theme;
