import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IFrame from 'utils/iframe';
import Modal from 'components/ui/Modal';
import Group from './Group';
import LanguageSelect from 'components/ui/LanguageSelect/LanguageSelect';

const WrapperContent = styled.div`
  width: 100%;
  min-width: 98vw;
  max-width: 98vw;
  min-height: calc(100vh - 30px);
  max-height: 100vh;
  overflow: auto;
  position: relative;
  padding: 100px 30px 30px 30px;
  background: #FFFFFF;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: GTEesti;
  display: flex;
  gap: 16px;
`;

const DesktopContent = styled.div`
  min-width: 788px;
  max-width: 788px;
  margin: 0 auto;
`;

const MobileContent = styled.div`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
`;

const CloseButton = styled.div`
  position: fixed;
  top: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
  background: #c0c0c0;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    transform: rotate(90deg);
  }

  &:before, &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 3px;
    top: 50%;
    left: 50%;
    background: white;
    transition: 0.2s;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translate(-50%, -50%);
`;

const Button = styled.div`
  font-family: GTEesti;
  text-align: center;
  white-space: nowrap;
  background: ${p => p.desktop ? '#1bc47d' : '#ccc'};
  border-radius: 4px;
  padding: 13px 40px 15px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: all .2s ease-in-out;
`;

const MobileButton = styled.div`
  font-family: GTEesti;
  text-align: center;
  white-space: nowrap;
  background: ${p => p.mobile ? '#1bc47d' : '#ccc'};
  border-radius: 4px;
  padding: 13px 40px 15px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: all .2s ease-in-out;
`;

const languages = [
  'ru',
  'en'
];

const hideEnContent = process.env.REACT_APP_HIDE_EN_CONTENT || true;

function Turbo({ onClose, data: { content } }) {
  const [currentLanguage, setCurrentLanguage] = useState(languages[0]);
  const groups = content[currentLanguage]?.blocks?.map((block) => <Group key={block.id} group={block} />);
  const [mobile, setMobile] = useState(false);
  const [desktop, setDesktop] = useState(true);

  const toggleMob = () => {
    setMobile(true);
    setDesktop(false);
  };

  const toggleDesk = () => {
    setMobile(false);
    setDesktop(true);
  };

  const onChange = (lang) => {
    if (currentLanguage !== lang) {
      setCurrentLanguage(lang);
    }
  };

  return (
    <Modal>
      {!hideEnContent && (
        <LanguageSelect
          languages={languages}
          onChange={onChange}
          language={currentLanguage}
        />
      )}
      <WrapperContent turbo='true'>
        <ButtonsWrapper>
          <MobileButton mobile={mobile} onClick={toggleMob}>Mobile</MobileButton>
          <Button desktop={desktop} onClick={toggleDesk}>Desktop</Button>
        </ButtonsWrapper>
        <IFrame mobile={mobile}>
          {mobile ? <MobileContent>{groups}</MobileContent> : <DesktopContent>{groups}</DesktopContent>}
        </IFrame>
        <CloseButton onClick={onClose} />
      </WrapperContent>

    </Modal>
  );
}

Turbo.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object
};

export default styled(Turbo)``;
