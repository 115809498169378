import React from 'react';
import { Box, Button, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Content } from 'components/ui/Layout';
import { FormField } from '../FormField';
import {
  addNewOstrovokSchema,
  LOCATION,
  PLACE_ID, PROVIDER_ID, TIMEZONE, COORDINATES, LNG, LAT
} from './form';
import { Row } from 'components/ui/Grid';
import Field from 'components/ui/Field/Field';
import RegionSelect from 'components/ui/RegionSelect/RegionSelect';
import TimezonesSelect from 'components/forms/TimezonesSelect';
import useStyles from '../AddObject.styles';
import { useAppDispatch } from 'store';
import createObjectAction from 'store/objectsSlice/actions/createObjectAction';
import { toastify } from 'utils/toatify';

function OstrovokForm({ setOpenModal, setModalData, provider }) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors }, ...methods
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(addNewOstrovokSchema),
    defaultValues: {
      timezone: 'Europe/Moscow'
    }
  });

  const handleSelect = (search_area) => {
    methods.setValue(PLACE_ID, search_area);
  };

  const searchArea = methods.watch(PLACE_ID);

  const onSubmit = async (data) => {
    const formatedData = {
      ...data,
      provider: provider,
      place_id: data.place_id?.place_id
    };

    const result = await dispatch(createObjectAction(formatedData));

    if (result?.error) {
      toastify('error', 'Возникла ошибка');
    } else {
      setOpenModal(true);
      setModalData(result?.payload?.meta);
    }
  };

  return (
    <form name='ostrovok_form' onSubmit={handleSubmit(onSubmit)}>
      <Content className={classes.content}>
        <Box className={classes.box}>
          <Text size={30} color='#000'>{t('Objects.New.Form1.Title')}</Text>

          {/* First block */}
          <div className={classes.block}>
            <Text size={20} color='#000'>{t('Objects.New.Form.All')}</Text>

            <FormField
              name={PROVIDER_ID}
              control={control}
              errors={errors?.[PROVIDER_ID]}
              label={t('Objects.Form.Ostrovok.ProviderId')}
              placeholder={t('Objects.Form.Ostrovok.ProviderId.Placeholder')}
              required
            />
            <FormField
              name={LOCATION}
              control={control}
              errors={errors?.[LOCATION]}
              label={t('Objects.New.Form.Address')}
              placeholder={t('Objects.New.Form.Address.Placeholder')}
              required
            />

            <Field
              label={t('Objects.New.Form.SearchArea')}
              name={PLACE_ID}
              error={errors?.[PLACE_ID]}
              required

            >
              <Controller
                name={PLACE_ID}
                control={control}
                render={({ field }) => (
                  <RegionSelect
                    errors={errors?.[PLACE_ID]} onSelect={handleSelect} value={searchArea}
                    error={!!errors[PLACE_ID]}
                    {...field}
                  />
                )}
              />

            </Field>

            <Row className={classes.row}>
              <FormField
                name={`${COORDINATES}.${LNG}`}
                control={control}
                errors={errors?.[COORDINATES]?.[LNG]}
                label={t('Objects.New.Form.Lng')}
                placeholder={t('Objects.New.Form.Lng.Placeholder')}
                required
              />
              <FormField
                name={`${COORDINATES}.${LAT}`}
                control={control}
                errors={errors?.[COORDINATES]?.[LAT]}
                label={t('Objects.New.Form.Lat')}
                placeholder={t('Objects.New.Form.Lat.Placeholder')}
                required
              />
              <div style={{ minWidth: '280px' }}>
                <Field
                  label={t('Objects.New.Form.Timezone')}
                  name={TIMEZONE}
                  required
                  error={errors[TIMEZONE]}
                >
                  <Controller
                    name={TIMEZONE}
                    control={control}
                    render={({ field }) => (
                      <TimezonesSelect
                        error={!!errors[TIMEZONE]}
                        {...field}
                      />
                    )}
                  />
                </Field>
              </div>
            </Row>
          </div>

        </Box>
        <Button type='submit' className={classes.button_green}>
          {t('Action.Add')}
        </Button>

      </Content>

    </form>
  );
}

OstrovokForm.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  setModalData: PropTypes.func.isRequired,
  provider: PropTypes.string.isRequired
};

export default styled(OstrovokForm)``;
