import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Grid, { Row, Column } from 'components/ui/Grid';
import Field from 'components/ui/Field';
import Input from 'components/ui/Input';
import TextArea from 'components/ui/TextArea';
import CategoriesSelect from 'components/forms/CategoriesSelect';
import ReviewersSelect from 'components/forms/ReviewersSelect';
import Button from 'components/ui/Button';
import ButtonGroup from 'components/ui/ButtonGroup';
import Text from 'components/ui/Text';
import MetaTagsForm from './MetaTagsForm';
import * as yup from 'yup';

const SectionContainer = styled.div`
`;

const Wrapper = styled.div`
  ${SectionContainer} {
    &:not(:first-child) {
      margin-top: 24px;
    }

    > ${Text} {
      margin-bottom: 12px;
    }
  }

  ${ButtonGroup} {
    margin-top: 28px;
  }
`;

const TITLE_FIELD_RU = 'title_ru';
const SEO_TITLE_FIELD_RU = 'seo_title_ru';
const SEO_DESCRIPTION_FIELD_RU = 'seo_description_ru';
const CATEGORY_FIELD = 'category';
const REVIEWER_FIELD = 'reviewer';

export const schema = yup.object().shape({
  [TITLE_FIELD_RU]: yup.string().required(),
  [SEO_TITLE_FIELD_RU]: yup.string().required(),
  [SEO_DESCRIPTION_FIELD_RU]: yup.string().required(),
  [REVIEWER_FIELD]: yup.object().required(),
  [CATEGORY_FIELD]: yup.object().required()
});

function BlogForm({ className }) {
  const { formState: { errors } } = useFormContext();
  const { t } = useTranslation();

  return (
    <Wrapper
      className={className}
    >
      <SectionContainer>
        <Text>{t('Common.General')}</Text>

        <Grid>
          <Row>
            <Field
              label={t('Regions.Form.Name')}
              error={!!errors[TITLE_FIELD_RU]}
              required
              name={TITLE_FIELD_RU}
            >
              <Controller
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      error={!!errors[TITLE_FIELD_RU]}
                      placeholder={t('Common.Name.Placeholder')}
                    />
                  );
                }}
              />
            </Field>
          </Row>

          <Row>
            <Column
              xs={6}
            >
              <Field
                label='Категория'
                name={CATEGORY_FIELD}
              >
                <Controller
                  render={({ field }) => (
                    <CategoriesSelect
                      error={!!errors[CATEGORY_FIELD]}
                      {...field}
                    />
                  )}
                />
              </Field>
            </Column>

            <Column
              xs={6}
            >
              <Field
                label='Автор'
                name={REVIEWER_FIELD}
              >
                <Controller
                  render={({ field }) => (
                    <ReviewersSelect
                      error={!!errors[REVIEWER_FIELD]}
                      {...field}
                    />
                  )}
                />
              </Field>
            </Column>
          </Row>
        </Grid>
      </SectionContainer>

      <SectionContainer>
        <Text>{t('Common.Seo.Title')}</Text>

        <Grid>
          <Row>
            <Field
              label={t('Common.Title')}
              error={!!errors[SEO_TITLE_FIELD_RU]}
              name={SEO_TITLE_FIELD_RU}
              required
            >
              <Controller
                render={({ field }) => (
                  <Input
                    {...field}
                    error={!!errors[SEO_TITLE_FIELD_RU]}
                    placeholder={t('Common.Title.Placeholder')}
                  />
                )}
              />
            </Field>
          </Row>

          <Row>
            <Field
              label={t('Common.Description')}
              error={!!errors[SEO_DESCRIPTION_FIELD_RU]}
              required
              name={SEO_DESCRIPTION_FIELD_RU}
            >
              <Controller
                render={({ field }) => (
                  <TextArea
                    {...field}
                    error={!!errors[SEO_DESCRIPTION_FIELD_RU]}
                    placeholder={t('Common.Description.Placeholder')}
                  />
                )}
              />
            </Field>
          </Row>
        </Grid>

        <MetaTagsForm />
      </SectionContainer>

      <ButtonGroup>
        <Button
          type='submit'
          size='medium'
        >
          {t('Action.Save')}
        </Button>

        <Button
          type='reset'
          size='medium'
          variant='secondary'
        >
          {t('Action.Cancel')}
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
}

BlogForm.defaultProps = {
  className: ''
};

BlogForm.propTypes = {
  className: PropTypes.string
};

export default styled(BlogForm)``;
