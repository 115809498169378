import connection from 'connection/instance';
import { AxiosResponse } from 'axios';
import { IObjectResponse, IArticlesMeta, IObjectResponseData, IObjectUserMeta } from 'types';

import {
  IObjectDataFull,
  IObjectRequest,
  IObjectResponseDataOne,
  IObjectsFetchParams,
  IObjectsMeta
} from 'types/object';

export interface IObjectsParams {
  id: string;
  object: IObjectDataFull;
}

export interface IObjectParams {
  id: string,
  lang: string
}

export const objectsFetch = async (page: number): Promise<IObjectResponse[]> => {
  const params: IObjectsFetchParams = {
    data: {
      pagination: {
        limit: 500,
        page
      }
    }
  };

  const response: AxiosResponse<IObjectResponseData<IObjectResponse[], IObjectsMeta>> = await connection.get(
    '/api/hotels',
    { params }
  );

  return response.data.data;
};

export const objectsFetchOne = async (data: IObjectParams) => {
  const response: AxiosResponse<IObjectResponseDataOne<any, IObjectsMeta>> = await connection.get(`/api/hotels/${data.id}`, { headers: { 'x-site-locale': data.lang } });

  return response.data.hotel;
};

export const objectsCreate = async (article: IObjectRequest) => {
  const response: AxiosResponse<IObjectResponseData<IObjectResponse, IObjectUserMeta>> = await connection.post(
    '/api/hotels',
    { data: article }
  );

  return response.data;
};

export const objectsUpdate = async (data: IObjectsParams) => {
  const response: AxiosResponse<IObjectResponseDataOne<IObjectResponse, IArticlesMeta>> = await connection.put(
    `/api/hotels/${data.id}`,
    { data: data.object }
  );

  return response.data.hotel;
};

export const objectsMigrate = async (data: any) => {
  const response = await connection.put(
      `/api/hotels/${data.id}/migrate`,
      { data: data.object }
  );

  return response.data;
};

export const objectsDelete = async (id: string) => {
  const response: AxiosResponse<IObjectResponseData<IObjectResponse, IArticlesMeta>> = await connection.delete(
    `/api/hotels/${id}`
  );

  return response.data.data;
};

export const objectsPublish = async (id: string) => {
  const response: AxiosResponse<IObjectResponseData<IObjectResponse, IArticlesMeta>> = await connection.put(
    `/api/hotels/${id}/publish`
  );

  return response.data.data;
};

export const objectsUnpublish = async (id: string): Promise<IObjectResponse> => {
  const response: AxiosResponse<IObjectResponseData<IObjectResponse, IArticlesMeta>> = await connection.put(
    `/api/hotels/${id}/unpublish`
  );

  return response.data.data;
};
