import { startAppListening } from '../../listenerMiddleware';

import { toastify } from 'utils/toatify';

import {
  createParsingTariffAction
} from '../actions';

startAppListening({
  actionCreator: createParsingTariffAction.rejected,
  effect: async (action, listenerApi) => {
    toastify('error', 'Не удалось создать тарифф');
  }
});

startAppListening({
  actionCreator: createParsingTariffAction.fulfilled,
  effect: async (action, listenerApi) => {
    toastify('success', `Тариф успешно создан`);
  }
});
