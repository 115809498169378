import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';
import { renderToString } from 'react-dom/server';

import useStyles from './Edit.styles';

import defaultValue from './defaultValue';

import Loader from 'components/ui/Loader';
import Button from 'components/ui/Button';
import { TopBar, Content } from 'components/ui/Layout';
import { RussiaIcon, UnitedKingdomIcon, ArrowIcon } from 'components/ui/Icons';
import Editor from 'components/forms/EditorJs';

import translate from 'utils/translate';

import Preview from './Preview';
import { useAppDispatch, useAppSelector } from 'store';
import { articleSelector } from 'store/articlesSlice/selectors';
import { clearArticleAction, fetchOneArticlesAction, saveArticleAction, updateArticleAction } from 'store/articlesSlice/actions';
import showErrors from 'utils/showErrors';

const hideEnContent = process.env.REACT_APP_HIDE_EN_CONTENT || true;

const plugins = [
  'titleAndDescription',
  'treatments',
  'listItems',
  'whatToSee',
  'facts',
  'map'
];

function Edit({ className }) {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const editorRefRu = useRef(null);
  const [previewData, setPreviewData] = useState();
  const editorRefEn = useRef(null);
  const navigate = useNavigate();
  const { classes, cx } = useStyles({ hideEnContent });

  const [_, article] = useAppSelector(articleSelector);

  useEffect(() => {
    dispatch(fetchOneArticlesAction(id));

    return () => dispatch(clearArticleAction());
  }, []);

  const [shouldUpdateRu, setShouldUpdateRu] = useState(false);
  const [shouldUpdateEn, setShouldUpdateEn] = useState(false);

  const [opened, setOpened] = useState(false);

  if (!article) {
    return <Loader className={classes.loader} />;
  }

  const handleSubmit = async () => {
    const savedDataRu = await editorRefRu.current.save();
    const savedDataEn = await editorRefEn.current?.save() || {};

    const tempData = {
      ...article,
      content: {
        ru: savedDataRu,
        en: savedDataEn
      }
    };

    const yandex_turbo = renderToString((
      <>
        <header>
          <h1>{article.title.ru}</h1>
        </header>
      </>
    ));

    const savedData = {
      ...tempData,
      yandex_turbo: {
        ru: yandex_turbo,
        en: yandex_turbo
      }
    };

    const errors = [
      ...showErrors(savedData.content.ru),
      ...showErrors(savedData.content.en)
    ];

    if (errors.length === 0) {
      dispatch(updateArticleAction({ article: savedData, id }));
    }
  };

  const handlePreview = async () => {
    const savedDataRu = await editorRefRu.current.save();
    const savedDataEn = await editorRefEn.current?.save() || {};

    setPreviewData({
      ...article,
      content: {
        ru: savedDataRu,
        en: savedDataEn
      }
    });

    setOpened(true);
  };

  const handleTemplate = () => {
    dispatch(saveArticleAction({
      ...article,
      content: {
        ...defaultValue.content
      }
    }));

    setShouldUpdateEn(!shouldUpdateEn);
    setShouldUpdateRu(!shouldUpdateRu);
  };

  const handleTranslateEn = () => {
    const translatedObj = translate(article.content.ru);

    dispatch(saveArticleAction({
      ...article,
      content: {
        ...article.content,
        en: translatedObj
      }
    }));

    setShouldUpdateEn(!shouldUpdateEn);
  };

  const handleTranslateRu = () => {
    const translatedObj = translate(article.content.en);

    setShouldUpdateRu(!shouldUpdateRu);

    dispatch(saveArticleAction({
      ...article,
      content: {
        ...article.content,
        ru: translatedObj
      }
    }));
  };

  const handleBack = () => {
    navigate('/regions');
  };

  const disableTemplate = article.content?.ru?.blocks?.length > 0;

  return (
    <Box className={cx(classes.root, className)}>
      <TopBar transparent>
        <Box className={classes.topContainer}>
          <Box onClick={handleBack} className={classes.leftTopbarContainer}>
            <ArrowIcon className={classes.arrow} />

            {t('Action.ToList')}
          </Box>

          <Box className={classes.rightTopbarContainer}>
            <Button onClick={handleTemplate} disabled={disableTemplate} className={classes.button}>
              {t('Common.Template')}
            </Button>

            <Button onClick={handleSubmit} className={classes.button}>
              {t('Action.Save')}
            </Button>

            <Button onClick={handlePreview} className={classes.button}>
              {t('Action.Preview')}
            </Button>
          </Box>
        </Box>
      </TopBar>

      <Text size={24} align='center'>
        {article.title.ru}
      </Text>

      <Content className={classes.content}>
        <Box className={classes.editorContainer}>
          <Box className={classes.iconContainer}>
            <RussiaIcon />
          </Box>

          <Editor
            editorRef={editorRefRu}
            lang='ru'
            plugins={plugins}
            key={shouldUpdateRu}
            defaultValue={article.content.ru}
          />
        </Box>

        {!hideEnContent && (
          <Box className={classes.icons}>
            <ArrowIcon className={classes.iconRu} onClick={handleTranslateEn} />

            <ArrowIcon className={classes.iconEn} onClick={handleTranslateRu} />
          </Box>
        )}

        {!hideEnContent && (
          <Box className={classes.editorContainer}>
            <Box className={classes.iconContainer}>
              <UnitedKingdomIcon />
            </Box>

            <Editor
              plugins={plugins}
              editorRef={editorRefEn}
              lang='en'
              defaultValue={article.content.en}
              key={shouldUpdateEn}
            />
          </Box>
        )}
      </Content>

      {opened && (
        <Preview
          onClose={() => setOpened(false)}
          data={previewData}
        />
      )}
    </Box>
  );
}

Edit.propTypes = {
  className: PropTypes.string
};

export default Edit;
