import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

const Text = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #FFFFFF;
  padding: 0 30px;
`;

const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  text-decoration: none;
  box-sizing: border-box;
  
  ${({ selected }) => selected && css`
    background-color: #000000;
  `}

  &:hover {
    background-color: #000000;
  }
`;

function Item({ menuItem, className }) {
  const location = useLocation();

  const selected = useMemo(() => {
    return location.pathname.indexOf(menuItem.href) === 0;
  }, [location]);

  return (
    <Wrapper
      className={className}
      to={menuItem.href}
      selected={selected}
    >
      <Text>{menuItem.label}</Text>
    </Wrapper>
  );
}

Item.defaultProps = {};

Item.propTypes = {
  className: PropTypes.string,
  menuItem: PropTypes.shape({
    label: PropTypes.string,
    href: PropTypes.string
  })
};

export default styled(Item)``;
