import React from 'react';
import { Box, Button, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';

import { Content } from 'components/ui/Layout';
import { FormField } from '../../../AddObject/FormField';
import {
  editOstrovokSchema,
  LOCATION,
  PLACE_ID, TIMEZONE, COORDINATES, LNG, LAT
} from './form';
import { Row } from 'components/ui/Grid';
import Field from 'components/ui/Field/Field';
import RegionSelect from 'components/ui/RegionSelect/RegionSelect';
import TimezonesSelect from 'components/forms/TimezonesSelect';
import useStyles from '../editObjects.style';
import { useAppDispatch } from 'store';
import { toastify } from 'utils/toatify';
import { updateObjectFormAction } from 'store/objectsSlice/actions';

interface Coordinates {
  lat: number;
  lng: number;
}

interface Hotel {
  provider: string;
  provider_id: string;
  location: string;
  place_id: string;
  place_name: string;
  id: string;
  coordinates: Coordinates;
  timezone: string;
}

interface FormData {
  provider_id: string;
  provider: string;
  location: string;
  coordinates: Coordinates;
  timezone: string;
  place_id: string;
}

interface OstrovokFormProps {
  hotel: Hotel;
}

function OstrovokFormEdit({ hotel }:OstrovokFormProps) {
  const { t } = useTranslation();
  const { id, provider, provider_id, location, place_id, place_name, coordinates, timezone } = hotel;

  const { classes }:any = useStyles();

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors }
  }: UseFormReturn<FormData> = useForm({
    mode: 'onChange',
    resolver: yupResolver(editOstrovokSchema),
    defaultValues: {
      provider_id,
      provider,
      location,
      coordinates,
      timezone,
      place_id
    }
  });

  const onSubmit = async (data:FormData) => {
    // @ts-ignore
    const result = await dispatch(updateObjectFormAction({ object: data, id }));
    // @ts-ignore
    if (result?.error) {
      toastify('error', 'Возникла ошибка');
    } else {
      toastify('success', 'Информация об объекте обновлена');
    }
  };

  return (
    <form name='ostrovok_form' onSubmit={handleSubmit(onSubmit)}>
      <Content className={classes.content}>
        <Box className={classes.box}>
          <Text size={30} color='#000'>{t('Objects.New.Form1.Title')}</Text>

          <div className={classes.block}>

            <FormField
              name={LOCATION}
              control={control}
              errors={errors?.[LOCATION]}
              label={t('Objects.New.Form.Address')}
              required
            />

            <Field
              label={t('Objects.New.Form.SearchArea')}
              name={PLACE_ID}
              error={errors?.[PLACE_ID]}
              required
            >
              <Controller
                name={PLACE_ID}
                control={control}
                render={({ field }) => (
                  <RegionSelect
                    defaultName={place_name}
                    {...field}
                  />
                )}
              />

            </Field>

            <Row className={classes.row}>
              <FormField
                name={`${COORDINATES}.${LNG}`}
                control={control}
                errors={errors?.[COORDINATES]?.[LNG]}
                label={t('Objects.New.Form.Lng')}
                required
              />
              <FormField
                name={`${COORDINATES}.${LAT}`}
                control={control}
                errors={errors?.[COORDINATES]?.[LAT]}
                label={t('Objects.New.Form.Lat')}
                required
              />
              <div style={{ minWidth: '280px' }}>
                <Field
                  label={t('Objects.New.Form.Timezone')}
                  name={TIMEZONE}
                  required
                >
                  <Controller
                    name={TIMEZONE}
                    control={control}
                    render={({ field }) => (

                      <TimezonesSelect
                        // @ts-ignore
                        error={!!errors[TIMEZONE]}
                        {...field}
                      />
                    )}
                  />
                </Field>
              </div>
            </Row>
          </div>

        </Box>
        <Button type='submit' className={classes.button_green}>
          {t('Action.Save')}
        </Button>

      </Content>

    </form>
  );
}

export default styled(OstrovokFormEdit)``;
