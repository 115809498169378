import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dateFormat from 'Utils/dateFormat';
import { useTranslation } from 'react-i18next';

import Badge from 'components/ui/Badge';
import Image from 'components/ui/Image';
import Header from 'components/ui/Header';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import Switch from 'components/ui/Switch';
import ButtonGroup, { LeftGroup, RightGroup } from 'components/ui/ButtonGroup';
import { MoreIcon } from 'components/ui/Icons';
import Dropdown, { DropdownList, DropdownItem } from 'components/ui/Dropdown';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;

  ${Header} + ${Text} {
    margin-top: 4px;
  }

  ${Text} + ${ButtonGroup} {
    margin-top: 16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  border: 1px solid #DCD8D2;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  background: #FFFFFF;

  ${Badge} {
    position: absolute;
    left: 16px;
    bottom: 16px;
  }

  ${Body} {
    flex-grow: 1;
  }
`;

function DefaultCard({ blog, onEdit, onPublish, onEditAdditional, className, onEditForm, sitePath }) {
  const { t } = useTranslation();
  const { title, published, published_at, image, slug } = blog;
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const showArticle = () => {
    const site = process.env.REACT_APP_SITE || 'https://sanatory-testing.ru';

    if (published && site) {
      window.open(`${site}/${sitePath}/${slug}`);
    }
  };

  const publishedStyle = published
    ? 'success'
    : 'secondary';

  const handleEdit = (e) => {
    onEdit(e);
  };

  const handlePublish = (value) => {
    onPublish(value);
  };

  const handleEditAdditional = (e) => {
    onEditAdditional(e);
    setDropdownIsOpen(false);
  };

  return (
    <Wrapper className={className}>
      <Image
        width='auto'
        height='152px'
        src={image?.url}
      >
        {blog.category && (
          <Badge>{blog.category.name}</Badge>
        )}
      </Image>

      <Body>
        <Header level={5}>
          {title.ru}
        </Header>

        {published_at && (
          <Text variant={publishedStyle}>
            {published
              ? `Опубликовано ${dateFormat(published_at)}`
              : `Снято с публикации ${dateFormat(published_at)}`}
          </Text>
        )}

        <ButtonGroup>
          <LeftGroup>
            <Button
              size='medium'
              variant='secondary'
              onClick={handleEdit}
            >
              {t('Action.Edit')}
            </Button>

            <Dropdown
              isOpen={dropdownIsOpen}
              onClickOutside={() => setDropdownIsOpen(false)}
              content={
                <DropdownList>
                  <DropdownItem>
                    <Text>{t('Action.Delete')}</Text>
                  </DropdownItem>

                  <DropdownItem onClick={() => onEditForm(blog)}>
                    <Text>{t('Action.EditForm')}</Text>
                  </DropdownItem>
                  <DropdownItem
                    onClick={showArticle}
                    disabled={!published}
                  >
                    <Text>{t('Action.ShowSite')}</Text>
                  </DropdownItem>
                </DropdownList>
              }
            >
              <Button
                size='medium'
                variant='secondary'
                onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
              >
                <MoreIcon />
              </Button>
            </Dropdown>
          </LeftGroup>

          <RightGroup>
            <Switch
              value={published}
              onChange={handlePublish}
            />
          </RightGroup>
        </ButtonGroup>
      </Body>
    </Wrapper>
  );
}

DefaultCard.defaultProps = {
  onEdit: () => { },
  onPublish: () => { }
};

DefaultCard.propTypes = {
  sitePath: PropTypes.string,
  onEditForm: PropTypes.func,
  className: PropTypes.string,
  blog: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    published: PropTypes.bool,
    published_at: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired
    }),
    category: PropTypes.shape({
      name: PropTypes.string.isRequired,
      tag: PropTypes.string.isRequired
    })
  }).isRequired,
  onEdit: PropTypes.func,
  onPublish: PropTypes.func,
  onEditAdditional: PropTypes.func
};

export default styled(DefaultCard)``;
