import { createStyles } from '@mantine/styles';

type Props = {
  transparent?: boolean
}

const useStyles = createStyles((theme, { transparent }: Props) => ({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexSirection: 'row',
    alignItems: 'center',
    width: 'inherit',
    minHeight: 80,
    justifyContent: 'space-between',
    maxHeight: 80,
    position: 'sticky',
    marginBottom: 20,
    top: 0,
    padding: '0 30px',
    zIndex: 20,
    boxSizing: 'border-box',
    boxShadow: '0 5px 5px -5px rgba(0, 0, 0, .3)',
    background: transparent ? '#EAE9E4' : '#FFFFFF'
  }
}));

export default useStyles;
