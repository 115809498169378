import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from './Button.theme';
import Loader from './Loader';

const Label = styled.div`
  display: flex;

  > *:not(:first-child) {
    margin-left: 6px;
  }
`;

const Wrapper = styled.button`
  ${(props) => theme(props)}
`;

const Button = React.forwardRef(({ children, loading, className, ...rest }, ref) => {
  return (
    <Wrapper
      {...rest}
      loading={loading ? 'true' : undefined}
      className={className}
      ref={ref}
    >
      <Label>
        {children}
      </Label>

      {loading && (
        <Loader />
      )}
    </Wrapper>
  );
});

Button.defaultProps = {
  className: '',
  disabled: false,
  loading: false,
  size: 'large',
  variant: 'primary'
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'large']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'info']),
  children: PropTypes.any.isRequired
};

export default styled(Button)``;
