import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  font-family: GTEesti;
  line-height: 36px;
`;

const Description = styled.div`
  margin-top: 20px;
  font-size: 20px;
  line-height: 34px;
  font-family: GTEesti;
`;

const Wrapper = styled.div`
  margin-top: 68px;
  max-width: 936px;
`;

function About({ className, data }) {
  return (
    <Wrapper className={className}>
      <Title dangerouslySetInnerHTML={{ __html: data.title }} />

      <Description dangerouslySetInnerHTML={{ __html: data.description }} />
    </Wrapper>
  );
}

About.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

export default styled(About)``;
