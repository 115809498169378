import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {

  },
  catalogContainer: {
    marginTop: 10,
    fontWeight: 600,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  regions: {
    marginTop: 20
  },
  catalogName: {
    lineHeight: '18px'
  },
  catalogButton: {

  },
  breadcrumbs: {
    marginBottom: 20
  },
  region: {
    marginBottom: 12
  },
  select: {
    marginTop: 4,
    width: '100%'
  }
}));

export default useStyles;
