import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput, PasswordInput, Paper, Title, Container, Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import Text from 'components/ui/Text';
import { schema } from './schema';
import { useAppDispatch, useAppSelector } from 'store';
import { IUserReqBody } from 'types';
import { loginAction } from 'store/authSlice/actions';
import { loginSelector } from 'store/authSlice/selectors';

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading } = useAppSelector(loginSelector);

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: IUserReqBody) => {
    await dispatch(loginAction(data));

    navigate('/articles');
  };

  return (
    <Container sx={{ width: 500 }} size={600} my={150}>
      <Title align='center' sx={{ fontWeight: 900 }}>
        Welcome!
      </Title>
      <form name='login' onSubmit={handleSubmit(onSubmit)}>
        <Paper withBorder shadow='md' p={30} mt={30} radius='md'>
          <Controller
            name='email'
            control={control}
            render={({ field }) => <TextInput withAsterisk {...field} label='Email' placeholder='Your email' />}
          />
          {errors.email && (
            <Text className='' variant='error'>
              {errors.email.message}
            </Text>
          )}

          <Controller
            name='password'
            control={control}
            render={({ field }) => (
              <PasswordInput withAsterisk {...field} label='Password' placeholder='Your password' mt='md' />
            )}
          />
          {errors.password && (
            <Text className='' variant='error'>
              {errors.password.message}
            </Text>
          )}

          <Button type='submit' fullWidth mt='xl' loading={loading}>
            Sign in
          </Button>
        </Paper>
      </form>
    </Container>
  );
};

export default Login;
