import { availabilitesFetch } from 'apis/AvailabilitiesApi';
import { useState } from 'react';
import { IAvailabilitiesResponse } from 'types/availabilities';

const limit: number = 50;

function useAvailabilities() {
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isPendingMore, setIsPendingMore] = useState<boolean>(false);
  const [data, setData] = useState<IAvailabilitiesResponse[]>([]);
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>(null);

  const fetch = async (params: any): Promise<void> => {
    setPage(1);
  
    setIsPending(true);
    const { data, meta } = await availabilitesFetch({ 
      ...params,
      paginate: {
        limit,
        page,
      }
    });

    setMeta(meta);

    setPage(page + 1);
    
    setData(data);

    setIsPending(false);
  };

  const fetchMore = async (params: any) => {
    if (meta?.pagination?.total > data.length) return;
    
    setIsPendingMore(true);
  
    const { data: fetchedData } = await availabilitesFetch({ 
      ...params,
      paginate: {
        limit,
        page,
      }
    });
    
    setPage(page + 1);

    setData([
      ...data,
      ...fetchedData
    ])

    setIsPendingMore(false);
  }

  return [
    {
      data,
      isPending,
      isPendingMore
    },
    {
      fetch,
      fetchMore
    }
  ];
}

export default useAvailabilities;
