import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Content = styled.div`
`;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
  background: rgba(0, 0, 0, 0.3);
`;

function Modal({ children, className, ...rest }) {
  return ReactDOM.createPortal(
    <Wrapper className={className}>
      <Content>
        {children}
      </Content>
    </Wrapper>
    ,
    document.getElementById('root')
  );
}

Modal.defaultProps = {
};

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default Modal;
