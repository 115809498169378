import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import Modal from 'components/ui/Modal';
import Header from 'components/ui/Header';
import Form from 'components/ui/Form';
import ReviewerForm, { schema } from 'Pages/BlogNew/components/Form';
import formValues from './formValues';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchOneArticlesAction, updateArticleFormAction } from 'store/articlesSlice/actions';
import { articleSelector } from 'store/articlesSlice/selectors';

const HeaderContainer = styled.div`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 4px;
  box-sizing: border-box;
  width: 560px;
  max-height: 100vh;
  overflow: auto;

  ${HeaderContainer} {
    margin-bottom: 20px;
  }
`;

const FormWrapper = styled(Form)`
`;

function EditForm({ className, lang }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [, article] = useAppSelector(articleSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchOneArticlesAction(id));
  }, []);

  const onSubmit = async (formData) => {
    const formattedData = {
      ...formData,
      title: {
        ru: formData?.title_ru || null,
        en: formData?.title_en || null
      },
      meta_tags: [
        {
          name: 'Description',
          property: null,
          content: formData?.seo_description_ru || null
        },
        ...formData?.meta_tags
      ],
      seo_description: {
        ru: formData?.seo_description_ru || null,
        en: formData?.seo_description_en || null
      },
      seo_title: {
        ru: formData?.seo_title_ru || null,
        en: formData?.seo_title_en || null
      },
      type: formData?.type,
      sub_title: {
        ru: formData?.title_ru || null,
        en: formData?.title_en || null
      }
    };

    dispatch(updateArticleFormAction({ article: formattedData, id }));
  };

  const onReset = () => {
    navigate('/articles');
  };

  if (!article) {
    return null;
  }

  return (
    <Modal>
      <Wrapper className={className}>
        <HeaderContainer>
          <Header
            level={3}
          >
            {t('Blog.Form.Edit')}
          </Header>
        </HeaderContainer>

        <FormWrapper
          initialValue={formValues({ type: 'article', ...article })}
          onSubmit={onSubmit}
          onReset={onReset}
          schema={schema}
          key={article.updated_at}
        >
          <ReviewerForm {...{ lang }} />
        </FormWrapper>
      </Wrapper>
    </Modal>
  );
}

EditForm.propTypes = {
  className: PropTypes.string,
  lang: PropTypes.string
};

export default styled(EditForm)``;
