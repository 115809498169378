import * as yup from 'yup';

export const LOCATION = 'location';
export const PLACE_ID = 'place_id';
export const LEGAL_ENTITY = 'legal_entity';
export const COORDINATES = 'coordinates';
export const NUMBER = 'number';
export const COMMISSION = 'commission_in_percent';
export const DATE = 'from_date';
export const CONTRACTS = 'contracts';
export const SOURCES = 'sources';
export const INSURANCE_ID = 'insurance_id';
export const INN = 'inn';
export const KPP = 'kpp';
export const LAT = 'lat';
export const LNG = 'lng';
export const TIMEZONE = 'timezone';

export const editObjectSchema = yup.object().shape({
  [LOCATION]: yup.string().required('Обязательное поле'),
  [LEGAL_ENTITY]: yup.object().shape({
    [INN]: yup.string().required('Обязательное поле')
  }),
  [PLACE_ID]: yup.string().nullable().required('Обязательное поле'),
  [COORDINATES]: yup.object().shape({
    [LAT]: yup.string().matches(
      /^-?([1-8]?[0-9](\.\d+)?|90(\.0+)?)$/,
      'Некорректный формат широты. Пример: 45.9999999'
    ).required('Обязательное поле'),
    [LNG]: yup.string().matches(
      /^-?((1[0-7][0-9]|[1-9]?[0-9])(\.\d+)?|180(\.0+)?)$/,
      'Некорректный формат долготы. Пример: 45.9999999'
    ).required('Обязательное поле')
  }),
  [TIMEZONE]: yup.string().required('Обязательное поле'),
  [SOURCES]: yup.array().of(
    yup.object().shape({
      [CONTRACTS]: yup.array().of(
        yup.object().shape({
          [NUMBER]: yup.string().required('Обязательное поле'),
          [DATE]: yup.string().required('Обязательное поле'),
          [COMMISSION]: yup.number().required('Обязательное поле').min(0, 'Значение должно быть не менее 0')
            .max(100, 'Значение должно быть не более 100')
        })
      )
    })
  )

});
