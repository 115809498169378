import React from 'react';
import PropTypes from 'prop-types';

import About from './About';
import Treatments from './Treatments';
import Diseases from './Diseases';
import WhatToSee from './WhatToSee';
import Facts from './Facts';
import Map from './Map';
import HotelCard from './HotelCard';
import HotelCardSmall from './HotelCardSmall';
import Links from './Links';
import { Image, Paragraph, Header, InfoBlock, List } from 'components/ui/PreviewBlocks';
import Region from './Region';

function Group({ group, region, ...rest }) {
  switch (group.type) {
    case 'titleAndDescription':
      return <About data={group.data} region={region} {...rest} />;

    case 'list':
      return <List data={group.data} region={region} {...rest} />;

    case 'regionCard':
      return <Region data={group.data} region={region} {...rest} />;

    case 'links':
      return <Links data={group.data} region={region} {...rest} />;

    case 'infoBlock':
      return <InfoBlock data={group.data} region={region} {...rest} />;

    case 'header':
      return <Header data={group.data} region={region} {...rest} />;

    case 'hotelCard':
      return <HotelCard data={group.data} region={region} {...rest} />;

    case 'hotelCardSmall':
      return <HotelCardSmall data={group.data} region={region} {...rest} />;

    case 'treatments':
      return <Treatments data={group.data} region={region} {...rest} />;

    case 'listItems':
      return <Diseases data={group.data} region={region} {...rest} />;

    case 'whatToSee':
      return <WhatToSee data={group.data} region={region} {...rest} />;

    case 'facts':
      return <Facts data={group.data} region={region} {...rest} />;

    case 'map':
      return <Map data={group.data} region={region} {...rest} />;

    case 'paragraph':
      return <Paragraph data={group.data} region={region} {...rest} />;

    case 'image':
      return <Image data={group.data} region={region} {...rest} />;

    default:
      return <div />;
  }
}

Group.propTypes = {
  className: PropTypes.string,
  group: PropTypes.object,
  region: PropTypes.object
};

Group.defaultProps = {
  region: {
    name: 'Тестовый регион'
  }
};

export default Group;
