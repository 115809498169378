import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BookIcon } from 'components/ui/Icons';

import Element from './Element';
import { useTranslation } from 'react-i18next';

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  margin: 0;
  font-family: GTEesti;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 22px;
  margin-top: 8px;
  font-family: GTEesti;
`;

const Button = styled.div`
  color: #2e7cd6;
  font-size: 18px;
  line-height: 26px;
  font-family: GTEesti;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  margin-top: 8px;
`;

const ListContainer = styled.div`
  margin-top: 20px;
`;

const LeftContainer = styled.div`

`;

const RightContainer = styled.div`
  margin-left: 14px;
  margin-top: 6px;
`;

const IconContainer = styled.div`
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background: #E1EFFF;
  border-radius: 50%;
`;

const Wrapper = styled.div`
  padding-left: 0;
  margin-top: 40px;
  padding: 0;
  display: flex;
  align-items: flex-start;

  ${Element} + ${Element} {
    margin-top: 22px;
  }
`;

function Links({ data }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState();
  const elementsComponent = data.list.map((element, index) => <Element key={index} index={index} {...element} />);
  const buttonText = opened ? t('Blog.Links.Action.Open') : t('Blog.Links.Action.Close');

  const handleToggle = () => {
    setOpened(!opened);
  };

  return (
    <Wrapper>
      <LeftContainer>
        <IconContainer>
          <BookIcon />
        </IconContainer>
      </LeftContainer>

      <RightContainer>
        <Title dangerouslySetInnerHTML={{ __html: data.title }} />

        <Description dangerouslySetInnerHTML={{ __html: data.description }} />

        <Button onClick={handleToggle}>
          {buttonText}
        </Button>

        {opened && (
          <ListContainer>
            {elementsComponent}
          </ListContainer>
        )}
      </RightContainer>
    </Wrapper>
  );
}

Links.propTypes = {
  data: PropTypes.object
};

export default styled(Links)``;
