import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MapComponent from './MapComponent';

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  font-family: GTEesti;
  line-height: 36px;
`;

const Content = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Description = styled.div`
  margin-top: 12px;
  font-size: 18px;
  line-height: 24px;
  font-family: GTEesti;
  max-width: 580px;
  min-width: 580px;
`;

const MapContainer = styled.div`
  max-width: 614px;
  min-width: 614px;
`;

const Wrapper = styled.div`
  margin-top: 68px;
`;

function Map({ className, data }) {
  const coordinates = [...data.coordinates].reverse();

  return (
    <Wrapper className={className}>
      <Title dangerouslySetInnerHTML={{ __html: data.title }} />

      <Content>
        <MapContainer>
          <MapComponent position={coordinates} />
        </MapContainer>

        <Description dangerouslySetInnerHTML={{ __html: data.description }} />
      </Content>
    </Wrapper>
  );
}

Map.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

export default styled(Map)``;
