import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';

import Top from './Top';
import Bottom from './Bottom';
import useHotelPrice from 'hooks/useHotelPrice';
import Gallery from '../../Gallery';
import useStyles from '../../Gallery/Gallery.styles';

const Wrapper = styled.div`
  margin-top: 68px;
  max-width: 936px;

  ${Bottom} {
    margin-top: 28px;
  }
`;

function About({ className, data: { hotel } }) {
  const [{ price, isPending }, { fetch }] = useHotelPrice(hotel);
  const { classes } = useStyles();

  if (!hotel.id) return;

  const handleEnter = () => {
    !isPending && !price && fetch();
  };

  return (
    <Waypoint onEnter={handleEnter}>
      <Wrapper className={className}>
        <Top hotel={hotel} />

        <Gallery className={classes.gallery} hotel={hotel} />

        <Bottom hotel={hotel} price={price} isPending={isPending} />
      </Wrapper>
    </Waypoint>
  );
}

About.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

export default styled(About)``;
