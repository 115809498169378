import React from 'react';

import Distance from './Distance';
import useStyles from './Distances.styles';
import { Box } from '@mantine/core';

type Props = {
  className?: string,
  distances: any[]
}

function Distances({ className, distances }: Props) {
  const { classes, cx } = useStyles();
  const distancesElement = distances.map((distance) => <Distance key={distance.id} className={classes.distance} distance={distance} />);

  return (
    <Box className={cx(className, classes.root)}>
      <Box>
        {distancesElement}
      </Box>
    </Box>
  );
}

export default Distances;
