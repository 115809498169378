import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DefaultCard from './DefaultCard';
import RegionCard from './RegionCard';
import ListCard from './ListCard';

function Card({ type, ...rest }) {
  switch (type) {
    case 'default':
      return <DefaultCard {...rest} />;

    case 'list':
      return <ListCard {...rest} />;

    case 'region':
      return <RegionCard {...rest} />;

    default:
      return <DefaultCard {...rest} />;
  }
}

Card.defaultProps = {
  type: 'default'
};

Card.propTypes = {
  type: PropTypes.string
};

export default styled(Card)``;
