import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mantine/core';

import useStyles from './Content.styles';

type Props = {
  className?: string,
  program: any
}

function Content({ className, program }: Props) {
  const { classes, cx } = useStyles();
  const listComponents = program.includes.map((p: string, index: number) => <Box key={index} className={classes.li}>{p}</Box>);

  return (
    <Box className={cx(className, classes.root)}>
      <Box className={classes.ul}>
        {listComponents}
      </Box>
    </Box>
  );
}

Content.propTypes = {
  className: PropTypes.string,
  program: PropTypes.object
};

export default Content;
