import connection from 'connection/instance';

class CategoriesApi {
  async fetchList() {
    try {
      const { data } = await connection.get('/api/categories');

      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }
}

export default new CategoriesApi();
