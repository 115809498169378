import connection from 'connection/instance';
import { AxiosResponse } from 'axios';
import {
  ARTICLE_FILTER_TYPE,
  IArticleRequest,
  IArticleResponse,
  IArticlesMeta,
  IAticlesFetchParams,
  IArticleResponseData
} from 'types';

export interface IArticlesParams {
  id: string,
  article: IArticleRequest
}

export const articlesFetch = async (type: ARTICLE_FILTER_TYPE): Promise<IArticleResponse[]> => {
  const params: IAticlesFetchParams = {
    data: {
      filter: {
        type
      },
      pagination: {
        limit: 500,
        page: 1
      }
    }
  };

  const response: AxiosResponse<IArticleResponseData<IArticleResponse[], IArticlesMeta>> =
    await connection.get('/api/articles', { params });

  return response.data.data;
};

export const articlesFetchOne = async (id: string) => {
  const response: AxiosResponse<IArticleResponseData<IArticleResponse, IArticlesMeta>> =
    await connection.get(`/api/articles/${id}`);

  return response.data.data;
};

export const articlesCreate = async (article: IArticleRequest) => {
  const response: AxiosResponse<IArticleResponseData<IArticleResponse, IArticlesMeta>> =
    await connection.post('/api/articles', { data: article });

  return response.data.data;
};

export const articlesUpdate = async (data: IArticlesParams) => {
  const response: AxiosResponse<IArticleResponseData<IArticleResponse, IArticlesMeta>> =
    await connection.put(`/api/articles/${data.id}`, { data: data.article });

  return response.data.data;
};

export const articlesDelete = async (id: string) => {
  const response: AxiosResponse<IArticleResponseData<IArticleResponse, IArticlesMeta>> =
    await connection.delete(`/api/articles/${id}`);

  return response.data.data;
};

export const articlesPublish = async (id: string) => {
  const response: AxiosResponse<IArticleResponseData<IArticleResponse, IArticlesMeta>> =
    await connection.put(`/api/articles/${id}/publish`);

  return response.data.data;
};

export const articlesUnpublish = async (id: string): Promise<IArticleResponse> => {
  const response: AxiosResponse<IArticleResponseData<IArticleResponse, IArticlesMeta>> =
    await connection.put(`/api/articles/${id}/unpublish`);

  return response.data.data;
};
