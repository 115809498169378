import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Tabs, Menu } from '@mantine/core';
import { Dots } from 'tabler-icons-react';

import useStyles from './Edit.styles';

import defaultValue from './defaultValue.json';

import Loader from 'components/ui/Loader';
import { TopBar } from 'components/ui/Layout';
import { ArrowIcon } from 'components/ui/Icons';
import { useAppDispatch, useAppSelector } from 'store';
import showErrors from 'utils/showErrors';
import { clearObjectOneAction, fetchOneObjectAction, saveObjectAction, updateObjectAction } from 'store/objectsSlice/actions';
import { objectSelector } from 'store/objectsSlice/selectors';
import { IObjectData, IObjectDataFull, STATUSES } from 'types';
import fromBlocksMapper from 'utils/hotelMapper/fromBlocksMapper';
import { SANATORY, OSTROVOK } from '../AddObject/constants';
import OstrovokProviderModal from './OstrovokProviderForm/OstrovokProviderForm';
import SanatoryProviderModal from './SanatoryProviderForm/SanatoryProviderForm';
import { ContentType } from './types';
import EditContent from './EditContent';
import EditObject from './EditObject';

type Props = React.PropsWithChildren<{
  className?: string
}>

function Edit({ className }: Props) {
  const dispatch = useAppDispatch();
  const { id }: any = useParams();
  const { t } = useTranslation();
  const editorRefRu = useRef<any>(null);
  const editorRefEn = useRef<any>(null);
  const navigate = useNavigate();
  const [hiddenContent, setHiddenContent] = useState<string>('en');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [shouldUpdateRu, setShouldUpdateRu] = useState<boolean>(false);
  const [shouldUpdateEn, setShouldUpdateEn] = useState<boolean>(false);
  const [opened, setOpened] = useState(false);

  const { classes, cx } = useStyles({ hiddenContent });

  const [objectState, object, hotel] = useAppSelector(objectSelector);

  const fetchHotel = (lang: string): void => {
    dispatch(fetchOneObjectAction({ id, lang }));
  };

  useEffect(() => {
    fetchHotel('ru');
    // dispatch(fetchOneObjectAction(id));

    return () => {
      dispatch(clearObjectOneAction());
    };
  }, []);

  if (!hotel && objectState !== STATUSES.FULFILLED) {
    return <Loader className={classes.loader} />;
  }

  const getEditorData = async (): Promise<IObjectData> => {
    let data: IObjectData = object;

    if (hiddenContent === 'en') {
      const savedDataRu = await editorRefRu.current.save();

      data = {
        ...object,
        content: {
          ...object.content,
          ru: savedDataRu
        }
      };
    }

    if (hiddenContent === 'ru') {
      const savedDataEn = await editorRefEn.current.save();

      data = {
        ...object,
        content: {
          ...object.content,
          en: savedDataEn
        }
      };
    }

    if (!hiddenContent) {
      const savedDataRu = await editorRefRu.current.save();
      const savedDataEn = await editorRefEn.current?.save() || {};

      data = {
        ...object,
        content: {
          ru: savedDataRu,
          en: savedDataEn
        }
      };
    }

    return data;
  };

  const handleClickHiddenAction = (name: string) => async () => {
    const data: IObjectData = await getEditorData();

    dispatch(saveObjectAction(data));

    setHiddenContent(name);
  };

  const handleSubmit = async () => {
    const data: IObjectData = await getEditorData();

    const errors = [
      ...showErrors(data.content.ru),
      ...showErrors(data.content.en)
    ];

    if (errors.length === 0) {
      const object: IObjectDataFull = fromBlocksMapper(data, 'ru');

      dispatch(updateObjectAction({ object, id }));
    }
  };

  const handlePreview = async () => {
    const data: IObjectData = await getEditorData();

    dispatch(saveObjectAction(data));

    setOpened(true);
  };

  const handleTemplate = () => {
    dispatch(saveObjectAction({
      ...object,
      content: {
        ...defaultValue.content
      }
    }));

    setShouldUpdateEn(!shouldUpdateEn);
    setShouldUpdateRu(!shouldUpdateRu);
  };

  const handleBack = () => {
    navigate('/objects');
  };

  const getСurrentProviderModal = () => {
    switch (hotel.provider) {
      case SANATORY:
        return <OstrovokProviderModal id={hotel.id} setShowModal={setShowModal} showModal={showModal} />;
      case OSTROVOK:
        return <SanatoryProviderModal id={hotel.id} setShowModal={setShowModal} showModal={showModal} />;
    }
  };

  return (
    <Box className={cx(classes.root, className)}>
      <Tabs defaultValue={ContentType.Content}>
        <TopBar transparent>
          <Box className={classes.topContainer}>
            <Box onClick={handleBack} className={classes.leftTopbarContainer}>
              <ArrowIcon className={classes.arrow} />

              {t('Action.ToList')}
            </Box>

            <Tabs.List>
              <Tabs.Tab value={ContentType.Content}>{t('Objects.Tab.Name1')}</Tabs.Tab>
              <Tabs.Tab value={ContentType.Object}>{t('Objects.Tab.Name2')}</Tabs.Tab>
            </Tabs.List>

            <Box className={classes.rightTopbarContainer}>
              <Button onClick={handleSubmit} className={classes.button}>
                {t('Action.Save')}
              </Button>
              <Menu>
                <Menu.Target>
                  <Box className={classes.target}>
                    <Dots />
                  </Box>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item>
                    <Button onClick={() => setShowModal(true)} className={classes.button}>
                      {t('Action.ChangeProvider')}
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button
                      onClick={handleTemplate}
                      disabled={false} // disableTemplate}
                      className={classes.button}
                    >
                      {t('Common.Template')}
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button onClick={handlePreview} className={classes.button}>
                      {t('Action.Preview')}
                    </Button>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>

            </Box>
          </Box>
        </TopBar>

        <Tabs.Panel value={ContentType.Content}>
          <EditContent
            hotel={hotel}
            fetchHotel={fetchHotel}
            setOpened={setOpened}
            opened={opened}
            hiddenContent={hiddenContent}
            handleClickHiddenAction={handleClickHiddenAction}
            editorRefRu={editorRefRu}
            shouldUpdateRu={shouldUpdateRu}
            object={object}
            editorRefEn={editorRefEn}
            shouldUpdateEn={shouldUpdateEn}
          />

        </Tabs.Panel>
        <Tabs.Panel value={ContentType.Object}>
          <EditObject hotel={hotel} />
        </Tabs.Panel>

      </Tabs>
      {getСurrentProviderModal()}
    </Box>
  );
}

Edit.propTypes = {
  className: PropTypes.string
};

export default Edit;
