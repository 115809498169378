import React from 'react';
import { ThumbUp } from 'tabler-icons-react';
import { Box, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import Stars from 'components/ui/Stars';

import { IObjectDataFull } from 'types';

import useStyles from './Header.styles';

type Props = {
  className?: string,
  object: IObjectDataFull,
  hotel: any
}

function Add({ className, object, hotel }: Props) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={cx(className, classes.root)}>
      <Box className={classes.headerContainer}>
        <Box>
          <Text weight={600} size={42} className={classes.title}>
            <Text className={classes.hotelName}>
              {hotel.name}
            </Text>

            {!!object.star && (<Stars stars={object.star} className={classes.stars} />)}
          </Text>

          <Box className={classes.address}>
            <Text>
              {hotel.address.location}
            </Text>
          </Box>
        </Box>

        {(hotel.rating > 5 || hotel.review?.total) > 0 && (
          <Box className={classes.rightContant}>
            {hotel.rating > 5 && (
              <Box className={classes.rating}>
                <ThumbUp fill='#1bc47d' stroke='transparent' size={25} />

                <Text className={classes.ratingText} size={30}>
                  {hotel.rating}
                </Text>
              </Box>
            )}

            {hotel.review?.total > 0 && (
              <Box className={classes.reviewContainer}>
                <Text color='#3582db' align='center'>
                  {t('Common.Review', { count: hotel.review.total })}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default Add;
