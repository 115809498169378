import React from 'react';
import { Text, Box } from '@mantine/core';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import useStyles from './Periods.styles';

import Rate from '../shared/Rate';
import { IPeriod } from 'types/parsing';

type Props = React.PropsWithChildren<{
  className?: string;
  periods: IPeriod[]
}>;

function Rates({ className, periods }: Props) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const periodsComponent = periods.map(period => {
    const date: string = [
      DateTime.fromFormat(period.start_date, 'yyyy-MM-dd').toFormat('dd.MM.yyyy'),
      DateTime.fromFormat(period.end_date, 'yyyy-MM-dd').toFormat('dd.MM.yyyy')
    ].join(' - ');

    const ratesComponent = period.rates.map(rate => <Rate rate={rate} />)

    return (
      <Box className={classes.period}>
        <Text weight={600} size={14}>
          {date}
        </Text>

        <Box className={classes.rates}>
          {ratesComponent}
        </Box>
      </Box>

    )
  })

  return (
    <Box className={cx(classes.root, className)}>
      <Text className={classes.label} size={16} color='#212529'>
        {t('Tariffs.Periods.Title')}
      </Text>

      <Box className={classes.periods}>
        {periodsComponent}
      </Box>
    </Box>
  );
}

export default Rates;
