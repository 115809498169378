import React from 'react';
import { Routes, Route } from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import Tariffs from './Tariffs';
import Add from './Add';
import Tariff from './Tariff';

type Props = React.PropsWithChildren <{
  className?: string
}>

function ParsingTariffs({ className }: Props) {
  return (
    <Routes>
      <Route path='/:id/edit' element={<Edit />} />

      <Route path='/' element={<List />}>
        <Route path='/add' element={<Add />} />
      </Route>

      <Route path='/:id/' element={<Tariffs />} />

      <Route path='/:id/tariff/:tariffId' element={<Tariff />} />
    </Routes>
  );
}

export default ParsingTariffs;
