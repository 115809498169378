import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Text, Tabs } from '@mantine/core';

import { Main, TopBar } from 'components/ui/Layout';
import SuccessModal from './SuccessModal';
import useStyles from './AddObject.styles';
import SanatoryForm from './SanatoryForm/SanatoryForm';
import { SANATORY, OSTROVOK } from './constants';
import OstrovokForm from './OstrovokForm/OstrovokForm';
import { getProviderTranslate } from '../List/getProviderTranslate';

function AddObject() {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleBack = () => {
    navigate('/objects');
  };

  return (
    <Main className={cx(classes.root)}>
      <Tabs defaultValue={SANATORY}>
        <TopBar>

          <div className={classes.left_wrapper}>
            <Text size={14} color='#999999'>{t('Objects.New.Title')}</Text>
            <Text size={30} color='#000'>
              {t('Objects.New.Subtitle')}
            </Text>

          </div>
          <Tabs.List>
            <Tabs.Tab value={SANATORY}>{getProviderTranslate(SANATORY)}</Tabs.Tab>
            <Tabs.Tab value={OSTROVOK}>{getProviderTranslate(OSTROVOK)}</Tabs.Tab>
          </Tabs.List>

          <div className={classes.right_wrapper}>
            <Button onClick={handleBack} className={classes.button}>
              {t('Action.Cancel')}
            </Button>
          </div>
        </TopBar>

        <Tabs.Panel value={SANATORY}><SanatoryForm provider={SANATORY} setOpenModal={setOpenModal} setModalData={setModalData} /></Tabs.Panel>
        <Tabs.Panel value={OSTROVOK}><OstrovokForm provider={OSTROVOK} setOpenModal={setOpenModal} setModalData={setModalData} /></Tabs.Panel>

      </Tabs>
      <SuccessModal setOpenModal={setOpenModal} openModal={openModal} modalData={modalData} />
    </Main>
  );
}

export default AddObject;
