import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ReviewerCard from '../ReviewerCard';

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -32px;
  margin-top: -32px;

  ${ReviewerCard} {
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 32px;
    margin-top: 32px;
  }
`;

const Wrapper = styled.div`
  width: 1024px;
`;

function ReviewersCards({ children, className }) {
  return (
    <Wrapper className={className}>
      <Content>
        {children}
      </Content>
    </Wrapper>
  );
}

ReviewersCards.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default styled(ReviewersCards)``;
