import React, { useEffect } from 'react';
import { Box, Button, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';

import { Content } from 'components/ui/Layout';
import { FormField } from '../../../AddObject/FormField';
import {
  editObjectSchema,
  COMMISSION,
  CONTRACTS,
  COORDINATES,
  DATE,
  INN,
  INSURANCE_ID,
  KPP,
  LAT,
  LEGAL_ENTITY,
  LNG,
  LOCATION,
  NUMBER,
  PLACE_ID, SOURCES, TIMEZONE
} from './form';
import { Row } from 'components/ui/Grid';
import Field from 'components/ui/Field/Field';
import RegionSelect from 'components/ui/RegionSelect/RegionSelect';
import TimezonesSelect from 'components/forms/TimezonesSelect';
import DatePicker from 'components/ui/DatePicker/DatePicker';
import useStyles from '../editObjects.style';
import { useAppDispatch } from 'store';
import { toastify } from 'utils/toatify';
import { updateObjectFormAction } from 'store/objectsSlice/actions';
import useSources from '../../../AddObject/SanatoryForm/useSources';

const format = 'yyyy-MM-dd';

interface Contract {
  commission_in_percent: number;
  from_date: string;
  number: string;
}

interface Source {
  insurance_id: string;
  contracts: Contract[];
}

interface LegalEntity {
  inn: string;
  kpp: string;
}

interface SanatoryFormData {
  provider: string;
  timezone: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  location: string;
  legal_entity: LegalEntity;
  place_id: string;
  sources: Source[];
}

interface SanatoryFormProps {
  hotel: {
    id: string;
    provider: string;
    place_name: string;
    location: string;
    place_id: string;
    coordinates: {
      lat: number;
      lng: number;
    };
    timezone: string;
    legal_entity: LegalEntity;
    sources: Source[];
  };
}

function SanatoryFormEdit({ hotel }:SanatoryFormProps) {
  const { t } = useTranslation();
  const { classes }:any = useStyles();
  const { id, provider, place_name, location, place_id, coordinates, timezone, legal_entity, sources: hotelSources } = hotel;
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<SanatoryFormData>({
    mode: 'onChange',
    resolver: yupResolver(editObjectSchema),
    defaultValues: {
      provider,
      timezone,
      coordinates: {
        lat: coordinates.lat,
        lng: coordinates.lng
      },
      location,
      legal_entity: {
        inn: legal_entity.inn,
        kpp: legal_entity.kpp
      },
      place_id,
      sources: hotelSources.map((source: Source) => ({
        insurance_id: source.insurance_id,
        contracts: source.contracts.map((contract: Contract) => ({
          commission_in_percent: contract.commission_in_percent,
          from_date: DateTime.fromISO(contract.from_date).toFormat(format),
          number: contract.number
        }))
      }))
    }
  });

  const [sources, { fetch }]:any = useSources();

  useEffect(() => {
    fetch();
  }, []);

  const onSubmit = async (data: FieldValues) => {
    // @ts-ignore
    const result = await dispatch(updateObjectFormAction({ object: data, id }));
    // @ts-ignore
    if (result?.error) {
      toastify('error', 'Возникла ошибка');
    } else {
      toastify('success', 'Информация об объекте обновлена');
    }
  };

  return (
    <form name='sanatory_form' onSubmit={handleSubmit(onSubmit)}>
      <Content className={classes.content}>
        <Box className={classes.box}>
          <Text size={30} color='#000'>{t('Objects.New.Form1.Title')}</Text>

          {/* First block */}
          <div className={classes.block}>
            <Text size={20} color='#000'>{t('Objects.New.Form.All')}</Text>

            <FormField
              name={LOCATION}
              control={control}
              errors={errors?.[LOCATION]}
              label={t('Objects.New.Form.Address')}
              required
            />
          </div>

          {/* Second block */}
          <div className={classes.block}>
            <Text size={20} color='#000'>{t('Objects.New.Form.Law')}</Text>

            <Row className={classes.row}>
              <FormField
                name={`${LEGAL_ENTITY}.${INN}`}
                control={control}
                errors={errors?.[LEGAL_ENTITY]?.[INN]}
                label={t('Objects.New.Form.Inn')}
                placeholder={t('Objects.New.Form.Inn.Placeholder')}
                required
              />
              <FormField
                name={`${LEGAL_ENTITY}.${KPP}`}
                control={control}
                errors={errors?.[LEGAL_ENTITY]?.[KPP]}
                label={t('Objects.New.Form.Kpp')}
                placeholder={t('Objects.New.Form.Kpp.Placeholder')}
              />

            </Row>
          </div>

          {/* Third block */}
          <div className={classes.block}>
            <Text size={20} color='#000'>{t('Objects.New.Form.Search')}</Text>
            <Field
              label={t('Objects.New.Form.SearchArea')}
              name={PLACE_ID}
              error={errors?.[PLACE_ID]}
              required
            >
              <Controller
                name={PLACE_ID}
                control={control}
                render={({ field }) => (
                  <RegionSelect
                    defaultName={place_name}
                    {...field}
                  />
                )}
              />

            </Field>

            <Row className={classes.row}>
              <FormField
                name={`${COORDINATES}.${LNG}`}
                control={control}
                errors={errors?.[COORDINATES]?.[LNG]}
                label={t('Objects.New.Form.Lng')}
                placeholder={t('Objects.New.Form.Lng.Placeholder')}
                required
              />
              <FormField
                name={`${COORDINATES}.${LAT}`}
                control={control}
                errors={errors?.[COORDINATES]?.[LAT]}
                label={t('Objects.New.Form.Lat')}
                placeholder={t('Objects.New.Form.Lat.Placeholder')}
                required
              />

              <div style={{ minWidth: '280px' }}>
                <Field
                  label={t('Objects.New.Form.Timezone')}
                  name={TIMEZONE}
                  required
                  error={errors[TIMEZONE]}
                >
                  <Controller
                    name={TIMEZONE}
                    control={control}
                    render={({ field }) => (
                      <TimezonesSelect
                        {...field}
                          // @ts-ignore
                        error={!!errors[TIMEZONE]}
                      />
                    )}
                  />
                </Field>
              </div>

            </Row>
          </div>

        </Box>
        <Box className={classes.box}>
          <Text size={30} color='#000'>{t('Objects.New.Form2.Title')}</Text>
          {/* // @ts-ignore */}
          {hotelSources?.map(({ insurance_id, contracts }:any, index:number) => {
            const name = sources?.find((item:any) => item?.id === insurance_id)?.name;
            return (
              <div key={insurance_id} className={classes.block}>
                <Text size={20} color='#000'>{name}</Text>
                <div>
                  <Row className={classes.row}>
                    <FormField
                      name={`${SOURCES}.${index}.${INSURANCE_ID}`}
                      control={control}
                      className={classes.hidden}
                      type='hidden'
                    />
                    {/* // @ts-ignore */}
                    {contracts.map((_:any, contractIndex: number):any => (
                      <React.Fragment key={contractIndex}>
                        <FormField
                          name={`${SOURCES}.${index}.${CONTRACTS}.${contractIndex}.${NUMBER}`}
                          control={control}
                          errors={errors?.[SOURCES]?.[index]?.[CONTRACTS]?.[contractIndex]?.[NUMBER]}
                          label={t('Objects.New.Form2.Number')}
                          placeholder={t('Objects.New.Form2.Number.Placeholder')}
                          required
                        />
                        <Field
                          label={t('Objects.New.Form2.Date')}
                          name={`${SOURCES}.${index}.${CONTRACTS}.${contractIndex}.${DATE}`}
                          error={errors?.[SOURCES]?.[index]?.[CONTRACTS]?.[contractIndex]?.[DATE]}
                          required
                        >
                          <Controller
                            name={`${SOURCES}.${index}.${CONTRACTS}.${contractIndex}.${DATE}`}
                            control={control}
                            render={({ field }) => (
                              <DatePicker
                                          // @ts-ignore */
                                className={classes.datepicker}
                                error={errors?.[SOURCES]?.[index]?.[CONTRACTS]?.[contractIndex]?.[DATE]}
                                {...field}
                              />
                            )}
                          />
                        </Field>
                        <FormField
                          name={`${SOURCES}.${index}.${CONTRACTS}.${contractIndex}.${COMMISSION}`}
                          control={control}
                          errors={errors?.[SOURCES]?.[index]?.[CONTRACTS]?.[contractIndex]?.[COMMISSION]}
                          label={t('Objects.New.Form2.Commission')}
                          placeholder={t('Objects.New.Form2.Commission.Placeholder')}
                          required
                          type='number'
                        />
                      </React.Fragment>
                    ))}
                  </Row>
                </div>
              </div>
            );
          })}
        </Box>
        <Button type='submit' className={classes.button_green}>
          {t('Action.Save')}
        </Button>

      </Content>

    </form>
  );
}

export default styled(SanatoryFormEdit)``;
