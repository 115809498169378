import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SwitchComponent from 'rc-switch';

import theme from './Switch.theme';

const Wrapper = styled.div`
  ${(props) => theme(props)}
`;

function Switch({ value: initialValue, onChange, className }) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (checked, e) => {
    onChange(checked);
    setValue(checked);
  };

  return (
    <Wrapper className={className}>
      <SwitchComponent
        checked={value}
        onChange={handleChange}
      />
    </Wrapper>
  );
}

Switch.defaultProps = {
  className: '',
  value: false,
  onChange: () => {}
};

Switch.propTypes = {
  className: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func
};

export default styled(Switch)``;
