import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Editor from 'components/forms/Editor';

const Wrapper = styled.div`
  width: 820px;
  margin: 0 auto;
`;

function Body({ reviewer, contentRef, className }) {
  const { id, content } = reviewer;

  return (
    <Wrapper className={className}>
      <Editor
        content={content}
        autoSaveUrl={`/api/reviewers/${id}/autosave`}
        uploadImgUrl={`/api/reviewers/${id}/attachments`}
        selectImgUrl={`/api/reviewers/${id}/attachments`}
        contentRef={contentRef}
      />
    </Wrapper>
  );
}

Body.defaultProps = {};

Body.propTypes = {
  className: PropTypes.string,
  reviewer: PropTypes.object.isRequired,
  contentRef: PropTypes.any
};

export default styled(Body)``;
