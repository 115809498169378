import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {

  },
  top: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 8
  },
  stat: {
    marginTop: 18,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  element: {
    width: '50%',
    marginBottom: 12
  },
  thumb: {
    marginLeft: 16,
    width: 132,
    height: 32,
    background: '#e4f9ee',
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    boxSizing: 'border-box'
  },
  review: {
    marginTop: 24
  },
  reviewTop: {
    display: 'flex',
    alignItems: 'center'
  },
  reviewRating: {
    display: 'flex',
    marginTop: 4,
    marginLeft: 12,
    height: 21,
    background: '#6dd098',
    borderRadius: 10.5,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 8px 0 10px',
    boxSizing: 'border-box'
  },
  reviewRatingValue: {
    marginLeft: 6
  },
  reviewComment: {
    marginTop: 10,
    lineHeight: '26px'
  }
}));

export default useStyles;
