import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { MapContainer as Map, TileLayer, Marker } from 'react-leaflet';
import { defaultMarker2 } from 'Utils/markers';

import getMapTilesUrl from 'Utils/getMapTilesUrl';

const Wrapper = styled.div`
  transition: all linear 0.2s;
  border-radius: 9px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  margin-top: 6px;

  .leaflet-popup-close-button{
    display: none;
  }

  .leaflet-control-attribution {
    display: none;
  }

  .leaflet-container{
    height: 439px;
    position: relative !important;
  };
`;

function MapPage({ position, tileBaseUrl, className }) {
  const tileUrl = getMapTilesUrl({ tileBaseUrl });

  return (
    <Wrapper className={className}>
      <Map
        animate
        minZoom={1}
        useFlyTo
        center={position}
        zoom={7}
        zoomControl={false}
      >
        <TileLayer url={tileUrl} />

        <Marker
          icon={defaultMarker2}
          draggable={false}
          position={position}
        />
      </Map>
    </Wrapper>
  );
}

MapPage.propTypes = {
  className: PropTypes.string,
  position: PropTypes.array.isRequired,
  // extra: PropTypes.object.isRequired,
  tileBaseUrl: PropTypes.string
};

MapPage.defaultProps = {
  tileBaseUrl: 'https://tiles.worldota.net/api/v1/t/ostrovok'
};

export default styled(MapPage)``;
