import connection from 'connection/instance';

class TimezonesApi {
  async fetchList() {
    try {
      const { data } = await connection.get('/api/filters/timezones');
      return { data: data.data.sort((a, b) => a?.formatted_name.localeCompare(b?.formatted_name)), error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }
}
// ?data[chars]=moscow
export default new TimezonesApi();
