import { PayloadAction } from '@reduxjs/toolkit';

import { IClientData, IUserState } from 'types';
import { readFromLocalStorage } from 'utils/storage';
import { setDefaulsHeader } from 'utils/setDefaultHeaders';

const user: IClientData = readFromLocalStorage('user');
setDefaulsHeader(user);
const client = { client: user };

const DEFAULTS = {
  client,
  isAuthenticated: !!user,
  loading: false
};

class Adapter {
  public getInitialState(props?: IClientData): IUserState {
    return { ...DEFAULTS, ...props };
  }

  public loginPending(state: IUserState) {
    const currentState = { loading: true, isAuthenticated: false };
    return { ...state, ...currentState };
  }

  public logoutFullfilled(state: IUserState) {
    const currentState = { loading: false, isAuthenticated: false };
    return { ...state, ...currentState };
  }

  public loginFulfilled(state: IUserState, action: PayloadAction<any>) {
    const currentState = { loading: false, isAuthenticated: true, client: { ...action.payload.client } };

    return { ...state, ...currentState };
  }

  public loginRejected(state: IUserState) {
    const currentState = { loading: false, isAuthenticated: false };

    return { ...state, ...currentState };
  }

  public logoutFulfilled(state: IUserState) {
    const currentState = { loading: false, isAuthenticated: false, client: null };

    return { ...state, ...currentState };
  }

  public logoutPending(state: IUserState) {
    const currentState = { loading: true, isAuthenticated: false };

    return { ...state, ...currentState };
  }

  public logoutRejected(state: IUserState) {
    const currentState = { loading: false, isAuthenticated: false };

    return { ...state, ...currentState };
  }
}

const adapter = new Adapter();

const initialState = adapter.getInitialState();

export { initialState };

export default adapter;
