import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import _uniqueId from 'lodash/uniqueId';

const Label = styled.label`
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  color: #a7a7a7;

  ${({ isRequired }) => isRequired && css`
    &:after {
      content: '*';
      margin-left: 4px;
    }
  `}

  ${({ isError }) => isError && css`
    color: #FF5656;
  `}
`;

const Element = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  ${({ isInline }) => isInline && css`
    flex-direction: row;
    align-items: center;

    ${Label} + ${Element} {
      margin-left: 16px;
    }
  `}

  ${({ isInline }) => !isInline && css`
    flex-direction: column;
    align-items: baseline;

    ${Label} + ${Element} {
      margin-top: 8px;
    }
  `}
  ${({ isHidden }) => isHidden && css`
    display: none;
  `}
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #FF5656;
  margin-top: 4px;
`;

function Field({ label, required, error, disabled, isHidden, inline, children, className, name }) {
  const id = useRef(_uniqueId('field-')); // ToDo. Сейчас это не передаётся в нужный input
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { name });
    }

    return child;
  });

  return (
    <Wrapper
      className={className}
      isInline={inline}
      isHidden={isHidden}
    >
      {label && (
        <Label
          htmlFor={id.current}
          isError={error}
          isRequired={required}
        >
          {label}
        </Label>
      )}

      <Element>
        {childrenWithProps}
      </Element>

      <ErrorMessage>
        {error?.message}
      </ErrorMessage>
    </Wrapper>
  );
}

Field.defaultProps = {
  required: false,
  error: false,
  disabled: false,
  inline: false,
  className: '',
  isHidden: false
};

Field.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  name: PropTypes.string,
  isHidden: PropTypes.bool
};

export default styled(Field)``;
