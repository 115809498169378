import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {
    padding: 20,
    boxSizing: 'border-box'
  },
  title: {},
  loader: {

  },
  checkBox: {
    marginTop: 20
  },
  select: {
    marginTop: 20
  },
  input: {
    marginTop: 20
  }
}));

export default useStyles;
