import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {

  },

  top: {

  },

  location: {
    marginTop: 5
  },

  table: {
    // marginTop: -20
  }
  
}));

export default useStyles;
