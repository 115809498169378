import { Button, Modal, Text } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import useStyles from '../AddObject.styles';
import { CopyIcon } from 'components/ui/Icons';
import { toastify } from 'utils/toatify';

function SuccessModal({ setOpenModal, openModal, modalData }) {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenModal(false);
    navigate('/objects');
  };

  const handleCopyLogin = e => {
    e.stopPropagation();
    navigator.clipboard.writeText(modalData?.email);
    toastify('info', 'Логин скопирован в буфер');
  };

  const handleCopyPass = e => {
    e.stopPropagation();
    navigator.clipboard.writeText(modalData?.password);
    toastify('info', 'Пароль скопирован в буфер');
  };

  return (
    <Modal withCloseButton={false} centered closeOnClickOutside={false} onClose={() => setOpenModal(false)} opened={openModal}>
      <Text size={30} color='#000'>{t('Objects.Modal.Title')}</Text>
      <div className={classes.column}>
        <div className={classes.modalRow}>
          <Text size={14} color='#999999'>{t('Objects.Modal.Login')}</Text>
          <Text size={14}>{modalData?.email}</Text>
          <div className={classes.icon} onClick={handleCopyLogin}>
            <CopyIcon />
          </div>
        </div>
        <div className={classes.modalRow}>
          <Text size={14} color='#999999'>{t('Objects.Modal.Pass')}</Text>
          <Text size={14}>{modalData?.password}</Text>
          <div className={classes.icon} onClick={handleCopyPass}>
            <CopyIcon />
          </div>
        </div>
      </div>
      <Button onClick={handleClose} className={classes.button_green_static}>
        {t('Action.Understand')}
      </Button>
    </Modal>
  );
}

SuccessModal.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  modalData: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string
  })
};

export default SuccessModal;
