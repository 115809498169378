import L from 'leaflet';

export const defaultMarker = L.icon({
  iconUrl: '/images/markers/point.png',
  iconSize: [32, 32],
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
});

export const defaultHotelMarker = L.icon({
  iconUrl: '/images/markers/point.png',
  iconSize: [68, 68],
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
});

export const defaultMarker2 = L.icon({
  iconUrl: '/images/markers/point_2.png',
  iconSize: [34, 34],
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
});
