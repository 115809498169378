import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getImage } from 'Utils/getImage';

const Wrapper = styled.img`
  width: 100%;
  margin-top: 68px;
  border-radius: 4px;
`;

function Image({ data, ...rest }) {
  const image = getImage(data.file.encoded_url, { width: 1200, height: 600 });

  return <Wrapper src={image.defaultSrc} {...rest} />;
}

Image.propTypes = {
  data: PropTypes.object
};

export default styled(Image)``;
