import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme, _, getRef) => ({
  root: {
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  description: {
    marginLeft: 10,
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '18px',
    maxWidth: 245
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: 23
  }
}));

export default useStyles;
