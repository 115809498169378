import { createStyles } from '@mantine/styles';
type Props = {
  rating: number
}

const useStyles = createStyles((theme, { rating }: Props) => ({
  root: {
    display: 'flex',
    gap: 12,
    alignItems: 'center'
  },
  slider: {
    marginLeft: 48,
    position: 'relative',
    height: 5,
    width: 130,
    backgroundColor: '#f5f5f7'
  },
  sliderLine: {
    borderRadius: 2.5,
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#5bb884',
    width: 13 * rating
  },
  name: {
    width: 120
  }
}));

export default useStyles;
