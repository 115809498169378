import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 18px;
  line-height: 26px;
  font-family: GTEesti;
  margin-top: 28px;
  width: 100%;
  padding: 32px 136px 32px 40px;
  border-radius: 10px;
  background-color: #f5f5f7;
  box-sizing: border-box;
`;

function InfoBlock({ data, ...rest }) {
  return <Wrapper {...rest} dangerouslySetInnerHTML={{ __html: data.title }} />;
}

InfoBlock.propTypes = {
  data: PropTypes.object
};

export default styled(InfoBlock)``;
