import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const ItemComponent = styled.div`
  border-radius: 30px;
  padding: 8px 20px;
  box-sizing: border-box;
  transition: 0.3s;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;

  ${({ active }) => active && css`
    background: rgba(26,36,87,1);
  `}
`;

const Wrapper = styled.div`
  display: flex;
  background: rgba(26,36,87,0.5);
  border-radius: 30px;

  ${ItemComponent} + ${ItemComponent} {
    margin-left: -8px;
  }
`;

function Edit({ className, language, languages, onChange }) {
  const itemsComponent = languages.map((lan, index) => (
    <ItemComponent key={index} onClick={() => onChange(lan)} active={language === lan}>
      {lan}
    </ItemComponent>
  ));

  return (
    <Wrapper className={className}>
      {itemsComponent}
    </Wrapper>
  );
}

Edit.propTypes = {
  className: PropTypes.string,
  languages: PropTypes.array,
  onChange: PropTypes.func,
  language: PropTypes.string
};

export default styled(Edit)``;
