import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { createSearchParams, useNavigate, Outlet, Link, useSearchParams } from 'react-router-dom';
import { Breadcrumbs, Anchor, ActionIcon, Text, Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Edit } from 'tabler-icons-react';

import RegionSelect from 'components/ui/RegionSelect';

import { Main, TopBar, Content } from 'components/ui/Layout';

import useStyles from './List.styles';

import { catalogFetchAction, fetchSearchAreaAction, fetchSearchAreaMoreAction } from 'store/searchAreaSlice/actions';

import { searchAreaAllSelector } from 'store/searchAreaSlice/selectors';
import { IRegionDocument, ISearchArea, ISearchAreaBreadcrumbs, STATUSES } from 'types';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

// eslint-disable-next-line no-undef
type SearchType = Record<string, string>;

const mainBreadcrumb: ISearchAreaBreadcrumbs = {
  name: 'Главная',
  id: 12001
};

const searchType: SearchType = {
  coordinates: 'по координатам',
  area: 'по границам'
};

function SearchArea({ className }: Props) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const osmId: any = searchParams.get('osm_id');
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const [page, setPage] = useState(2);

  const dispatch = useAppDispatch();
  const [searchAreas, breadcrumbs, isLast, stateMore, total]: [
    ISearchArea[],
    ISearchAreaBreadcrumbs[],
    boolean,
    string,
    number,
  ] = useAppSelector(searchAreaAllSelector);

  useEffect(() => {
    dispatch(
      fetchSearchAreaAction({
        page: 1,
        parent_osm_id: osmId || undefined
      })
    );

    dispatch(
      catalogFetchAction()
    );
  }, [osmId]);

  useEffect(() => {
    isLast && navigate(-1);
  }, [isLast]);

  const buildSearchPath = (id?: number) => {
    if (!id) return '';

    return createSearchParams({
      osm_id: String(id)
    }).toString();
  };

  const handleClick = (area: ISearchArea) => () => {
    navigate({
      search: buildSearchPath(area.osm_id)
    });
  };

  const handleEdit = (element: ISearchArea) => (e: any) => {
    e.stopPropagation();

    navigate({
      pathname: `/search_area/${element.id}`,
      search: buildSearchPath(osmId)
    });
  };

  const handleSelect = (regionObj: IRegionDocument) => {
    navigate(`/search_area/${regionObj?.place_id}`);
  };

  const handleScroll = (e: any) => {
    const scrollHeight = e.currentTarget.scrollHeight;
    const scrollTop = e.currentTarget.scrollTop;
    const clientHeight = e.currentTarget.clientHeight;

    if (scrollHeight - (scrollTop + clientHeight) < 200) {
      if (stateMore !== STATUSES.PENDING) {
        setPage(page + 1);
        if (searchAreas.length >= total) {
          return;
        }
        dispatch(
          fetchSearchAreaMoreAction({
            page,
            parent_osm_id: osmId || undefined
          })
        );
      }
    }
  };

  const items = [mainBreadcrumb, ...breadcrumbs].map((item, index) => {
    if (index !== breadcrumbs.length) {
      return (
        <Anchor component={Link} to={{ search: buildSearchPath(item.osm_id) }} key={index}>
          {item.name}
        </Anchor>
      );
    }

    return (
      <Anchor key={index} variant='text'>
        {item.name}
      </Anchor>
    );
  });

  return (
    <Main onScroll={handleScroll} className={cx(className, classes.root)}>
      <TopBar>
        <Text size={40} color='#000'>
          {t('SearchArea.Title')}
        </Text>

        <RegionSelect placeholder={t('SearchArea.RegionSelect.Placeholder')} onSelect={handleSelect} clearAfterSelect />
      </TopBar>

      <Content className={classes.content}>
        <Breadcrumbs className={classes.breadcrumbs}>{items}</Breadcrumbs>

        <Box className={classes.table}>
          <Box className={classes.boxHeader}>
            <Text className={classes.text}>{t('SearchArea.Table.Field1')}</Text>
            <Text className={classes.text}>{t('SearchArea.Table.Field2')}</Text>
            <Text className={classes.text}>{t('SearchArea.Table.Field3')}</Text>
            <Text className={classes.text}>{t('SearchArea.Table.Field4')}</Text>
            <Text className={classes.text}>{t('SearchArea.Table.Field5')}</Text>
            <Text className={classes.text}>{t('SearchArea.Table.Field6')}</Text>
            <Text />
          </Box>
          {searchAreas.map((element) => (
            <Box className={classes.box} key={element.id} onClick={handleClick(element)}>
              <Text className={classes.boxText}>{element.name.ru}</Text>
              <Text className={classes.boxText}>{element.country.ru}</Text>
              <Text className={classes.boxText}>{searchType[element.search_mode]}</Text>
              <Text className={classes.boxText}>{element.is_searchable ? 'да' : 'нет'}</Text>
              <Text className={classes.boxText}>{element.center.coordinates.map((c) => c.toFixed(2)).join(', ')}</Text>
              <Text className={classes.boxText}>{String(element.search_radius)}</Text>
              <Text className={classes.boxText}>
                <ActionIcon
                  onClick={handleEdit(element)}
                  variant='outline'
                  color='rgb(76, 110, 245)'
                  title='Редактировать'
                >
                  <Edit size={16} />
                </ActionIcon>
              </Text>
            </Box>
          ))}
        </Box>
        {stateMore === STATUSES.PENDING && (
          <Text align='center' weight='bold' size='lg' transform='uppercase'>
            Loading...
          </Text>
        )}
      </Content>

      <Outlet />
    </Main>
  );
}

export default SearchArea;
