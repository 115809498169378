import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Text, Button } from '@mantine/core';

import useStyles from './Edit.styles';

import defaultValue from './defaultValue.json';

import Loader from 'components/ui/Loader';
import { TopBar, Content } from 'components/ui/Layout';
import { RussiaIcon, ArrowIcon, UnitedKingdomIcon } from 'components/ui/Icons';
import Editor from 'components/forms/EditorJs';

import Preview from './Preview';
import { useAppDispatch, useAppSelector } from 'store';
import showErrors from 'utils/showErrors';
import { fetchOneObjectAction, saveObjectAction, updateObjectAction } from 'store/objectsSlice/actions';
import { objectSelector } from 'store/objectsSlice/selectors';
import { IObjectData, IObjectDataFull, STATUSES } from 'types';
import fromBlocksMapper from 'utils/hotelMapper/fromBlocksMapper';

const plugins = [
  'programs',
  'blogCard',
  'stars',
  'description',
  'comments',
  'distances',
  'procedures',
  'feeding_programs'
];

type Props = React.PropsWithChildren<{
  className?: string
}>

function Edit({ className }: Props) {
  const dispatch = useAppDispatch();
  const { id }: any = useParams();
  const { t } = useTranslation();
  const editorRefRu = useRef<any>(null);
  const editorRefEn = useRef<any>(null);
  const navigate = useNavigate();

  const [hiddenContent, setHiddenContent] = useState<string>('en');

  const { classes, cx } = useStyles({ hiddenContent });

  const [objectState, object, hotel] = useAppSelector(objectSelector);

  const fetchHotel = (lang: string): void => {
    dispatch(fetchOneObjectAction({ id, lang }));
  };

  useEffect(() => {
    fetchHotel('ru');
    // dispatch(fetchOneObjectAction(id));
  }, []);

  const [shouldUpdateRu, setShouldUpdateRu] = useState<boolean>(false);
  const [shouldUpdateEn, setShouldUpdateEn] = useState<boolean>(false);

  const [opened, setOpened] = useState(false);

  if (!hotel && objectState !== STATUSES.FULFILLED) {
    return <Loader className={classes.loader} />;
  }

  const getEditorData = async (): Promise<IObjectData> => {
    let data: IObjectData = object;

    if (hiddenContent === 'en') {
      const savedDataRu = await editorRefRu.current.save();

      data = {
        ...object,
        content: {
          ...object.content,
          ru: savedDataRu
        }
      };
    }

    if (hiddenContent === 'ru') {
      const savedDataEn = await editorRefEn.current.save();

      data = {
        ...object,
        content: {
          ...object.content,
          en: savedDataEn
        }
      };
    }

    if (!hiddenContent) {
      const savedDataRu = await editorRefRu.current.save();
      const savedDataEn = await editorRefEn.current?.save() || {};

      data = {
        ...object,
        content: {
          ru: savedDataRu,
          en: savedDataEn
        }
      };
    }

    return data;
  };

  const handleClickHiddenAction = (name: string) => async () => {
    const data: IObjectData = await getEditorData();

    dispatch(saveObjectAction(data));

    setHiddenContent(name);
  };

  const handleSubmit = async () => {
    const data: IObjectData = await getEditorData();

    const errors = [
      ...showErrors(data.content.ru),
      ...showErrors(data.content.en)
    ];

    if (errors.length === 0) {
      const object: IObjectDataFull = fromBlocksMapper(data, 'ru');

      dispatch(updateObjectAction({ object, id }));
    }
  };

  const handlePreview = async () => {
    const data: IObjectData = await getEditorData();

    dispatch(saveObjectAction(data));

    setOpened(true);
  };

  const handleTemplate = () => {
    dispatch(saveObjectAction({
      ...object,
      content: {
        ...defaultValue.content
      }
    }));

    setShouldUpdateEn(!shouldUpdateEn);
    setShouldUpdateRu(!shouldUpdateRu);
  };

  const handleBack = () => {
    navigate('/objects');
  };

  return (
    <Box className={cx(classes.root, className)}>
      <TopBar transparent>
        <Box className={classes.topContainer}>
          <Box onClick={handleBack} className={classes.leftTopbarContainer}>
            <ArrowIcon className={classes.arrow} />

            {t('Action.ToList')}
          </Box>

          <Box className={classes.rightTopbarContainer}>
            <Button
              onClick={handleTemplate}
              disabled={false} // disableTemplate}
              className={classes.button}
            >
              {t('Common.Template')}
            </Button>

            <Button onClick={handleSubmit} className={classes.button}>
              {t('Action.Save')}
            </Button>

            <Button onClick={handlePreview} className={classes.button}>
              {t('Action.Preview')}
            </Button>
          </Box>
        </Box>
      </TopBar>

      <Box className={classes.header}>
        <Box />

        <Text size={24} align='center' className={classes.headerTitle}>
          {hotel.name}
        </Text>

        <Box className={classes.flags}>
          <Box
            className={cx(classes.flagsBlock, hiddenContent === 'en' && classes.activeFlag)}
            onClick={handleClickHiddenAction('en')}
          >
            <RussiaIcon />
          </Box>

          <Box
            className={cx(classes.flagsBlock, hiddenContent === 'ru' && classes.activeFlag)}
            onClick={handleClickHiddenAction('ru')}
          >
            <UnitedKingdomIcon />
          </Box>

          <Box
            className={cx(classes.flagsBlock, !hiddenContent && classes.activeFlag)}
            onClick={handleClickHiddenAction('')}
          >
            <RussiaIcon />

            <UnitedKingdomIcon />
          </Box>
        </Box>
      </Box>

      <Content className={classes.content}>
        {hiddenContent !== 'ru' && (
          <Box className={classes.editorContainer}>
            <Box className={classes.iconContainer}>
              <RussiaIcon />
            </Box>

            <Editor
              editorRef={editorRefRu}
              lang='ru'
              plugins={plugins}
              key={String(shouldUpdateRu)}
              defaultValue={object.content.ru}
            />
          </Box>
        )}

        {hiddenContent !== 'en' && (
          <Box className={classes.editorContainer}>
            <Box className={classes.iconContainer}>
              <UnitedKingdomIcon />
            </Box>

            <Editor
              editorRef={editorRefEn}
              lang='en'
              plugins={plugins}
              key={String(shouldUpdateEn)}
              defaultValue={object.content.en}
            />
          </Box>
        )}
      </Content>

      {opened && (
        <Preview
          onClose={() => setOpened(false)}
          object={object}
          hotel={hotel}
          fetchHotel={fetchHotel}
        />
      )}
    </Box>
  );
}

Edit.propTypes = {
  className: PropTypes.string
};

export default Edit;
