import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Element = styled.li`
  position: relative;
  list-style: none;
  padding-left: 22px;
  color: #323232;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  font-family: GTEesti;
  padding-top: 10px;
  width: 100%;
  padding-bottom: 5px;
  margin-top: 0;

  &:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
    border: 2px solid #00bb58;
    left: 0;
    top: 16px;
    border-radius: 50%;
    background-color: transparent;
    box-sizing: border-box;
  }
`;

const Wrapper = styled.ul`
  padding-left: 0;
  margin-top: 6px;
  padding: 0;
  font-family: GTEesti;
`;

function List({ data, ...rest }) {
  const elementsComponent = data.items.map((element, index) => <Element key={index} dangerouslySetInnerHTML={{ __html: element }} />);

  return (
    <Wrapper>
      {elementsComponent}
    </Wrapper>
  );
}

List.propTypes = {
  data: PropTypes.object
};

export default styled(List)``;
