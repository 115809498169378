import { getImage } from 'Utils/getImage';
import Uploader from '../utils/Uploader';
import _cloneDeep from 'lodash/cloneDeep';

import pasteEvent from '../utils/pasteEvent';

class HotelUi {
  constructor({ hotel, api }) {
    this.api = api;
    this.hotel = hotel;
    this.images = [];

    this.uploader = new Uploader({
      config: {},
      onUpload: (response) => this.onUpload(response)
    });

    this.nodes = {
      imagesContainer: make('div', this.CSS.imagesContainer),
      wrapper: make('div', this.CSS.wrapper),
      name: make('div', this.CSS.name),
      topContainer: make('div', this.CSS.topContainer),
      leftTopContainer: make('div', this.CSS.leftTopContainer),
      rightTopContainer: make('div', this.CSS.rightTopContainer),
      location: make('div', this.CSS.location),
      rating: make('div', this.CSS.rating),
      reviews: make('div', this.CSS.reviews),
      image: make('img', this.CSS.image),
      images: make('img', this.CSS.image),
      description: make('div', this.CSS.description, {
        contentEditable: true
      }),
      priceContainer: make('div', this.CSS.priceContainer),
      bottomContainer: make('div', this.CSS.bottomContainer),
      amenities: make('div', this.CSS.amenities),
      amenity: make('div', this.CSS.amenity),
      caption: make('div', [this.CSS.input, this.CSS.caption], {
        contentEditable: true
      }),
      price: make('div', this.CSS.price, {
        contentEditable: true
      }),
      billingHour: make('div', this.CSS.billing, {
        contentEditable: true
      }),
      addImageButton: make('div', this.CSS.addImageButton, {
        onclick: () => this.uploader.uploadSelectedFile({})
      })
    };

    this.nodes.description.addEventListener('paste', pasteEvent);

    this.setHotelContent();
  }

  setHotel(hotel) {
    this.hotel = hotel;

    this.setHotelContent();
  }

  setHotelContent() {
    if (!this.hotel) return;
    const sortImages = _cloneDeep(this.hotel.images);
    sortImages.sort((a, b) => a.priority - b.priority);

    this.images = sortImages;

    this.nodes.name.innerHTML = this.hotel.name;
    this.nodes.location.innerHTML = this.hotel.address.location;
    this.nodes.rating.innerHTML = this.hotel.rating;
    this.nodes.reviews.innerHTML = `${this.hotel?.review?.total || 0} отзывов`;
    this.nodes.description.innerHTML = this.hotel.description || 'Введите описание';
    this.nodes.price.innerHTML = this.hotel.min_price_text || 'от 2000 ₽';
    this.nodes.billingHour.innerHTML = this.hotel.billingText || 'за сутки';

    this.renderTopContent();

    this.renderBottom();
  }

  onUpload = (data) => {
    data.priority = 9999;
    this.images = [...this.images, data];

    this.render();
  }

  renderTopContent() {
    this.nodes.leftTopContainer.appendChild(this.nodes.name);
    this.nodes.leftTopContainer.appendChild(this.nodes.location);

    this.nodes.rightTopContainer.appendChild(this.nodes.rating);
    this.nodes.rightTopContainer.appendChild(this.nodes.reviews);

    this.nodes.topContainer.appendChild(this.nodes.leftTopContainer);
    this.nodes.topContainer.appendChild(this.nodes.rightTopContainer);

    this.nodes.wrapper.appendChild(this.nodes.topContainer);
    this.renderAmenities();
  }

  renderAmenities() {
    this.hotel.amenities.forEach((element) => {
      this.nodes.amenity.innerHTML = element.text;
      const amenity = make('div', this.CSS.amenity, {
        innerHTML: element.text
      });

      this.nodes.amenities.appendChild(amenity);
    });

    this.nodes.wrapper.appendChild(this.nodes.amenities);
  }

  handleBlur(e, index) {
    const images = _cloneDeep(this.images);
    images[index].alt = e.target.value;
    this.images = images;
  }

  handleBlurPriority(e, index) {
    const value = Number(e.target.value);
    if (value === this.images[index].priority) return;
    const images = _cloneDeep(this.images);
    images[index].priority = value;
    images.sort((a, b) => a.priority - b.priority);
    this.images = images;
    this.render();
  }

  clear(nodeElement) {
    nodeElement.innerHTML = '';
  }

  handleDelete(enc) {
    const images = _cloneDeep(this.images);

    const filteredImages = images.filter((img) => img.encoded_url !== enc);
    filteredImages.sort((a, b) => a.priority - b.priority);

    this.images = filteredImages;
    this.render();
  }

  renderImages() {
    this.clear(this.nodes.imagesContainer);
    this.clear(this.nodes.addImageButton);

    const images = this.images.map((image, index) => {
      const img = getImage(image.encoded_url, { width: 360, height: 360 });

      const closeIcon = make('div', this.CSS.closeIcon, {
        onclick: () => this.handleDelete(image.encoded_url)
      });
      const caption = make('input', [this.CSS.input, this.CSS.caption], {
        contentEditable: true,
        value: image.alt || this.hotel.name,
        placeholder: 'Подсказка для изображения',
        onblur: (e) => this.handleBlur(e, index)
      });

      const priority = make('input', [this.CSS.input, this.CSS.caption], {
        contentEditable: true,
        value: image.priority || 9999,
        placeholder: 'Priority',
        onblur: (e) => this.handleBlurPriority(e, index)
      });

      const picture = make('img', this.CSS.picture, {
        src: img.defaultSrc,
        key: img.defaultSrc
      });

      const block = make('div', this.CSS.block);

      this.nodes.wrapper.appendChild(this.nodes.addImageButton);
      this.nodes.imagesContainer.appendChild(block);
      block.appendChild(picture);
      block.appendChild(priority);
      block.appendChild(caption);
      block.appendChild(closeIcon);

      return this.nodes.imagesContainer;
    });

    const imagesContainer = make('div');

    images.forEach((img) => {
      imagesContainer.appendChild(img);
    });

    this.nodes.wrapper.appendChild(imagesContainer);
  }

  renderBottom() {
    this.nodes.priceContainer.appendChild(this.nodes.price);
    this.nodes.priceContainer.appendChild(this.nodes.billingHour);
    this.nodes.bottomContainer.appendChild(this.nodes.priceContainer);
    this.nodes.bottomContainer.appendChild(this.nodes.description);
    this.nodes.wrapper.appendChild(this.nodes.bottomContainer);
  }

  get CSS() {
    return {
      input: this.api.styles.input,
      wrapper: 'hotelcard_hotel_wrapper',
      caption: 'hotelcard_hotel__caption',
      billing: 'hotelcard_hotel_billing',
      topContainer: 'hotelcard_hotel_topcontainer',
      leftTopContainer: 'hotelcard_hotel_top_leftcontainer',
      rightTopContainer: 'hotelcard_hotel_top_rightcontainer',
      name: 'hotelcard_hotel_name',
      location: 'hotelcard_hotel_location',
      amenities: 'hotelcard_hotel_amenities',
      amenity: 'hotelcard_hotel_amenity',
      rating: 'hotelcard_hotel_rating',
      reviews: 'hotelcard_hotel_reviews',
      image: 'hotelcard_hotel_image',
      bottomContainer: 'hotelcard_hotel_bottom',
      description: 'hotelcard_hotel_description',
      priceContainer: 'hotelcard_hotel_pricecontainer',
      price: 'hotelcard_hotel_price',
      priceButton: 'hotelcard_hotel_price_button',
      imagesContainer: 'hotelcard_gallery_img_container',
      addImageButton: 'hotelcard_add_image_button',
      closeIcon: 'hotelcard_gallery_img_remove',
      picture: 'hotelcard_gallery-img',
      block: 'hotel_block'
    };
  }

  getHotelData() {
    const description = this.nodes.description.innerHTML;
    const billingText = this.nodes.billingHour.innerHTML;
    const min_price_text = this.nodes.price.innerHTML;

    return {
      ...this.hotel,
      description,
      images: this.images,
      billingText,
      min_price_text
    };
  }

  render() {
    this.renderImages();
    return this.nodes.wrapper;
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};

export default HotelUi;
