import amenitiesMapper from 'Utils/amenitiesMapper';

function hotelMapper({ amenities, id, images, description, address, name, rating, star, review, slug, min_price_text, price }) {
  return {
    address,
    name,
    review,
    id,
    description,
    slug,
    rating,
    min_price_text,
    star,
    price,
    amenities: amenitiesMapper(amenities).slice(0, 6),
    image: images.length > 0 && images[0]
  };
}

export default hotelMapper;
