import React from 'react';

import ProgrammsContent from './ProgrammsContent';
import Procedures from './Procedures';
import Meal from './Meal';
import Description from './Description';
import Amenities from './Amenities';
import Treatments from './Treatments';

function NavContent({ page, ...rest }: any) {
  switch (page.tag) {
    case 'programms':
      return <ProgrammsContent {...rest} />;

    case 'procedures':
      return <Procedures {...rest} />;

    case 'meal':
      return <Meal {...rest} />;

    case 'amenities':
      return <Amenities {...rest} />;

    case 'description':
      return <Description {...rest} />;

    case 'specializations':
      return <Treatments {...rest} />;

    default:
      return <ProgrammsContent {...rest} />;
  }
}

export default NavContent;
