import _compact from 'lodash/compact';
import _flatten from 'lodash/flatten';

import { IError } from 'types';

import { toastify } from 'utils/toatify';

const showErrors = (data: any): IError[] => {
  let scrolled = false;
  const errors: IError[] = _compact(_flatten(data?.blocks?.map((block: any) => block?.data?.errors || [])));

  if (errors?.length > 0) {
    errors.forEach(({ text, componentRef }) => {
      if (!scrolled) {
        componentRef.scrollIntoView({ behavior: 'smooth', block: 'center' });

        scrolled = true;
      }

      toastify('error', text);
    });
  }

  return errors;
};

export default showErrors;
