import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Text, Select } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/ru';
import { DateTime } from 'luxon';

import { Main, TopBar, Content } from 'components/ui/Layout';
import Loader from 'components/ui/Loader';

import useStyles from './Hotels.styles';

import useAvailabilities from 'hooks/useAvailabilities';

import Hotel from './Hotel';
import { IAvailabilitiesResponse } from 'types/availabilities';
import { IOption } from 'types';

const HotelRow = React.memo(Hotel);

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const options: IOption[] = [
  {
    label: 'Свободные номера',
    value: 'free'
  },
  {
    label: 'Занятые номера',
    value: 'busy'
  },
  {
    label: 'Квота',
    value: 'quota'
  },
  {
    label: 'Стоимость за сутки',
    value: 'price_per_day_min_in_rub'
  },
]

const sourceOptions: IOption[] = [
  {
    label: 'Группа «Согаз»',
    value: '5a27ea5273e8b0489f2768cb'
  },
  {
    label: 'Sanatory.ru',
    value: '5ba103be73e8b035cb40c8da'
  }
]

function Hotels({ className }: Props) {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const [value, setValue] = useState('free');
  const from = DateTime.now().startOf('month');
  const to = from.plus({ months: 11 }).endOf('month');
  const [date, setDate] = useState<[Date, Date]>([from.toJSDate(), to.toJSDate()]);
  const [source, setSource] = useState<string>('5a27ea5273e8b0489f2768cb')

  const [{ data, isPending, isPendingMore }, { fetch, fetchMore }]: any = useAvailabilities();

  const from_date = DateTime.fromJSDate(date[0]).toFormat('yyyy-MM-dd');
  const to_date = DateTime.fromJSDate(date[1]).toFormat('yyyy-MM-dd');

  useEffect(() => {
    if (!date[0] || !date[1]) return;

    const fetchFunction = async (params: any) => {
      await fetch(params);
    }

    fetchFunction({ from_date, to_date, source_id: source });
  }, [date, source]);

  const handleChange = (val: any) => {
    setValue(val);
  }

  const handleChangeSource = (val: any) => {
    setSource(val);
  }

  const handleChangeDate = (val: any) => {
    setDate(val);
  }

  const handleScroll = (e: any) => {
    const bottom = Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) - e.target.clientHeight < 2000;

    if (bottom && !isPendingMore && !isPending) {
      fetchMore({ from_date, to_date, source_id: source });
    }
  };

  return (
    <Main className={cx(className, classes.root)}>
      <TopBar>
        <Text size={25} color='#000'>
          {t('Available.Title')}
        </Text>

        <Box className={classes.topInputs}>
          <DateRangePicker
            label='Дата отчета'
            inputFormat="DD MMM YYYY"
            amountOfMonths={2}
            value={date}
            locale='ru'
            className={classes.date}
            onChange={handleChangeDate}
          />

          <Select
            data={sourceOptions}
            label='Источник продаж'
            value={source}
            className={classes.source}
            onChange={handleChangeSource}
          />

          <Select
            data={options}
            className={classes.type}
            label='Ражим отображения'
            value={value}
            onChange={handleChange}
          />
        </Box>
      </TopBar>

      <Content className={classes.content} onScroll={handleScroll}>
        {isPending && <Loader className={classes.loader} />}

        {data && data.map((d: IAvailabilitiesResponse) => (
          <HotelRow
            data={d}
            variant={value}
            key={d.hotel.id}
            className={classes.hotel}
          />
        ))}

        {isPendingMore && <Box className={classes.loaderMore}>Загрузка...</Box>}
      </Content>

      <Outlet />
    </Main>
  );
}

export default Hotels;
