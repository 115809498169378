import React from 'react';
import { Box, Image } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { getImage } from 'utils/getImage';

import useStyles from './Gallery.styles';
import { IImage } from 'types';

type Props = {
  className?: string,
  hotel: any
}

function Gallery({ className, hotel }: Props) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const mainImage: IImage = hotel.images.length > 0 && hotel.images[0];
  const image = getImage(mainImage?.encoded_url, { width: 810, height: 380 });
  const smallImages: IImage[] = hotel.images.slice(0, 4);
  const images = smallImages
    .map((img) => getImage(img.encoded_url, { width: 196, height: 110 }))
    .map((img, index) => <Image key={index} {...img} />);

  return (
    <Box className={cx(className, classes.root)}>
      <Image {...image} />

      <Box className={classes.images}>
        {images}
      </Box>
    </Box>
  );
}
export default Gallery;
