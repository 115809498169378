import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { StarIcon } from 'components/ui/Icons';

const StarContainer = styled.div`
  color: #FCC656;
  font-size: 25px;
  line-height: 11px;
  margin-left: 4px;

  &:nth-child(1) {
    margin-left: 0;
  }

  svg {
    height: 20px;
    width: 20px;
  }
`;

const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
`;

const Wrapper = styled.div`
  display: inline-flex;
  height: 28px;
  align-items: center;
  min-height: 26px;

  svg {
    height: 18px;
  }
`;

function Stars({ className, stars }) {
  return (
    <Wrapper className={className}>
      <StarsContainer>
        {
          Array.from({ length: stars }).map((_, idx) => (
            <StarContainer key={idx}>
              <StarIcon />
            </StarContainer>
          ))
        }
      </StarsContainer>
    </Wrapper>
  );
}

Stars.propTypes = {
  className: PropTypes.string,
  stars: PropTypes.number.isRequired
};

export default Stars;
