import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@mantine/core';

import ModalForm, { schema } from './Form';

import useStyles from './Add.styles';

import Form from 'components/ui/Form';
import { useAppDispatch } from 'store';
import { createParsingAction } from 'store/parsingSlice/actions';
import { IParsingCreateRequest } from 'apis/ParsingApi';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

function Add({ className }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const onSubmit = async (data: IParsingCreateRequest) => {
    setLoading(true);
    await dispatch(createParsingAction(data));
    setLoading(false);

    navigate('/parsing/');
  };

  const handleReset = () => {
    navigate('/parsing/');
  };

  return (
    <Modal opened onClose={handleReset}>
      <Form
        className={classes.form}
        initialValue={{ }}
        onSubmit={onSubmit}
        onReset={handleReset}
        schema={schema}
      >
        <ModalForm loading={loading} />
      </Form>
    </Modal>
  );
}

Add.propTypes = {
  className: PropTypes.string
};

export default Add;
