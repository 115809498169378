import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    flexGrow: 0,
    flexShrink: 0
  },
  shortContainer: {
    width: 40,
    height: 40,
    backgroundColor: '#3b4149',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    zIndex: 1,
    cursor: 'pointer'
  },
  fullContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minWidth: 256,
    maxWidth: 256,
    backgroundColor: '#3b4149',
    height: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    minHeight: 80,
    maxHeight: 80,
    boxSizing: 'border-box',
    letterSpacing: 1.6
  },
  content: {
    overflowY: 'auto'
  },
  btn: {
    marginTop: 'auto'
  }
}));

export default useStyles;
