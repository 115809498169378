import React from 'react';
import { Box } from '@mantine/core';

import useStyles from './Group.styles';

type Props = {
  className?: string,
  group: any
}

function Group({ className, group }: Props) {
  const { classes, cx } = useStyles();
  const elementsComponent = group?.children?.map(
    (element: any) => <Box className={classes.element} key={element.id}>{element.name}</Box>
  );

  return (
    <Box className={cx(classes.root, className)}>
      <Box className={classes.title}>
        {group.name}
      </Box>

      {elementsComponent}
    </Box>
  );
}

export default Group;
