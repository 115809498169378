import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dateFormat from 'Utils/dateFormat';
import { useTranslation } from 'react-i18next';

import Header from 'components/ui/Header';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import Switch from 'components/ui/Switch';
import { LeftGroup } from 'components/ui/ButtonGroup';
import { MoreIcon } from 'components/ui/Icons';
import Dropdown, { DropdownList, DropdownItem } from 'components/ui/Dropdown';

const Td = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, 1fr);
  padding: 16px 24px;
  border-bottom: 1px solid rgb(220, 216, 210);
`;

function ListCard({ blog, onEdit, onPublish, className, onDelete, onEditForm, sitePath }) {
  const { title, published, published_at, slug } = blog;
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const { t } = useTranslation();

  const showArticle = () => {
    const site = process.env.REACT_APP_SITE || 'https://sanatory-testing.ru';

    if (published && site) {
      window.open(`${site}/${sitePath}/${slug}`);
    }
  };

  const publishedStyle = published
    ? 'success'
    : 'secondary';

  const handleEdit = (e) => {
    onEdit(e);
  };

  const handlePublish = (value) => {
    onPublish(value);
  };

  return (
    <Wrapper className={className}>
      <Td>
        <Header level={5}>
          {title.ru || title}
        </Header>
      </Td>

      <Td>
        {published_at && (
          <Text variant={publishedStyle}>
            {published
              ? `Опубликовано ${dateFormat(published_at)}`
              : `Снято с публикации ${dateFormat(published_at)}`}
          </Text>
        )}
      </Td>

      <Td>
        {blog.category && (
          <Text>
            {blog.category.name}
          </Text>
        )}
      </Td>

      <Td>
        <LeftGroup>
          <Button
            size='medium'
            variant='secondary'
            onClick={handleEdit}
          >
            {t('Action.Edit')}
          </Button>

          <Dropdown
            isOpen={dropdownIsOpen}
            onClickOutside={() => setDropdownIsOpen(false)}
            content={
              <DropdownList>
                <DropdownItem onClick={() => onDelete(blog)}>
                  <Text>{t('Action.Delete')}</Text>
                </DropdownItem>

                <DropdownItem onClick={() => onEditForm(blog)}>
                  <Text>{t('Action.EditForm')}</Text>
                </DropdownItem>
                <DropdownItem onClick={showArticle} disabled={!published}>
                  <Text>{t('Action.ShowSite')}</Text>
                </DropdownItem>
              </DropdownList>
            }
          >
            <Button
              size='medium'
              variant='secondary'
              onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
            >
              <MoreIcon />
            </Button>
          </Dropdown>
        </LeftGroup>
      </Td>

      <Td>
        <Switch
          value={published}
          onChange={handlePublish}
        />
      </Td>
    </Wrapper>
  );
}

ListCard.defaultProps = {
  onEdit: () => { },
  onPublish: () => { }
};

ListCard.propTypes = {
  onEditForm: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  sitePath: PropTypes.string,
  blog: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    published: PropTypes.bool,
    published_at: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired
    }),
    category: PropTypes.shape({
      name: PropTypes.string.isRequired,
      tag: PropTypes.string.isRequired
    })
  }).isRequired,
  onEdit: PropTypes.func,
  onPublish: PropTypes.func
};

export default styled(ListCard)``;
