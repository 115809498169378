import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useNavigate, Outlet } from 'react-router-dom';
import { Text, Table, Button, Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import Loader from 'components/ui/Loader';

import { Main, TopBar, Content } from 'components/ui/Layout';

import useStyles from './List.styles';

import { STATUSES } from 'types';
import { fetchParsingAction } from 'store/parsingSlice/actions';
import { parsingAllSelector } from 'store/parsingSlice/selectors';
import { IParsingResponse } from 'types/parsing';
import parseAsyncAction from 'store/parsingSlice/actions/parseAsyncAction';

const format = 'dd.MM.yyyy';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

function SearchArea({ className }: Props) {
  const { t } = useTranslation();
  const statuses: any = {
    created: t('Parsing.Status.Created'),
    pending_hotel_info: t('Parsing.Status.PendingHotel'),
    parsing_hotel_info: t('Parsing.Status.ParsingHotel'),
    pending_rates_info: t('Parsing.Status.PendingRates'),
    parsing_rates_info: t('Parsing.Status.ParsingRates'),
    parsed: t('Parsing.Status.Parsed'),
    completed: t('Parsing.Status.Completed'),
    cancelled: t('Parsing.Status.Cancelled'),
    expired: t('Parsing.Status.Expired'),
    failed: t('Parsing.Status.Failed'),
  };

  const { classes, cx } = useStyles();
  const navigate = useNavigate();

  const statusClasses: any = {
    failed: classes.statusFailed,
    completed: classes.statusCompleted
  }

  const dispatch = useAppDispatch();
  const [state, parsing]: [STATUSES, IParsingResponse[]] = useAppSelector(parsingAllSelector);

  useEffect(() => {
    dispatch(fetchParsingAction());
  }, []);

  if (state !== STATUSES.FULFILLED) {
    return <Loader className={classes.loader} />;
  }

  const handleScroll = (e: any) => {
    const scrollHeight = e.currentTarget.scrollHeight;
    const scrollTop = e.currentTarget.scrollTop;
    const clientHeight = e.currentTarget.clientHeight;

    if (scrollHeight - (scrollTop + clientHeight) < 200) {
      // if (stateMore !== STATUSES.PENDING) {
      //   setPage(page + 1);

      //   dispatch(fetchObjectMoreAction(page));
      // }
    }
  };

  const handleClick = (parsing: IParsingResponse) => {
    navigate(`/parsing/${parsing.uuid}/`);
  };

  const handleCreate = () => {
    navigate('/parsing/add/');
  };

  const handleParse = (id: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(parseAsyncAction(id));
  }

  const rows = parsing.map((element) => (
    <tr key={element.uuid} className={classes.tableRow} onClick={() => handleClick(element)}>
      <td className={classes.td}>{element.name?.ru}</td>
      <td className={classes.td}>{element.hotel_code}</td>
      <td className={classes.td}>
        {DateTime.fromISO(element.created_at).toFormat(format)}
      </td>

      <td className={classes.td}>
        {element.available_period
          ? [DateTime.fromISO(element.available_period.from).toFormat(format), DateTime.fromISO(element.available_period.to).toFormat(format)].join(' - ')
          : ''
        }
      </td>

      <td className={classes.td}>
        {element.tariffs_count}
      </td>

      <td className={classes.td}>
        <Box className={cx(classes.status, statusClasses[element.state] || classes.statusInProgress)}>
          {statuses[element.state] || t('Parsing.Status.Completed')}
        </Box>
      </td>

      <td className={classes.td}>
        <Box className={classes.refresh} onClick={handleParse(element.uuid)}>
          <Text color='#fff' size={15} weight={600}>
            P
          </Text>
        </Box>
      </td>
    </tr>
  ));

  return (
    <Main onScroll={handleScroll} className={cx(className, classes.root)}>
      <TopBar>
        <Text size={25} color='#000'>
          {t('Parsing.Title')}
        </Text>

        <Button className={classes.button} onClick={handleCreate}>
          {t('Parsing.Create')}
        </Button>
      </TopBar>

      <Content className={classes.content}>
        <Table verticalSpacing='xl' horizontalSpacing='xl' className={classes.table} fontSize='md'>
          <thead>
            <tr className={classes.tr}>
              <th className={classes.th}>{t('Parsing.Table.Field1')}</th>
              <th className={classes.th}>{t('Parsing.Table.Field2')}</th>
              <th className={classes.th}>{t('Parsing.Table.Field3')}</th>
              <th className={classes.th}>{t('Parsing.Table.Field4')}</th>
              <th className={classes.th}>{t('Parsing.Table.Field5')}</th>
              <th className={classes.th}>{t('Parsing.Table.Field6')}</th>
              <th className={classes.th}></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </Content>

      <Outlet />
    </Main>
  );
}

export default SearchArea;
