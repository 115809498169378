import connection from 'connection/instance';
import { serialize } from 'object-to-formdata';

class ReviewersApi {
  async fetchList() {
    try {
      const { data } = await connection.get('/api/reviewers');

      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async fetchOne(id) {
    try {
      const { data } = await connection.get(`/api/reviewers/${id}`);

      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async create(attributes) {
    try {
      const { data } = await connection.post('/api/reviewers', serialize({ data: attributes }));
      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async update(id, attributes) {
    try {
      const { data } = await connection.put(
        `/api/reviewers/${id}`, serialize({ data: attributes })
      );

      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async delete(id) {
    try {
      const { data } = await connection.delete(
        `/api/reviewers/${id}`
      );

      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async publish(id) {
    try {
      const { data } = await connection.put(`/api/reviewers/${id}/publish`);
      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }

  async unpublish(id) {
    try {
      const { data } = await connection.put(`/api/reviewers/${id}/unpublish`);
      return { data: data.data, error: undefined };
    } catch (e) {
      return { data: undefined, error: e };
    }
  }
}

export default new ReviewersApi();
