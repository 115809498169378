import React from 'react';
import { Box } from '@mantine/core';

import useStyles from './BottomBar.styles';

type Props = React.PropsWithChildren<{
  className?: string
}>

function BottomBar({ children, className }: Props) {
  const { classes, cx } = useStyles();

  return (
    <Box className={cx(className, classes.root)}>
      {children}
    </Box>
  );
}

export default BottomBar;
