import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Row from './Row';
import Column from './Column';

const Container = styled.div`
  ${Row}:not(:last-child) {
    margin-bottom: 12px;
  }

  ${Column} {
    box-sizing: border-box;
    padding-left: 6px;
    padding-right: 6px;

    &:first-child {
      padding-left: 0px;
    }

    &:last-child {
      padding-right: 0px;
    }

    &:only-child {
      padding-left: 0px;
      padding-right: 6px;
    }
  }
`;

const Grid = ({ className, children }) => {
  return (
    <Container
      className={className}
    >
      {children}
    </Container>
  );
};

Grid.defaultProps = {
  className: ''
};

Grid.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default styled(Grid)``;
