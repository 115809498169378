
import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  root: {
    padding: 20,
    paddingTop: 0,
    boxSizing: 'border-box'
  },

  duration: {
    width: 'fit-content',
    height: 21,
    background: '#71A6DD',
    borderRadius: 4,
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '20px',
    color: '#FFFFFF',
    padding: '0 8px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  },

  programContent: {
    columnCount: 2,
    marginTop: 8,
    display: 'inline-block',

    ul: {
      margin: 0,
      padding: 0
    },

    li: {
      listStyle: 'none',
      margin: 0,
      fontSize: 16,
      lineHeight: '18px',
      color: '#323232',
      padding: '6px 0'
    }
  },
  description: {
    fontSize: 16,
    lineHeight: '22px',
    color: '#323232',
    marginTop: 20
  },

  listTitle: {
    fontSize: 16,
    lineHeight: '20px',
    color: '#323232',
    fontWeight: 600,
    marginTop: 22
  }
}));

export default useStyles;
