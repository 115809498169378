import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Name = styled.div`
  font-family: GTEesti;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AddressContainer = styled.div`
  color: #2e7cd5;
  font-weight: 600;
  font-size: 14px;
  font-family: GTEesti;
`;

const AddressContent = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  width: 100%;
`;

const RatingContainer = styled.div`
  background: #5bb884;
  color: #fff;
  display: flex;
  padding: 0 8px;
  align-items: center;
  border-radius: 3px;
  height: 21px;
  font-family: GTEesti;
  font-size: 14px;
  line-height: 26px;

  &:before {
    content: '';  
    width: 14px;
    margin-right: 7px;
    height: 13px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNCAxMyIgZmlsbD0iI2ZmZiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA0LjQwMjQ3VjExLjQwMjVDMCAxMS44ODU3LjM5MTczMSAxMi4yNzc1Ljg3NSAxMi4yNzc1SDMuNVYzLjUyNzQ3SC44NzVDLjM5MTczMSAzLjUyNzQ3IDAgMy45MTkyIDAgNC40MDI0N3pNMTIuMzg0MiA0LjQwMjI4SDkuNzk5MzJDOS41NzMzMSA0LjQwMjI4IDkuNDYwMDkgNC4yNTE0NyA5LjQyMjA2IDQuMTg2NTIgOS4zODQwNCA0LjEyMjAxIDkuMzA3MTMgMy45NDk4MyA5LjQxNjUxIDMuNzUyNDRMMTAuMzI3OCAyLjExMTM5QzEwLjUyNzggMS43NTIwNyAxMC41NDgzIDEuMzI5NTMgMTAuMzg0Ni45NTIyNjYgMTAuMjIxLjU3NDU4MSA5Ljg5ODAxLjMwMDcxNiA5LjQ5ODUzLjIwMTE2OEw4Ljg1NTk1LjA0MDUyMzRDOC42OTk1OC4wMDEyMTY3NyA4LjUzMzgxLjA1MTIwNDYgOC40MjQ4Ni4xNzIxMTZMNC45MzU5NyA0LjA0ODA5QzQuNTc0MSA0LjQ1MDk5IDQuMzc1IDQuOTcwNTIgNC4zNzUgNS41MTE0MVYxMC4wODk4QzQuMzc1IDExLjI5NTkgNS4zNTYzOCAxMi4yNzczIDYuNTYyNSAxMi4yNzczTDEwLjkxNDkgMTIuMjc2OEMxMS44OTc1IDEyLjI3NjggMTIuNzY1MyAxMS42MTUgMTMuMDI0NiAxMC42Njc4TDEzLjk1ODYgNi4zODE3QzEzLjk4NTkgNi4yNjI1IDE0IDYuMTQwMzEgMTQgNi4wMTgxMiAxNCA1LjEyNzMxIDEzLjI3NSA0LjQwMjI3IDEyLjM4NDEgNC40MDIyN0wxMi4zODQyIDQuNDAyMjh6Ii8+PC9zdmc+);
  }
`;

const LeftContainer = styled.div`

`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${RatingContainer} + ${AddressContainer} {
    margin-left: 12px;
  }
`;

const site = process.env.REACT_APP_SITE || 'https://sanatory-testing.ru';

function Top({ className, hotel }) {
  const handleTitleClick = () => {
    window.open(`${site}/hotel/${hotel.slug}`);
  };

  return (
    <Wrapper className={className}>
      <LeftContainer>
        <NameContainer>
          <Name onClick={handleTitleClick}>
            {hotel.name}
          </Name>
        </NameContainer>

        <AddressContent>
          {hotel.rating > 0 && (
            <RatingContainer>
              {hotel.rating}
            </RatingContainer>
          )}

          {hotel.address?.region && (
            <AddressContainer>
              {hotel.address.region}
            </AddressContainer>
          )}
        </AddressContent>
      </LeftContainer>
    </Wrapper>
  );
}

Top.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object
};

export default styled(Top)``;
