import { css } from 'styled-components';

const variant = ({ variant }) => {
  if (variant === 'primary') {
    return css`
      color: #000000;
    `;
  }

  if (variant === 'secondary') {
    return css`
      color: #999999;
    `;
  }

  if (variant === 'success') {
    return css`
      color: #58CA97;
    `;
  }

  if (variant === 'error') {
    return css`
      color: #FF5656;
    `;
  }

  if (variant === 'warning') {
    return css`
      color: #F2994A;
    `;
  }

  return css``;
};

const size = (props) => css`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
`;

const defaults = (props) => css`
  display: inline-flex;
  margin: 0;
  padding: 0;
`;

const theme = (props) => ([
  defaults(props),
  size(props),
  variant(props)
]);

export default theme;
