import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
  rate: {
    display: 'flex',
    padding: '4px 0',
    borderBottom: '1px solid #c0c0c0'
  },

  leftRate: {
    width: 500
  },

  rightRate: {
    marginLeft: 20
  }
}));

export default useStyles;
