import isNil from 'lodash/isNil';

function type({ type }) {
  return {
    type: (isNil(type) ? '' : type)
  };
}

function title({ title }) {
  if (isNil(title)) {
    return {
      title_ru: ''
    };
  }

  return {
    title_ru: title.ru ? title.ru : '',
    title_en: title.en ? title.en : ''
  };
}

function seoTitle({ seo_title }) {
  if (isNil(seo_title)) {
    return {
      seo_title_ru: ''
    };
  }

  return {
    seo_title_ru: seo_title.ru ? seo_title.ru : '',
    seo_title_en: seo_title.en ? seo_title.en : ''
  };
}

function seoDescription({ seo_description }) {
  if (isNil(seo_description)) {
    return {
      seo_description_ru: ''
    };
  }

  return {
    seo_description_ru: seo_description.ru ? seo_description.ru : '',
    seo_description_en: seo_description.en ? seo_description.en : ''
  };
}

function metaTags({ meta_tags }) {
  if (isNil(meta_tags)) {
    return {
      meta_tags: []
    };
  }

  return {
    meta_tags
  };
}

function reviewer({ reviewer }) {
  return {
    reviewer: (isNil(reviewer) ? null : reviewer)
  };
}

function category({ category }) {
  return {
    category: (isNil(category) ? null : category)
  };
}

function formValues(value) {
  if (value === null) return null;

  return {
    ...reviewer(value),
    ...category(value),
    ...title(value),
    ...seoTitle(value),
    ...seoDescription(value),
    ...metaTags(value),
    ...type(value)
  };
}

export default formValues;
