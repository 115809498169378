import React from 'react';
import { DateTime } from 'luxon';
import { useMantineTheme } from '@mantine/core';
import ReactECharts from 'echarts-for-react';

type Props = React.PropsWithChildren<{
  className?: string;
  availabilities: [string, number][],
  from_date: any,
  to_date: any
}>;

function SearchArea({ availabilities, from_date, to_date, className }: Props) {
  const theme = useMantineTheme();
  const max: any = Math.max(...availabilities.map(([e, count]) => count)) || 1;

  const options = {
    visualMap: {
      min: 1,
      max,
      type: 'piecewise',
      outOfRange: {
        color: [theme.colors.red[6]]
      },
      inRange: {
        color: ['yellow', '#52AB18']
      },
      pieces: [
        // Range of a piece can be specified by property min and max,
        // where min will be set as -Infinity if ignored,
        // and max will be set as Infinity if ignored.
        {min: 0, max: 0, color: theme.colors.red[6] },
        {min: 1, max: 1, color: 'yellow', label: '1' },
        {min: 1, max: Math.ceil(max / 5), color: '#e0f200', label: `1 - ${Math.ceil(max / 5) }` },
        {min: Math.ceil(max / 5), max: Math.ceil(max / 4), color: '#c2e400' },
        {min: Math.ceil(max / 4), max: Math.ceil(max / 3), color: '#a6d605' },
        {min: Math.ceil(max / 3), max: Math.ceil(max / 2), color: '#89c80d' },
        {min: Math.ceil(max / 2), max: Math.ceil(max / 1.5), color: '#6eba13' },
        {min: Math.ceil(max / 1.5), max: Math.ceil(max), color: '#52ab18' },
    ],
      calculable: true,
      orient: 'horizontal',
      left: 'center',
      top: 'top',
      show: max != '-Infinity'  
    },
    gradientColor: [
      'yellow',
      '#52AB18'
    ],
    calendar: [
      {
        range: [from_date.toFormat('yyyy-MM-dd'), to_date.toFormat('yyyy-MM-dd')],
        cellSize: ['auto', 20],
        monthLabel: {
          formatter: ({ MM }: any) => {
            return DateTime.fromFormat(MM, 'MM').toFormat('MMM')
          }
        },
        dayLabel: {
          show: false
        }
      }
    ],

    series: [
      {
        type: 'heatmap',
        coordinateSystem: 'calendar',
        calendarIndex: 0,
        data: availabilities
      }
    ],
    tooltip: {
      position: 'top'
    },
  };

  return (
    <ReactECharts option={options} className={className} />
  );
}

const from_date = DateTime.now().startOf('month');
const to_date = from_date.plus({ months: 11 }).endOf('month');

SearchArea.defaultProps = {
  from_date,
  to_date
}

export default SearchArea;
