import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: inline-block;

  ${({ xs }) => css`
    width: ${({ xs }) => xs / 12 * 100}%;
  `}
`;

function Column({ className, children, xs }) {
  return (
    <Container
      className={className}
      xs={xs}
    >
      {children}
    </Container>
  );
}

Column.defaultProps = {
  className: '',
  xs: 12
};

Column.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  xs: PropTypes.number
};

export default styled(Column)``;
