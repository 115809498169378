import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Hotels from './Hotels';

type Props = React.PropsWithChildren <{
  className?: string
}>

function Objects({ className }: Props) {
  return (
    <Routes>
      <Route path='/availability' element={<Hotels />} />

      <Route path='/' element={<Hotels />} />
    </Routes>
  );
}

export default Objects;
