
import { createStyles } from '@mantine/styles';

const useStyles = createStyles((theme, _, getRef) => ({
  root: {
    columnCount: 2
  }
}));

export default useStyles;
