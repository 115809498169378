import pasteEvent from '../utils/pasteEvent';

export default class Ui {
  constructor({ about, t }) {
    this.about = about;
    this.t = t;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      title: make('div', this.CSS.title, {
        innerHTML: t('Description.Title')
      }),
      about: make('div', this.CSS.about, {
        contentEditable: true
      })
    };

    this.nodes.about.addEventListener('paste', pasteEvent);
    this.nodes.wrapper.appendChild(this.nodes.title);
  }

  get CSS() {
    return {
      wrapper: 'description',
      title: 'description_title',
      about: 'description_about'
    };
  }

  getData() {
    const about = this.nodes.about;

    return {
      about: about.innerHTML
    };
  }

  render() {
    this.nodes.about.innerHTML = this.about;
    this.nodes.wrapper.appendChild(this.nodes.about);

    return this.nodes.wrapper;
  }

  displayErrorAbout = (flag) => {
    flag ? this.nodes.about.classList.add('error') : this.nodes.about.classList.remove('error');

    return this.nodes.about;
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
